import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container, Navbar, Nav, OverlayTrigger, NavDropdown, Col, Tooltip, Badge } from "react-bootstrap";


import classes from "./Header.module.css"
import Login from "../General/Login/Login";

import useDetectMobile from "../../../utils/Hooks/useDetectMobile";

const Header = (props) =>{

    const { MyNFTs, CardsGallery, Rewards, GameTesting, CheckNFT } = props.funcActivadas;
    const { isConnected, roles } = props.infoAccount;
    const { isTester } = roles;

    const { isMobile } = useDetectMobile();
    
    return (
        <header>
            <Navbar className={classes.navBackground} collapseOnSelect scrolling="true" dark="true" variant="dark" expand="lg" fixed="top" >
                <Container fluid className="m-3">
                    <Col xs={12} sm={3} className={ isMobile ? "d-flex justify-content-center" : "d-flex justify-content-start"}>
                        <Navbar.Brand href="/">
                            <img
                                src="images/LogoDE.png"
                                width="140"
                                height="50"
                                className="d-inline-block align-top"
                                alt="Dark Earth logo"
                            />
                        </Navbar.Brand>
                    </Col>
                    
                    <Navbar.Collapse className="text-center mx-auto" id="responsive-navbar-nav">
                        <Col xs={12} className="d-flex justify-content-center text-center mx-auto">
                            
                                <Nav className="text-center mx-auto" navbarScroll>
                                { isConnected ?
                                    <Fragment>
                                        <Nav.Link as={Link} className="nav-link" to="/">Home</Nav.Link >
                                        
                                        { MyNFTs ?
                                                <Fragment>
                                                    <Nav.Link as={Link} className="nav-link" to="/my-capsules">My Capsules</Nav.Link>

                                                    <NavDropdown title="My NFTs" id="MyNFTS" className={classes.dropDownColor} menuVariant="dark">
                                                        
                                                        <NavDropdown.Item as={Link} to="/pre-mint" className="fw-bolder text-white text-center">
                                                            Pre-Mint
                                                        </NavDropdown.Item>
                                                        
                                                        <NavDropdown.Divider />
                                                        
                                                        <NavDropdown.Item as={Link} to="/my-nfts" className="fw-bolder text-white text-center">
                                                            My NFTS
                                                        </NavDropdown.Item>

                                                        { CheckNFT && 
                                                        <Fragment>
                                                        <NavDropdown.Divider />

                                                        <NavDropdown.Item as={Link} to="/check-token" className="fw-bolder text-white text-center">
                                                            Check NFT
                                                        </NavDropdown.Item>
                                                        </Fragment>
                                                        }

                                                    </NavDropdown>

                                                    
                                                </Fragment>
                                        :
                                            <Fragment>
                                                <Nav.Link as={Link} disabled className="nav-link disabled" to="#">My Capsules</Nav.Link>
                                                <Nav.Link as={Link} disabled className="nav-link disabled" to="#">My NFTs</Nav.Link>
                                                <Nav.Link as={Link} disabled className="nav-link disabled" to="#">Rewards</Nav.Link>
                                            </Fragment>
                                        }

                                        <Nav.Link as={Link} className="nav-link text-white" to="/referrals">Referrals <small><Badge bg="warning" text="dark">NEW</Badge></small></Nav.Link>

                                        {Rewards ?
                                            <Nav.Link as={Link} className="nav-link" to="/rewards">Rewards</Nav.Link>
                                        :
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">You dont have access to this section!</Tooltip>}>
                                                <Nav.Link as={Link} className="nav-link" to="#">Rewards</Nav.Link>
                                            </OverlayTrigger>
                                        }

                                        { (GameTesting && isTester) && <Nav.Link as={Link} className="nav-link" to="/game-test">GAME</Nav.Link> }

                                    </Fragment>
                                :
                                <Fragment>
                                    { CardsGallery && <Link className="nav-link fw-bolder text-white" to="/our-gallery">See our NFT Gallery</Link> }

                                    <a className="nav-link fw-bolder text-white" href="https://darkearth.gg/en/we-help-you/">Tutorials</a>
                                </Fragment>
                                }  
                                </Nav>
                            
                        </Col>
                    </Navbar.Collapse>
                    
                    <Col className={ !isMobile ? "d-flex justify-content-end" : "d-flex justify-content-center mt-2"}>
                        <Login
                            getBlockchainData = { props.getBlockchainData }
                            infoAccount = {props.infoAccount}
                            onLogin={props.onLogin}
                            onLogout={props.onLogout}
                            chainId = { props.chainId }
                            useLoad = { props.useLoad }
                        />
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    </Col>
                    
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;