import React, { Fragment } from "react";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import MyCapsule from "../../components/UI/pages/MyCapsule/MyCapsule";
import NoDisponible from "../NoDisponible/NoDisponible";

const MyCapsules = ({handleToggle, ...props}) => {

    const { isConnected, genesisCapsDetails } = props.infoAccount;
    const { contracts, web3 } = props.web3Api;
    const reloadUserDetails = props.reloadUserDetails;

    const activado = props.activo;

    return (
        <Fragment>
            <main>

                { !isConnected ?
                    <NoConectado/>

                    :
                    
                     activado ?
                        <MyCapsule
                            infoAccount = { props.infoAccount }
                            handleToggle = { handleToggle }
                            genesisCapsDetails = { genesisCapsDetails }
                            contracts = { contracts }
                            reloadUserDetails = { reloadUserDetails }
                            web3 = { web3 }
                        />
                    :
                        <NoDisponible/>
                }
            </main>
        </Fragment>
  );

}

export default MyCapsules;