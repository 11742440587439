import { useState, useEffect, Fragment } from "react";
import { Container } from "react-bootstrap";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTES PRINCIPALES
import Header from "./components/UI/Header/Header";
import Main from "./components/UI/Main/Main";
import Footer from "./components/UI/Footer/Footer";

// WEB3 - METAMASK
import Web3 from "web3";
import { loadContract } from "./utils/Contracts/load-contract";

import Loader from "./components/UI/General/Loader/Loader";

import { GlobalVariables, setGlobalVariables } from "./utils/GlobalVariables";
import { GlobalInfoAccount, setGlobalInfoAccount } from "./utils/GlobalInfoAccount";
//import { fastLoadingRun } from "./utils/FastLoading/FastLoading";

// SISTEMA DE PÁGINAS
import {
  Routes,
  Route,
} from "react-router-dom";

// PAGINAS
import BuyCapsules from "./pages/BuyCapsules/BuyCapsules";
import MyCapsules from "./pages/MyCapsules/MyCapsules";
import Marketplace from "./pages/Marketplace/Marketplace";
import Error404 from "./pages/404/Error404";
import MyCards from "./pages/MyCards/MyCards";
import VideoApertura from "./components/UI/General/VideoApertura/VideoApertura";
import Rewards from "./pages/Rewards/Rewards";
import MyNFTs from "./pages/MyNFTs/MyNFTs";
import CardsGallery from "./pages/CardsGallery/CardsGallery";
//import Roulette from "./pages/Roulette/Roulette";

import ConstantesAPI from "./utils/API/Constantes";
import { changeNetwork, reloadDetails, resetInfoAccount } from "./utils/GlobalFunctions";
import { consensusRunNow } from "./utils/Consensus/Consensus";
import useLoadWalletsDataFromDB from "./utils/FastLoading/useLoadWalletsDataFromDB";
import CheckTokens from "./components/UI/pages/CheckTokens/CheckTokens";
import { useCollectionCards } from "./components/Services/DataCards/useCollectionCards";
import GameTest from "./pages/GameTest/GameTest";
import useDetectMobile from "./utils/Hooks/useDetectMobile";
import Referrals from "./pages/Referrals/Referrals";
import StakingV1 from "./pages/StakingV1/StakingV1";

export let deviceIsMobile = window.innerWidth < 768;

function App() {

  let web3Api = GlobalVariables;
  let globalInfoAccount = GlobalInfoAccount;

  const [ isLoadingWeb, setIsLoadingWeb ] = useState(false);

  // Activar y desactivar funcionalidades de la web
  const funcActivadas = {
    ComprarCapsulas: true,
    AbrirCapsulas: true,
    MyNFTs: true,
    Marketplace: false,
    CardsGallery: true,
    Roulette: true,
    Rewards: true,
    CheckNFT: true,
    GameTesting: true,
    StakingV1: false
  }

  // -------------------------
  // ALL IN ONE - ACCOUNT
  // -------------------------
  const cleanInfoAccount = resetInfoAccount();
  const [ infoAccount, setInfoAccount ] = useState(cleanInfoAccount);

  // Se puede conectar al Smart Contract?
  const [ canConnectToContract, setCanConnectToContract ] = useState(false)

  const [ reproduceVideo, setReproduceVideo ] = useState(false);
  const [ capsulasAbiertas, setCapsulasAbiertas ] = useState([]);

  // -----------------------------------
  // HOOKS PERSONALIZADOS
  // -----------------------------------
  const useLoad = useLoadWalletsDataFromDB();
  const { haveDesync } = useLoad;
  const [ runHaveDesync, setRunHaveDesync ] = useState(false);

  const { collection, isLoading } = useCollectionCards();

  const { isMobile } = useDetectMobile();

  useEffect(() => {
    deviceIsMobile = isMobile;
  }, [isMobile])

  // ----------------------------------

  const playOpencapVideo = (openedCapsuleCards) => {
    const toggle = !reproduceVideo;
    setCapsulasAbiertas(openedCapsuleCards);
    setReproduceVideo(toggle);
  }

  const stopOpencapVideo = () => {
    const toggle = !reproduceVideo;
    setReproduceVideo(toggle);
  }

  useEffect(() => {
    const updateGlobalInfoAccount = () => {
      setGlobalInfoAccount(infoAccount);
    }

    infoAccount && updateGlobalInfoAccount();
  }, [infoAccount, globalInfoAccount])

  useEffect(() => {

    const playHaveDesync = () => {
      setRunHaveDesync(false);
      haveDesync();
    }

    runHaveDesync && playHaveDesync();
  }, [runHaveDesync, haveDesync])

  // -----------------------------------------------------------
  // FUNCION GENERAL PARA ACTUALIZAR LA INFORMACION DE LA CUENTA
  // -----------------------------------------------------------
  // COMPATIBILIDAD CON ANTIGUAS FUNCIONES
  // Carga rápida - No es una carga completa.
  const reloadUserDetails = async (web3, contracts, account, showLoader = true) => {

    if(web3 && contracts && account){
      if(showLoader) setIsLoadingWeb(true);

      const infoAccount = await reloadDetails(web3, contracts, account, web3Api.networkId);
      setGlobalInfoAccount(infoAccount);
      setInfoAccount(infoAccount);
      haveDesync();
      await consensusRunNow(infoAccount, contracts);

      if(showLoader) setIsLoadingWeb(false);
    }
  }

  const handleReloadUserDetails = async ({web3Api, account, showLoader = true}) => {
    await reloadUserDetails(web3Api.web3, web3Api.contracts, account, web3Api.networkId, showLoader);
  }

  // Carga lenta - Es una carga completa
  const getBlockchainData = async (provider, web3, showLoader = true) => {
    if(provider && web3) {
      if(showLoader) setIsLoadingWeb(true);
      const accountsChain = await web3.eth.getAccounts();
      if(accountsChain.length === 0) {
          console.log("ERROR: No account detected!");
          const vacio = resetInfoAccount();
          setInfoAccount(vacio);
        } else {
          const chainId = await web3.eth.getChainId();

          if(chainId === 137 || chainId === 80001) {
            // Cargamos el contrato cuando estamos en la ChainID correcta
            const USDC = await loadContract("USDC", provider);
            const Collection = await loadContract("DECollection", provider);
            const MysteryCapsule = await loadContract("MysteryCapsule", provider);
            //const DEStaking = await loadContract("DEStaking", provider);

            setCanConnectToContract(true);
            
            const _isSequence = provider._isSequenceProvider ? true : false;

            const newVars = {
              provider: provider,
              isProviderLoaded: true,
              web3: web3,
              networkId: chainId,
              isSequence: _isSequence,
              contracts: {
                MysteryCapsule: MysteryCapsule,
                USDC: USDC,
                DECollection: Collection,
                DEStaking: null
              }
            }
            // -------------------
            // Información sobre WEB3
            setGlobalVariables(newVars);
            // -------------------
            // Informacion sobre el usuario
            const infoAccount = await reloadDetails(newVars.web3, newVars.contracts, accountsChain[0], newVars.networkId);
            setGlobalInfoAccount(infoAccount);
            setInfoAccount(infoAccount);
            // -------------------
            setRunHaveDesync(true);
            // -------------------
            // Inicializar la API
            ConstantesAPI();
            // -------------------
            // Ejecutar consenso de TXs
            await consensusRunNow(infoAccount, newVars.contracts);
          } else {
            console.log("ERROR: This is not a valid chain.");
            setCanConnectToContract(false);
            changeNetwork(web3);
          }
        }

        if(showLoader) setIsLoadingWeb(false);
    }
  }
  // -----------------------------------------------------------
  // Función asincrona para hacer login
  const onLogin = async (provider) => {
    if(provider){
      const web3 = new Web3(provider);
      
      if (ConstantesAPI === null) {
        await changeNetwork(web3);
      } else {
        await getBlockchainData(provider, web3);
      }

    } else {
      console.error("No se detecta provider");
    }
  }

  // Función para cuando se desconecta
  const onLogout = () => {
    const vacio = resetInfoAccount();
    setInfoAccount(vacio);
  }

  // Cambios interactivos
  useEffect(() => {

    // Manejador para tratar cuando se cambia de cuenta
    const handleAccountChanged = async (accounts) => {
      await getBlockchainData(web3Api.provider, web3Api.web3);
    }

    // Manejador para tratar cuando se cambia de red
    const handleChainChanged = async () => {
      await getBlockchainData(web3Api.provider, web3Api.web3);
    }

    // Nos subscribimos a los eventos
    if(infoAccount.isConnected) {
      const { provider } = web3Api;
      provider.on("accountsChanged", handleAccountChanged);
      provider.on("chainChanged", handleChainChanged);
      
    }

    return () => {
      if(infoAccount.isConnected) {
        const { provider } = web3Api;
        provider.removeListener("accountsChanged", handleAccountChanged);
        provider.removeListener("chainChanged", handleChainChanged);
      }
      
    }

  }, [infoAccount, web3Api])

  // -----------------------------------------------------------

  return (
  <>
    
    { isLoadingWeb ? 
        <Fragment>
          <Loader/>
        </Fragment>
      :
        reproduceVideo ?
            <VideoApertura
              toggle = { playOpencapVideo }
              openedCapsuleCards = { capsulasAbiertas }
              handleReloadUserDetails = { handleReloadUserDetails }
              reloadVars = {{web3Api, account: infoAccount.account, showLoader: false}}
              handleStopVideo = {stopOpencapVideo}
            />
        :
        <Container fluid className="bg-black">
          <Header
            infoAccount = { infoAccount }
            onLogin = { onLogin }
            onLogout = { onLogout }
            funcActivadas = { funcActivadas }
            chainId = { web3Api.networkId }
            getBlockchainData = { getBlockchainData }
            useLoad = { useLoad }
          />
          <Routes>
            <Route path="/" element={
              <Main
                canConnectToContract = { canConnectToContract }
                infoAccount = { infoAccount }
                web3Api = { web3Api }
                // ------------
                reloadUserDetails = { reloadUserDetails }
                activoBuyCap = { funcActivadas.ComprarCapsulas }
              />
            }/>

            <Route path="/our-gallery" element={
              <CardsGallery
                activo = { funcActivadas.AbrirCapsulas }
                infoAccount = { infoAccount }
                collection = { collection }
                isLoading = { isLoading }
              />
            }/>

            <Route path="/buy-capsule" element={
              <BuyCapsules
                canConnectToContract = { canConnectToContract }
                reloadUserDetails = { reloadUserDetails }
                activo = { funcActivadas.ComprarCapsulas }
                infoAccount = { infoAccount }
                web3Api = { web3Api }
              />
            }/>

            <Route path="/staking" element={
              <StakingV1
                infoAccount = { infoAccount }
                activo = { funcActivadas.StakingV1 }
                useLoad = { useLoad }
                collection = { collection }
                web3Api = { web3Api }
              />
            }/>

            <Route path="/game-test" element={
              <GameTest
                infoAccount = { infoAccount }
                activo = { funcActivadas.GameTesting }
              />
            }/>

            <Route path="/my-capsules" element={
              <MyCapsules
                web3Api = { web3Api }
                activo = { funcActivadas.MyNFTs }
                infoAccount = { infoAccount }
                handleToggle = { playOpencapVideo }
                handleReloadUserDetail = { reloadUserDetails }
              />
            }/>

            <Route path="/pre-mint" element={
              <MyCards
                activo = { funcActivadas.MyNFTs }
                infoAccount = { infoAccount }
                web3Api = { web3Api }
                reloadUserDetails = { reloadUserDetails }
                collection = { collection }
                isLoading = { isLoading }
              />
            }/>

            <Route path="/referrals" element={
              <Referrals
                infoAccount = { infoAccount }
                showDetails = { true }
              />
            }/>

            <Route path="/my-nfts" element={
              <MyNFTs
                activo = { funcActivadas.MyNFTs }
                infoAccount = { infoAccount }
                contracts = { web3Api.contracts }
                collection = { collection }
                isLoading = { isLoading }
                useLoad = { useLoad }
              />
            }/>

            <Route path="/rewards" element={
              <Rewards
                activo = { funcActivadas.Rewards }
                infoAccount = { infoAccount }
                contracts = { web3Api.contracts }
                web3 = { web3Api.web3 }
                reloadUserDetails = { reloadUserDetails }
                useLoad = { useLoad }
                collection = { collection }
              />
            }/>

            <Route path="/check-token" element={
              <CheckTokens
                infoAccount = { infoAccount }
                DECollection = { web3Api.contracts.DECollection }
              />
            }/>

            <Route path="/marketplace" element={
              <Marketplace
                activo = { funcActivadas.Marketplace }
                infoAccount = { infoAccount }
              />
            }/>

            <Route path='*' exact={true} element={
              <Error404
                isConnected = { infoAccount.isConnected }
              />
            }/>
            
          </Routes>
          <ToastContainer theme="dark"/>
          <Footer/>
        </Container>
    }
  </>
  );
}

export default App;
