import Swal from "sweetalert2";
import { consensusAddUnverified, consensusSetVerified } from "../../../../utils/Consensus/Consensus";

const infoAlertTransactionRequest = {
    title: "Request in process",
    text: "Accept the transaction in your wallet to continue."
}

const infoAlertBurnCapsule = {
    title: "Opening Mystery Capsule",
    text: "Getting cards from the Mystery Capsule."
}

const burningCapsule = async ({
        account, 
        idCapsules, 
        MysteryCapsule, 
        handleSaveAPICapsules = async ({account, tx, count}) => {}, 
        handleCreateMintAPICapsules = async ({account, tx, idCaps}) => {}, 
        handleInfoAlertView = ({title, text, ms}) => {}, 
        handleErrorAlertView = ({textError}) => {}, 
        handleFinishFunction = (openedCapsuleCards) => {}
    }) => {

    let requestOk = false;

    //no se cierra este dialogo, se debe cerrar al momento de activar el video
    handleInfoAlertView(infoAlertTransactionRequest);

    try {
        await MysteryCapsule.bulkBurn.call(idCapsules, {from: account})
        requestOk = true;
    } catch(error){
        handleErrorAlertView(error.message);
    }

    if(requestOk) {

        const checkId = await consensusAddUnverified(account, "Open Capsules", JSON.stringify(idCapsules));
        if(!checkId) {
            handleErrorAlertView("You already have a transaction in process.<br/> Wait for the blockchain to finish and refresh the page.");
            return;
        }
        
        await MysteryCapsule.bulkBurn(idCapsules, {
            from: account,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null
        }).then((result) => {
            
            //texto informativo: Abriendo Capsula
            handleInfoAlertView(infoAlertBurnCapsule);

            // Guardar datos de la capsula en la DB
            handleSaveAPICapsules({account, tx: result.tx, count:idCapsules.toString()})
            .then(()=>{
                consensusSetVerified(account, checkId, result.tx);
                // Crear los minteos
                return handleCreateMintAPICapsules({account, tx: result.tx, idCaps: idCapsules});
            })
            .then((openedCapsuleCards)=>{
                Swal.close()
                // Lanzar animación
                handleFinishFunction(openedCapsuleCards);
            });

            return true;

            // Aquí ejecuta reloadUserDetails
        }).catch((e) => {
            //consensusSetVerified(account, checkId);
            handleErrorAlertView(e.message);
            return false;
        });

        
    }
}

const OpeningCapsuleAlertView = async ({
    account, 
    idCapsules, 
    MysteryCapsule, 
    handleSaveAPICapsules = async ({account, tx, count}) => {}, 
    handleCreateMintAPICapsules = async ({account, tx, idCaps}) => {}, 
    handleInfoAlertView = ({title, text, ms}) => {}, 
    handleErrorAlertView = ({errorText}) => {}, 
    handleFinishFunction = (openedCapsuleCards) => {}
}) => {

    MysteryCapsule && await burningCapsule({account, idCapsules, MysteryCapsule, handleSaveAPICapsules, handleCreateMintAPICapsules, handleInfoAlertView, handleErrorAlertView, handleFinishFunction})

}

export default OpeningCapsuleAlertView;