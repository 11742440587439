import ConstantesAPI from "./Constantes";

export const getRewardFromDB = async (idReward) => {
    const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/obtener_Reward.php?id=${idReward}`);
    const transTx = await respuesta.json();
    const idCardNeeds = transTx[0];
    return idCardNeeds["idCardNeeds"];
}

export const getFreeMintsFromDB = async (wallet) => {
    const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/obtener_FreeMints.php?wallet=${wallet}`);
    const transTx = await respuesta.json();
    return transTx;
}

export const getWalletRewards = async (account) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtener_WalletReward.php?wallet=${account}`);
    const respuesta = await peticion.json();
    return respuesta;
}

export const getScMinteos = async (account) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtener_ScMinteos.php?id=${account}`);
    const respuesta = await peticion.json();
    return respuesta;
}

export const getScTxMinteos = async (account) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtenerAll_MintTxs.php?wallet=${account}`);
    const respuesta = await peticion.json();
    return respuesta;
}

export const getSpecialMinteos = async (account, rewardIndex) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtener_SpecialMinteos.php?id=${account}&rewardIndex=${rewardIndex}`);
    const respuesta = await peticion.json();
    return respuesta;
}

export const getAllRewards = async () => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtenerAll_Rewards.php`);
    const respuesta = await peticion.json();
    return respuesta;
}

export const getBurnedCapsFromDB = async (account) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtenerAll_BurnedCaps.php?wallet=${account}`);
    const respuesta = await peticion.json();
    return respuesta;
}

export const getTxFromMint = async (idCap, wallet) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/obtener_TxFromMint.php?idCap=${idCap}&wallet=${wallet}`);
    const respuesta = await peticion.json();
    return respuesta[0].txId;
}

export const referralCheckCode = async (account, code) => {

    const datos = {
        wallet: account,
        code: code
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/referral_checkCode.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const referralAddBuy = async (account, code, amount) => {

    const datos = {
        wallet: account,
        code: code,
        cantidad: amount
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/referral_addBuy.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const insertOrUpdateReferral = async (account, code) => {

    const datos = {
        wallet: account,
        code: code
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/referral_createOrUpdateCode.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const getStakingReward = async (account) => {

    const datos = {
        wallet: account
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/staking_getReward.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const getReferralInfo = async (account) => {

    const datos = {
        wallet: account
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/referral_getInfo.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const getReferralCode = async (account) => {

    const datos = {
        wallet: account
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/referral_getCode.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const getWalletIsTester = async (account) => {

    const datos = {
        wallet: account,
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/checkTester.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const getIdGeneralFromTypes = async (tokenTypes) => {

    const datos = {
        tokenTypes: tokenTypes,
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/checkIdGeneral.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const getAndUpdateWalletsData = async (account, tokenIds, fullReload = false) => {

    const datos = {
        wallet: account,
        tokenIds: tokenIds,
        fullReload: fullReload
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/updateAndGetWalletsData.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const updateUsedWalletsData = async (account, tokenIds) => {

    const datos = {
        wallet: account,
        tokenIds: tokenIds,
    }

    const cargaUtil = JSON.stringify(datos);

    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/markUsedWalletsData.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();

    return respuesta;

}

export const saveTxMints = async (account, tx, typeMsg = "NFTs Minted") => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        tx: tx,
        type: typeMsg,
        amount: "All"
    }

    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/guardar_tx.php`, {
        method: "POST",
        body: cargaUtil,
    });

    //const exitoso = await respuesta.json();
}

export const saveConsensusMint = async (account, tx, idCap) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        txId: tx,
        details: idCap
    }

    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/useOneMint.php`, {
        method: "POST",
        body: cargaUtil,
    });

}

export const createMintReward = async (account, tx, rewardId) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        tx: tx,
        rewardId: rewardId
    }
    
    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/crea_MintReward.php`, {
        method: "POST",
        body: cargaUtil,
    });

}

