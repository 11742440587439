import StakeV1 from "../../components/UI/pages/StakeV1/StakeV1";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import NoDisponible from "../NoDisponible/NoDisponible";

const StakingV1 = (props) => {

    const { isConnected } = props.infoAccount;

   const activado = props.activo || false;

   return (
       <main>
           { !isConnected ?
                   <NoConectado/>

                   :
                   
                   activado ?
                       <StakeV1
                            infoAccount = { props.infoAccount }
                            useLoad = { props.useLoad }
                            collection = {  props.collection }
                            web3Api = { props.web3Api }
                       />
                   :
                       <NoDisponible/>
               }
       </main>
   );

}



export default StakingV1;