import { Fragment } from "react";

import classes from "./Market.module.css";

import Card from "../../Elements/Card/Card";
import CenterLogo from "../../Elements/CenterLogo/CenterLogo";

const Market = (props) => {
    
    return (
        <Fragment>
            <CenterLogo/>
            <div className={classes.centrador}>
                <Card className={classes.infoCapsules}>
                    <h1 className="display-1">MARKETPLACE</h1>
                    <Card className={classes.infoCapsulesCard}>
                        <p>
                            <b>Disponibles para abrir:</b> 0 <br/>
                            <b>Abiertas:</b> 0<br/>
                        </p>
                    </Card>
                </Card>
            </div>
        </Fragment>
    );

}
export default Market;