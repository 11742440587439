function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getRandomLoader = () => {
    const random = randomNumberInRange(1, 9);
    const ruta = "videos/CardsLoader/loader_card"+random+".webm";
    return ruta;
} 

export let actualLoader = getRandomLoader();