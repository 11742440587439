//import { useState } from "react";

export var GlobalVariables =  {
    provider: null,
    web3: null,
    networkId: null,
    isSequence: false,
    contracts: {
      MysteryCapsule: null,
      USDC: null,
      DECollection: null
    }
};

export default GlobalVariables;

export const setGlobalVariables = (newGlobalVars) => {
    GlobalVariables = newGlobalVars;
}


