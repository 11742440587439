import { useState } from "react";
import { Container } from "react-bootstrap";
import CenterLogo from "../../Elements/CenterLogo/CenterLogo";
import NoConectado from "../../Main/NoConectado/NoConectado";

const CheckTokens = (props) => {

    const [ inProcess, setInProcess ] = useState(false);
    const [ isTokenUsed, setIsTokenUsed ] = useState(null);
    const [ searchTokenId, setSearchTokenId ] = useState();

    const [ errorMessage, setErrorMessage ] = useState("");

    const { isConnected } = props.infoAccount;
    const DECollection = props.DECollection;

    const checkOnBlockchain = async () => {
        setInProcess(true);
        setErrorMessage("");

        try {
            const respuesta = await DECollection.getTokenInfo.call(searchTokenId);
            setIsTokenUsed(respuesta.used);
        } catch(error) {
            const mensaje = (error.message).includes("This token does not exist") ? "This token does not exist" : error.message;
            setErrorMessage(mensaje)
        }

        setInProcess(false);
    }

    return(
        <main>
        
            { isConnected ?
                <Container fluid className="bg-black">
                    <CenterLogo/>
                    <h1 className="display-3 fw-bold mx-auto text-center text-white pb-4">Check if the token was used!</h1>
                    <div className="mx-auto text-center">
                        <input value={searchTokenId} onInput={(e) => setSearchTokenId(e.target.value)} placeholder="TOKEN ID" className="p-2"/><br/>
                        <button type="button" className="btn btn-outline-light text-center mx-auto mt-4 p-2" onClick={checkOnBlockchain} disabled={inProcess}>
                            <span className="m-2">SEARCH</span>
                        </button>

                        { (!inProcess && isTokenUsed !== null && errorMessage === "") &&
                            <p className="text-white p-4">This token is <b>{isTokenUsed ? "used" : "not used"}</b></p>
                        }

                        {errorMessage !== "" && <p className="text-danger p-4">{errorMessage}</p> }
                    </div>
                </Container>
            :
                <NoConectado/>
            }
        
        </main>
    );

}

export default CheckTokens;