import Swal from "sweetalert2";

const ConfirmAlertView = ({
    account, 
    arrayCheckersCapsules, 
    MysteryCapsule, 
    handleOpenCapsuleAlertView = ({account, idCapsules: arrayCheckersCapsules, MysteryCapsule, handleSaveAPICapsules, handleCreateMintAPICapsules, handleInfoAlertView, handleErrorAlertView, handleFinishFunction})=>{}, 
    handleSaveAPICapsules = async ({account, tx, count}) => {}, 
    handleCreateMintAPICapsules = async ({account, tx, idCaps}) => {}, 
    handleInfoAlertView = ({title, text, ms}) => {}, 
    handleErrorAlertView = ({textError}) => {}, 
    handleFinishFunction = (openedCapsuleCards) => {}
}) => {
    
    Swal.fire({
        title: "Do you want to open " + arrayCheckersCapsules.length + " Mystery Capsule(s)?",
        html: "You are going to open the Mystery Capsule(s) with ID(s)<b> " + arrayCheckersCapsules.toString() + " </b><br/><br/>Do you agree?",
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: 'Yes, open now!',
        width: "50rem",
        background: "#131312",
        color: "#FFF",
        showCancelButton: true,
        denyButtonText: 'Cancel',
        footer: 'You will have to confirm the operation with your wallet',
    }).then((result) => {
        if(result.isConfirmed){
            handleOpenCapsuleAlertView({ account, idCapsules: arrayCheckersCapsules, MysteryCapsule, handleSaveAPICapsules, handleCreateMintAPICapsules, handleInfoAlertView, handleErrorAlertView, handleFinishFunction });
        } else if(result.isDenied){
            Swal.fire({
                title: "Transaction canceled.",
                text: "No transaction started.",
                width: "50rem",
                background: "#131312",
                color: "#FFF",
                icon: "info"
            });
        }
    });
}

export default ConfirmAlertView;