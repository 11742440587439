import { useCallback, useEffect, useState, useRef } from "react";

import { _ConstCollectionService } from "../../../utils/API/Constantes";
import { nullCollectionCard, EnumAttributes } from "./CollectionTypes";
import { MintTypeRanges } from "../Filters/FilterTypes";
import { useDidMount } from "./useDidMount";
import { useGlobalCollection } from "./useGlobalCollection";

/**
 * Devuelve el valor actual de la collección de cartas.
 * @returns { CollectionServiceState } { collection: StandardInfoCard, isLoading: boolean }
 */
export const useCollectionCards = () => {

    const [collection, nextCollectionState] = useGlobalCollection();
    const [isLoading, setLoading] = useState(true);
    const [ needReload, setNeedReload ] = useState(true);
    const error = useRef('');
    const didMount = useDidMount(true);

    /**
     * Nos devuelve el número total de cartas de la colección.
     * @returns number
     */
    const getTotalCards = () => {
        let currentMaxIndex = 1;
        for (const mintType in MintTypeRanges) {
            if (Object.hasOwnProperty.call(MintTypeRanges, mintType)) {
                const maxIndexCard = MintTypeRanges[mintType].max;
                currentMaxIndex = maxIndexCard > currentMaxIndex? maxIndexCard: currentMaxIndex;
            }
        }
        
        return currentMaxIndex;
    }

    /**
     * Devuelve la dirección del json que contiene la información de la carta (dirtyInfoCard).
     * @param {*} index index de la carta a la cual desea obtener la ruta
     * @returns string
     */
    const getUrlCardByIndex = (index) => {
        return _ConstCollectionService.RUTA_CARDS + index + ".json";
    }

    /**
     * Convierte un CleanInfoCard a uno estándar para ser utilizado por la aplicación.
     * @param {CleanCollectionCard} cleanInfoCard json de la infocard limpia.
     * @param {number} index id de la carta.
     * @returns {StandardInfoCard} StandardInfoCard
     */
    const asStandardInfoCard = (cleanInfoCard = nullCollectionCard, index) => {
        
        let assetUrl = _ConstCollectionService.RUTA_CARDS_MEDIA + index;
        let isVideo = false;
        let isImage = true;

        if(cleanInfoCard.hasOwnProperty('animation_url') && index !== 139) {
            assetUrl+=".mp4";
            isVideo = true;
        } else {
            assetUrl+=".png";
            isImage = true;
        }

        const inforCard = {
            index: index,
            name: cleanInfoCard.name, 
            description: cleanInfoCard.description, 
            external_url: cleanInfoCard.external_url,
            background_color: cleanInfoCard.background_color, 
            image: cleanInfoCard.hasOwnProperty('image')? cleanInfoCard.image: '',
            animation_url: cleanInfoCard.hasOwnProperty('animation_url')? cleanInfoCard.animation_url: '',
            assetUrl,
            isVideo,
            isImage,
            edition: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Edition).value,
            mintType: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.MintType).value,
            rarity: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Rarity).value,
            type: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Type).value,
            faction: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Faction).value,
            used: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Used).value,
            ilustrator: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Ilustrator).value,
            manufactured: cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Manufactured).value
        }

        const animator = cleanInfoCard.attributes.find(({trait_type, value})=> trait_type === EnumAttributes.Animator);

        return {...inforCard, ...{animator: animator !== undefined?animator.value:''}}
    }

    /**
     * Inicia el Subject donde su primera carga es la collección completa del servidor (array de StandardInfoCard).
     * @returns void
     */
    const fetchCollectionCards = useCallback(async ()=>{
        try {

            if(!didMount){
                return;
            }
            setLoading(true);
            const currentMaxIndex = getTotalCards();
            const arrayCollectionCards = [];

            for (let index = 1; index <= currentMaxIndex; index++) {
    
                const urlCard = getUrlCardByIndex(index);
                
                await fetch(urlCard, { mode: _ConstCollectionService.CORS_MODE })
                    .then(
                        async (dirtyInfoCard) => {
                            if (dirtyInfoCard.ok) {
                                const cleanInfoCard = await dirtyInfoCard.json();
                                const infoCard = asStandardInfoCard(cleanInfoCard, index);
                                arrayCollectionCards.push(infoCard);
                            }
                        }
                    );
    
            }

            if(arrayCollectionCards.length > 0){
                nextCollectionState(arrayCollectionCards);

            }else{
                error.current ='Not collection cards found.';
            }

            setLoading(false);
            setNeedReload(false);

        } catch (e) {
            if(didMount){
                error.current = e;
                setLoading(false);
                setNeedReload(false);
                console.groupCollapsed('ERROR');
                console.log(e);
                console.groupEnd();
            }
        }
    },[didMount, nextCollectionState]);

    useEffect(() => {
        const fetchCards = () => {
            if(collection.length === 0){
                fetchCollectionCards();
            }
            setNeedReload(false);
        }
        
        needReload && fetchCards();
    }, [collection, fetchCollectionCards, needReload]);

    return {
        collection,
        fetchCollectionCards,
        isLoading,
        error
    };
}