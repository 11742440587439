import { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Animated } from "react-animated-css";
import Swal from "sweetalert2";

//Services
import { ServerCards } from "../../../Services/DataCards/CollectionsCards";
//Components
import CardView from "../../Elements/Card/CardView";
import Paginator from "../../Elements/Paginator/Paginator";
import classes from "./MyCard.module.css";
import ConstantesAPI from "../../../../utils/API/Constantes";
import { getScMinteos } from "../../../../utils/API/ApiFunctions";
import { consensusAddUnverified, consensusSetVerified } from "../../../../utils/Consensus/Consensus";
import LoaderCardsView from "../../Elements/Card/LoaderCardsView";
import FilterCard from "../../Elements/Filters/FilterCardView";
import { FilterMyCards as FILTER_CARDS } from "../../../Services/Filters/FilterMyCards";
import DetailCardView from "../../Elements/Card/DetailCardView";
//import { useCollectionCards } from "../../../Services/DataCards/useCollectionCards";

import { actualLoader } from "../../../Services/Loader/LoaderFunctions";
import { saveTxMints } from "../../../../utils/API/ApiFunctions";

import useDetectMobile  from "../../../../utils/Hooks/useDetectMobile";

const MyCard = (props) => {

    const [ myCards, setMyCards ] = useState([]);
    const [ myViewCards, setMyViewCards ] = useState([]);
    const [ currentViewCardsFiltered, setCurrentViewCardsFiltered ] = useState([]);
    const [ isLoadCards, setIsLoadCards ] = useState(false);
    const [ toggleViewDetail, setToggleViewDetail ] = useState(false);
    const [ currentInfoCardView, setCurrentInfoCardView ] = useState({});

    const [ needReload, setNeedReload ] = useState(true);

    const [ mintInProcess, setMintInProcess ] = useState(false);

    const { isMobile } = useDetectMobile();

    const { DECollection } = props.contracts;

    const infoAccount = props.infoAccount;
    const account = infoAccount.account;
    const web3 = props.web3;
    const contracts = props.contracts;
    const reloadUserDetails = props.reloadUserDetails;

    const limiteParaMintear = 56; // Tiene que concordar con la API

    //const { collection, isLoading } = useCollectionCards();
    const collection = props.collection;
    const isLoading = props.isLoading;
    const rutaDefaultCarta = actualLoader;

    useEffect(() => {

        const loadCards = async () => {
            setNeedReload(false);
            await reloadUserDetails(web3, contracts, infoAccount.account, false);

            setTimeout(async () => {
                const [auxMyCards, auxMyViewCards] = await ServerCards({
                    account:infoAccount.account,
                    urlAPIServer: ConstantesAPI().RUTA_API,
                    urlCardServer: ConstantesAPI().RUTA_CARDS_MEDIA,
                    defaultImage: ConstantesAPI().DEFAULT_CARD_IMAGE,
                    defaultVideo: rutaDefaultCarta
                });

                setMyCards(auxMyCards);
                setMyViewCards(auxMyViewCards);
                setCurrentViewCardsFiltered(auxMyViewCards);
                setIsLoadCards(true);
            }, 1500);
        }

        infoAccount && needReload && !isLoading && loadCards();
        
    }, [infoAccount, needReload, isLoading, web3, contracts, reloadUserDetails, rutaDefaultCarta])

    // ----------------------------
    // ALERTAS DE INFORMACIÓN
    // ----------------------------

    const confirmAlert = async () => {

        var todoOk = false;

        const respuesta = await getScMinteos(account);
        // ----------
        const longitud = respuesta.length;
        let cantidad = myCards.length;
        let datosMinteo = null;

        if(longitud === 1) {
            datosMinteo = respuesta[0];
            todoOk = true;
        } 

        const esRedondo = (cantidad % limiteParaMintear) === 0;
        const checkSteps = esRedondo ? cantidad/limiteParaMintear : Math.floor((cantidad/limiteParaMintear))+1;

        var msg;
        if(cantidad > limiteParaMintear) {
            msg = "The cards will go from being on the server to your Polygon wallet. <br/><br/><b>Wow, you have a lot of NFTs! Congratulations!</b><br/><br/><b>You need " + checkSteps + " transactions to mint all the NFTs.</b><br/>Let's mint the first round, are you ready?";
        } else {
            msg = "The cards will go from being on the server to your Polygon wallet. <br/><br/><b>Are you ready?</b>";
        }
        if(todoOk) {
            Swal.fire({
                title: "You are going to mint your cards!",
                width: "50rem",
                background: "#131312",
                color: "#FFF",
                html: msg,
                icon: "question",
                showConfirmButton: true,
                confirmButtonText: 'Yes, mint now!',
                showCancelButton: true,
                denyButtonText: 'Cancel',
                footer: 'You will have to confirm the transaction with your wallet',
            }).then((result) => {
                if(result.isConfirmed){
                    mintCards(datosMinteo);
                } else if(result.isDenied){
                    Swal.fire({
                        title: "Order canceled",
                        text: "No transaction started.",
                        width: "50rem",
                        background: "#131312",
                        color: "#FFF",
                        icon: "info"
                    });
                }
            });
        } else {
            errorAlert("You dont have more mints!");
            setNeedReload(true);
        }
    }

    const infoAlert = (fase, max) => {
        //var titulo = "Request in process (" + fase + "/" + max + ")";
        Swal.fire({
            title: "Request in process",
            text: "Accept the transaction in your wallet to continue.",
            icon: "info",
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    const successAlert = (txId) => {
        const haveMore = myCards.length > 56 ? true : false;
        Swal.fire({
            title: "Transaction completed!",
            html:
                'The transaction has been confirmed. <br/>'+
                '<div class="alert alert-primary" role="alert">Transaction Hash<br/><br/><b>' + txId + '</b></div><br/><br/>' + 
                '<a class="btn btn-primary" target="_blank" href="'+ ConstantesAPI().NETWORK_SCANTX + txId + '">View on Block Explorer</a>',
            icon: "success",
            showConfirmButton: haveMore,
            confirmButtonText: "Next mint",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Close",
            width: "60rem",
            background: "#131312",
            color: "#FFF",
        }).then((result) => {
            if(result.isConfirmed) confirmAlert();
            if(result.isDenied || result.isDismissed || result.dismiss) setNeedReload(true);
        });
    }

    const errorAlert = (error) => {
        var mensaje = null;
        if(error.includes("SIGNATURE ERROR: What are you trying to do?")){
            mensaje = "SIGNATURE ERROR: What are you trying to do?";
        } else if(error.includes("LENGTH ERROR: Data malformed")){
            mensaje = "LENGTH ERROR: Data malformed";
        } else if(error.includes("ERROR: This transaction is already in our system.")) {
            mensaje = "ERROR: This transaction is already in our system.";
        } else if(error.includes("gas required exceeds allowance")) {
            mensaje = "ERROR: Gas required esceeds allowance.";
        } else if(error.includes("insufficient funds for gas * price + value")){
            mensaje = "ERROR: You do not have enough MATIC to complete the transaction.";
        } else {
            mensaje = error;
        }
        Swal.fire({
            title: "Transaction failed!",
            html: '<b>' + mensaje +'</b>',
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            cancelButtonText: 'Close',
        });
    }

    const mintCards = (datosMinteo) => {
        const minteaCartas = async (datosMinteo) => {
            infoAlert();
            setMintInProcess(true);
            await callMintCard(datosMinteo[0], datosMinteo[1], (datosMinteo[2]).toString());
        }

        DECollection && minteaCartas(datosMinteo)
    }

    const callMintCard = async (cardIds, txIds, firma) => {

        var peticionOk = false;
                
        try {
            await DECollection.mintCards.call(cardIds, txIds, firma, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((r) => {
                peticionOk = true;
            }).catch((e) => {
                errorAlert(e.message);
                setNeedReload(true);
            })
        } catch(error){
            errorAlert(error.message);
            setNeedReload(true);
        }

        if(peticionOk) {
            const checkId = await consensusAddUnverified(account, "NFTs Minted", JSON.stringify(txIds));
            if(!checkId) {
                errorAlert("You already have a transaction in process.<br/> Wait for the blockchain to finish and refresh the page.");
                return;
            }

            await DECollection.mintCards(cardIds, txIds, firma, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((result) => {
                successAlert(result.tx);
                saveTxMints(account, result.tx);
                saveUsedMints(account, txIds);
                consensusSetVerified(account, checkId, result.tx);
                setNeedReload(true);
            }).catch((e) => {
               errorAlert(e.message);
            }).finally(() => {
                setMintInProcess(false);
                setNeedReload(true);
            });

        }
        
    }

    const getJsonData = async (infoCard) => {
        if(infoCard !== undefined) {
            //atributos
            const id = infoCard.id;
            let clearInfoCards;
            const encontrado = collection.filter(o1 => o1.index === id);
            if(encontrado) clearInfoCards = encontrado[0];
            /*
            const urlFinal = "https://nft-hub.darkearth.gg/cards/"+id+".json";
        
            const dirtyInfoCards = await fetch(urlFinal);
            const clearInfoCards = await dirtyInfoCards.json();
            */
            return clearInfoCards;
        }
    }

    const onClickCardView = async (infoCard) => {
        let newInfoCard = infoCard;
        if(infoCard.hasOwnProperty('id') && infoCard.hasOwnProperty('idCard')){
            newInfoCard = await getJsonData(infoCard);
        }
        setCurrentInfoCardView(newInfoCard);
        setToggleViewDetail(!toggleViewDetail);
    }

    const updateCurrentFilterViewCards = (currentFilterItems = {name: [], faction: [], mintType: [], rarity: [], type: []}) => {
        setCurrentViewCardsFiltered(FILTER_CARDS(myViewCards, currentFilterItems));
    }

    return (
        <Fragment>

            {toggleViewDetail && DetailCardView({infoCard: currentInfoCardView, handleToggleDetailCardView: onClickCardView})}  

            <div className={classes.centrador}>
                <Animated animationIn="zoomInDown" animationOut="fadeOut" isVisible={true}>
                    <h1 className="display-6 fw-bolder text-white pb-4">MY PRE-MINTED NFTs</h1>
                </Animated>
                { !isLoadCards ? 
                    <LoaderCardsView loadingText="Synchronizing and loading your cards" />
                :
                    myCards.length !== 0 ?
                        <Fragment>
                            <Animated animationIn="zoomInDown" animationOut="fadeOut" animationInDelay={300} isVisible={true}>
                                <p className="text-white fw-bolder">YOU HAVE {myCards.length} CARDS AVAILABLES FOR MINT</p>
                                        <center>
                                            <button type="button" className={ !isMobile ? "btn btn-outline-light p-4 mb-4 w-25" : "btn btn-outline-light p-4 mb-4 w-75"} onClick={confirmAlert} disabled={mintInProcess}>
                                                <img src="images/iconDark.png" alt="Logo Dark Earth" />
                                                <span className="m-2 fw-bolder">MINT ALL</span>
                                            </button>
                                        </center>
                            </Animated>
                            <FilterCard handleUpdateCurrentFilterOwner={updateCurrentFilterViewCards} />
                            <Container fluid>
                                <Paginator arrayViewItems={currentViewCardsFiltered} countItemsPerPage={8} handleElementView={CardView} handleElementViewOnClick={onClickCardView}/>
                            </Container>
                        </Fragment>
                    :                    
                    <LoaderCardsView loadingText="No cards available to mint" />
                }
            </div>

        </Fragment>
    );

}

const saveUsedMints = async (account, txIds) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        txIds: txIds
    }

    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/useMints.php`, {
        method: "POST",
        body: cargaUtil,
    });

    //const exitoso = await respuesta.json();
}


export default MyCard;