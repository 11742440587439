import { useEffect, useState, useRef } from "react";
import { getAndUpdateWalletsData, getIdGeneralFromTypes } from "../API/ApiFunctions";
import { waitInfoAccount } from "./FastLoading";

export const useLoadWalletsDataFromDB = () => {

    const [ tokenSync, setTokenSync ] = useState(false);
    const [ isFullLoad, setIsFullLoad ] = useState(false);
    const [ isReloading, setIsReloading ] = useState(false);


    const [ userNotUsedTokens, setUserNotUsedTokens] = useState([]);
    const [ userNotUsedTokensTypes, setUserNotUsedTokensTypes] = useState([]);
    const [ userTokensTypes, setUserTokensTypes] = useState([]);
    const [ userTokensIds, setUserTokensIds] = useState([]);
    const [ userTokensIsUsed, setUserTokensIsUsed ] = useState([]);
    const [ userTokenIdGenerales, setUserTokenIdsGenerales ] = useState([]);
    const [ userNotUsedTokenIdGenerales, setUserNotUsedTokenIdsGenerales ] = useState([]);

    let userTokenInfo = useRef([]);
    
    useEffect(() => {
        const reloadData = async () => {
            setIsReloading(true);
            const infoAccount = await waitInfoAccount();
            const wallet = infoAccount.account;
            const tokenIds = infoAccount.collectionDetails.cardsIds;

            try {
                const tokenInfo = await getAndUpdateWalletsData(wallet, tokenIds, isFullLoad);
                userTokenInfo.current = tokenInfo;
                let notUsedIds = [];
                let notUsedTypes = [];
                let userTokenIds = [];
                let userTokenTypes = [];
                let isUsedTokens = [];
        
                tokenInfo.forEach(element => {

                    if(!element.isUsed) {
                        notUsedIds.push(element.tokenId);
                        notUsedTypes.push(element.tokenType);
                    }

                    userTokenIds.push(element.tokenId);
                    userTokenTypes.push(element.tokenType);
                    isUsedTokens.push(element.isUsed);
                });

                const notUsedIdGenerales = await getIdGeneralFromTypes(notUsedTypes);
                const idGenerales = await getIdGeneralFromTypes(userTokenTypes);
                // Seteamos todos los datos
                setUserNotUsedTokenIdsGenerales(notUsedIdGenerales);
                setUserTokenIdsGenerales(idGenerales);
                setUserNotUsedTokens(notUsedIds);
                setUserNotUsedTokensTypes(notUsedTypes);
                setUserTokensIds(userTokenIds);
                setUserTokensTypes(userTokenTypes);
                setUserTokensIsUsed(isUsedTokens);
                setTokenSync(true);
                setIsFullLoad(false);
                setIsReloading(false);
            }catch(error) {
                console.log("ERROR in useLoadWalletsDataFromDB -> ", error);
            }
        }

        !tokenSync && !isReloading && reloadData();

    }, [tokenSync, isReloading, isFullLoad, userNotUsedTokens, userNotUsedTokensTypes, userTokensIds, userTokensTypes, userTokensIsUsed])

    const haveDesync = (fullReload = false) => {
        setIsFullLoad(fullReload);
        setTokenSync(false);
    }

    return {
        tokenSync,
        haveDesync,
        userNotUsedTokens,
        userNotUsedTokensTypes,
        userTokensTypes,
        userTokensIds,
        userTokenInfo,
        userTokensIsUsed,
        userNotUsedTokenIdGenerales,
        userTokenIdGenerales
    };

}

export default useLoadWalletsDataFromDB;