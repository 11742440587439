import { Container, Row, Col, Image } from "react-bootstrap";

const CardGallery = () => {
    return (
        <Container fluid>
            <Row className="w-50 m-4 p-4 mx-auto">
                <p className="m-4 text-white display-4 fw-bold">Our artists</p>
                <Col>
                    <Image fluid src="images/firmasArte/Zayas.png" alt="Zayas"/>
                </Col>
                <Col>
                     <Image fluid src="images/firmasArte/Ruben.png"  alt="Ruben"/>
                 </Col>
                 <Col>
                     <Image fluid src="images/firmasArte/Jesus.png" alt="Jesus" />
                 </Col>
                 <Col>
                    <Image fluid src="images/firmasArte/Jana.png"  alt="Jana"/>
                </Col>
                <Col>
                    <Image fluid src="images/firmasArte/Hernan.png"  alt="Hernan"/>
                </Col>
                <Col>
                    <Image fluid src="images/firmasArte/Guillermo.png"  alt="Guillermo"/>
                </Col>
                <Col>
                    <Image fluid src="images/firmasArte/Carlos.png"  alt="Carlos"/>
                </Col>
            </Row>
        </Container>
    );
}

export default CardGallery;