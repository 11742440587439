import { ViewGalleryCard } from "./ViewGalleryCard";

/**
 * Devuelve un conjunto de componentes tipo ViewGalleryCard según el array de StandardInfoCard pasado como prop.
 * @param {[]} arrayStandardInfoCard 
 * @returns Array de componentes de View Gallery Card.
 */
export const ViewCollectionGalleryCard = ({arrayStandardInfoCard, handleElementViewOnClick}) => {
    if(arrayStandardInfoCard === []){
        return (<></>);
    }
    return (arrayStandardInfoCard.map(standardInfoCard => ViewGalleryCard(standardInfoCard, handleElementViewOnClick)));
}