import Swal from "sweetalert2";

const ErrorAlertView = (textError) => {
    let mensaje = null;
    if(textError.includes("User denied transaction signature")){
        mensaje = "User denied transaction signature";
    } else if(textError.includes("caller has no BURNER ROLE")){
        mensaje = "You dont have Burner ROLE.";
    } else {
        mensaje = textError;
    }
    
    Swal.fire({
        title: "Transaction failed!",
        html: '<b>' + mensaje +'</b>',
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        width: "50rem",
        background: "#131312",
        color: "#FFF",
        cancelButtonText: 'Close',
    });
}

export default ErrorAlertView;