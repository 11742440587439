import { useState, Fragment, useEffect } from "react";
import { Image } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import { AiOutlineCheckCircle, AiOutlineSync } from "react-icons/ai";

import classes from "./BDLoader.module.css";
//import useLoadWalletsDataFromDB from "../../../../utils/FastLoading/useLoadWalletsDataFromDB";
import { consensusAddCheatUnverified } from "../../../../utils/Consensus/Consensus";
import { waitWeb3Api } from "../../../../utils/FastLoading/FastLoading";

import useDetectMobile  from "../../../../utils/Hooks/useDetectMobile";

const BDLoader = (props) => {

    const { isMobile } = useDetectMobile();
    const [ isLoading, setIsLoading ] = useState(false);
    const useLoad = props.useLoad;

    const { tokenSync, haveDesync } = useLoad;
    const account = props.account;

    const message = props.message;
    const needReload = props.needReload;
    const handleNeedReload = props.handleNeedReload;
    const getBlockchainData = props.getBlockchainData;

    const [ isFirstTime, setIsFirstTime ] = useState(true);
    
    const insertAllFlags = async (account) => {
        await consensusAddCheatUnverified(account, "Open Capsules", "Manual reload");
        await consensusAddCheatUnverified(account, "NFTs Minted", "Manual reload");
    }

    useEffect(() => {

        const toastConfig = {
            position: "bottom-right",
            autoClose: 7500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        };

        const reloadData = async () => {
            handleNeedReload(false);
            setIsLoading(true);
            setIsFirstTime(false);
            toast.info("RE-SYNC | Please wait...", toastConfig);
            const web3Api = await waitWeb3Api();
            await insertAllFlags(account);
    
            await getBlockchainData(web3Api.provider, web3Api.web3, false);
            
            haveDesync(true);
    
            toast.info("Rebuilding cache...", toastConfig);
            setIsLoading(false);
        };

        needReload && reloadData();

    }, [needReload, handleNeedReload, account, haveDesync, getBlockchainData, isFirstTime])

    useEffect(() => {

        const toastConfig = {
            position: "bottom-right",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        };

        !isFirstTime && tokenSync && !isLoading && toast.success("All in sync! 👌", toastConfig);
    }, [tokenSync, isLoading, isFirstTime])

    return (
        <Fragment>
            { !isMobile ?
                 (!tokenSync || isLoading) ? 
                    <span className="fw-bold">
                        <Spinner animation="border" variant="dark" size="sm" className="mx-2 p-1" role="status">
                            <span className="visually-hidden">Loading...</span> 
                        </Spinner>
                        {message}
                    </span>
                :
                    <span className="fw-bold"><Image src="images/loader/ok.png" className={classes.isOkImg}/> {message}</span>
            :
                (!tokenSync || isLoading) ? 
                    <span className="fw-bold text-danger"><AiOutlineSync/> RE-SYNC<br/></span>
                :
                <span className="fw-bold text-white"><AiOutlineCheckCircle/> SYNC<br/></span>
            }
            
        </Fragment>
    );
}

export default BDLoader;