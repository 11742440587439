import { FilterTypes } from "./FilterTypes";

export const FilterMyNFTs = (arrayAllMyCardsToFilter = [], filters = FilterTypes) => {
    const auxArrayNFTs = arrayAllMyCardsToFilter.filter(
        (dirtyNFT) => {
            const nft = {
                name: dirtyNFT.infoCard.name,
                mintType: dirtyNFT.infoCard.mintType,
                rarity: dirtyNFT.infoCard.rarity,
                type: dirtyNFT.infoCard.type,
                faction: dirtyNFT.infoCard.faction
            }
            const isName = filters.name.includes('ALL')? true : filters.name.includes(nft.name);
            const isFaction = filters.faction.includes('ALL')? true : filters.faction.includes(nft.faction);
            const isMintType = filters.mintType.includes('ALL')? true : filters.mintType.includes(nft.mintType);
            const isRarity = filters.rarity.includes('ALL')? true : filters.rarity.includes(nft.rarity);
            const isType = filters.type.includes('ALL')? true : filters.type.includes(nft.type);
            return isName && isFaction && isMintType && isRarity && isType;
    });

    return auxArrayNFTs;
}

/*
nft = {
    infoCard:{
        name: "Sonic Boom",
        description: "When the MXCorp want to capture enemies alive, they use these sonic weapons that quickly incapacitate them. However, for some unknown reason, the sound of this weapon has a strange effect upon Leira’s fauna, and sometimes attracts blue spiny mammals."
        attributes: [
            0: {trait_type: 'Edition', value: 'Genesis'}
            1: {trait_type: 'Mint type', value: 'Steel'}
            2: {trait_type: 'Rarity', value: 'Basic'}
            3: {trait_type: 'Type', value: 'Support'}
            4: {trait_type: 'Faction', value: 'Terrans'}
            5: {trait_type: 'Used', value: false}
            6: {trait_type: 'Ilustrator', value: 'Rodrigo Zayas'}
            7: {display_type: 'date', trait_type: 'Manufactured', value: 1654888647}
        ]
    }
}

*/