import { useEffect, useState } from "react";
import { Image} from "react-bootstrap";

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
    const [isLoadCard, setIsLoadCard] = useState(false);

    const handleOnLoad = ()=>{
        setIsLoadCard(true);
    }

    useEffect(() => {
    }, [src, isLoadCard]);

    return (
        <Image fluid {...{ src: isLoadCard?src:placeholderSrc, ...props }} alt={props.alt || ""} onLoad = {handleOnLoad}/>
    );
};

export default ProgressiveImg;