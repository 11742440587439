import React, { Fragment } from "react";

import classes from "./NoDisponible.module.css";

import Card from "../../components/UI/Elements/Card/Card";

const NoDisponible = (props) => {

    return (
        <Fragment>
            <div className={`${classes.separador}`}/>
            <div className={`${classes.centrador}`}>
                <Card className={`${classes.info}`}>
                    <h1 className="display-1 p-5 fw-bolder">RESTRICTED AREA</h1>
                    <img className='img-fluid shadow-4' src="images/under_construction.png" alt="Closed zone"/>
                    <h3 className="p-5 fw-bold display-6">YOU CANNOT ACCESS THIS AREA.</h3>
                </Card>
            </div>
        </Fragment>
    );

}

export default NoDisponible;
