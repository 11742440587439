export const FilterTypes = {
    name: [],
    faction: ['Terrans', 'Gladios', 'Bioshara', 'Musktars', 'Askari'],
    mintType: ['Steel', 'Titanium', 'Cosmium','Master Art', 'Faction banner', 'KeyArt'],
    rarity: ['Basic', 'Rare', 'Epic', 'Legendary', 'Special'],
    type: ['Unit', 'Support', 'Equipment', 'Tactic'],
    specialType: ['Master Art', 'Faction banner', 'KeyArt']
}

export const EnumInfoCardType = {
    preMint: 'preMint',
    nft: 'nft'
}

export const EnumFilterType = {
    name: 'name',
    faction: 'faction',
    mintType: 'mintType',
    rarity: 'rarity',
    type: 'type',
    //specialType: 'mintType'
}

export const MintTypeRanges = {
    steel: {min: 1, max: 40},
    titanium: {min: 41, max: 80},
    cosmium: {min: 81, max: 93},
    masterArt: {min: 94, max: 133},
    factionBanner: {min: 134, max: 138},
    keyArt: {min: 139, max: 139},
    special: {min: 94, max: 139}
}

export const EnumMintType = {
    Steel: 'steel',
    Titanium: 'titanium',
    Cosmium: 'cosmium',
    MasterArt: 'masterArt',
    FactionBanner: 'factionBanner',
    KeyArt: 'keyArt',
    Special: 'special'
}

export const FilterTypesForAutoComplete = {
    name: [
        {id: 0, name: 'Medical team'}, 
        {id: 1, name: 'Captain Harmony'}, 
        {id: 2, name: 'Dealer'}, 
        {id: 3, name: 'Tax Collector'}, 
        {id: 4, name: 'Banthry Trainer'}, 
        {id: 5, name: 'Special Force'}, 
        {id: 6, name: 'Frenzied Supersoldier'}, 
        {id: 7, name: 'Armored Tank'}, 
        {id: 8, name: 'Intelligence hacker'}, 
        {id: 9, name: 'Annihilator'}, 
        {id: 10, name: 'Sentry Drone'}, 
        {id: 11, name: 'Sonic Boom'}, 
        {id: 12, name: 'Tax Burearu'}, 
        {id: 13, name: 'Skydagger'}, 
        {id: 14, name: 'Nisal Den'}, 
        {id: 15, name: 'Secret Food Store'}, 
        {id: 16, name: 'Survival of the Strong'}, 
        {id: 17, name: 'Recruitment center'}, 
        {id: 18, name: 'Implant center'}, 
        {id: 19, name: 'Wasp Drone'}, 
        {id: 20, name: 'Pepper grenade'}, 
        {id: 21, name: 'Sniper rifle'}, 
        {id: 22, name: 'Dark Energy'}, 
        {id: 23, name: 'Sleeping pills'}, 
        {id: 24, name: 'Bloodied Blade'}, 
        {id: 25, name: 'Training Whip'}, 
        {id: 26, name: 'Energy Armor'}, 
        {id: 27, name: 'Sacred Gladio'}, 
        {id: 28, name: 'Toolbox'}, 
        {id: 29, name: 'Blueprints'}, 
        {id: 30, name: 'Defensive formation'}, 
        {id: 31, name: 'Force Field activation'}, 
        {id: 32, name: 'Peace negotiations'}, 
        {id: 33, name: 'Cosmium OPA'}, 
        {id: 34, name: 'Stand In Front of enemies'}, 
        {id: 35, name: 'Lofty Robbery'}, 
        {id: 36, name: 'It is just a scratch'}, 
        {id: 37, name: 'Covering fire'}, 
        {id: 38, name: 'Ransomware attack'}, 
        {id: 39, name: 'Cosmium Surge'},
    ],
    faction: [
        {id: 0, name: 'Terrans'}, 
        {id: 1, name: 'Gladios'}, 
        {id: 2, name: 'Bioshara'}, 
        {id: 3, name: 'Musktars'}, 
        {id: 4, name: 'Askari'}
    ],
    mintType: [
        {id: 0, name: 'Steel'}, 
        {id: 1, name: 'Titanium'}, 
        {id: 2, name: 'Cosmium'}, 
        {id: 3, name: 'Master Art'}, 
        {id: 4, name: 'Faction banner'}, 
        {id: 5, name: 'KeyArt'}
    ],
    rarity: [
        {id: 0, name: 'Basic'}, 
        {id: 1, name: 'Rare'}, 
        {id: 2, name: 'Epic'}, 
        {id: 3, name: 'Legendary'},
        {id: 4, name: 'Special'}
    ],
    type: [
        {id: 0, name: 'Unit'}, 
        {id: 1, name: 'Support'}, 
        {id: 2, name: 'Equipment'}, 
        {id: 3, name: 'Tactic'}, 
        {id: 4, name: 'Settlement'}
    ]
}