import { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { Container, Row } from "react-bootstrap";

import classes from "./Paginator.module.css";

const Paginator = ({ arrayViewItems, countItemsPerPage, handleElementView, handleElementViewOnClick }) => {
    
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  

    useEffect(() => {
      const endOffset = itemOffset + countItemsPerPage;
      const newCurrentItems = arrayViewItems.slice(itemOffset, endOffset);

      if(newCurrentItems.length > 0){
        setCurrentItems(newCurrentItems);
        setPageCount(Math.ceil(arrayViewItems.length / countItemsPerPage));

      }else{
        setCurrentItems(arrayViewItems.slice(0, countItemsPerPage));
        setPageCount(Math.ceil(arrayViewItems.length / countItemsPerPage));
      }

    }, [itemOffset, arrayViewItems, countItemsPerPage]);
  

    const handlePageClick = (event) => {

      const newOffset = (event.selected * countItemsPerPage) % arrayViewItems.length;
      setItemOffset(newOffset);

    };
  
    return (
      <Container fluid>
        <Row xs={1} sm={2} xl={4}>
          {currentItems.map( element => handleElementView(element, handleElementViewOnClick))}
        </Row>
        <Row>
          <div className={classes.selector}>
              <ReactPaginate
                  nextLabel="➡️"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="⬅️"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
              />
          </div>
        </Row>
      </Container>
    );
}

export default Paginator