import React, { Fragment } from "react";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import MyCard from "../../components/UI/pages/MyCards/MyCard";
import NoDisponible from "../NoDisponible/NoDisponible";

const MyCards = (props) => {


  const { isConnected,  collectionDetails } = props.infoAccount;
  const activado = props.activo;

  return (
      <Fragment>
          <main>

              { !isConnected ?
                  <NoConectado/>

                  :
                  activado ?
                    <MyCard
                        contracts = { props.web3Api.contracts }
                        infoAccount = { props.infoAccount }
                        collectionDetails = { collectionDetails }
                        reloadUserDetails = { props.reloadUserDetails }
                        web3 = { props.web3Api.web3 }
                        collection = { props.collection }
                        isLoading = { props.isLoading }
                    />
                  :
                    <NoDisponible/>
              }

          </main>
      </Fragment>
  );

}

export default MyCards;