import { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import ReactPlayer from "react-player";
import { Tooltip, OverlayTrigger, Card as CardB, Container, Row, Col  } from "react-bootstrap";

import { useSearchParams } from "react-router-dom";

// Efecto confetti
import Confetti from 'react-confetti'
import { useReward } from 'react-rewards';

import Card from "../../Elements/Card/Card";
import classes from "./BuyZone.module.css";
import Swal from "sweetalert2";
import Warning from "../../Main/Conectado/Warning/Warning";

import ConstantesAPI from "../../../../utils/API/Constantes";
import { referralAddBuy, referralCheckCode } from "../../../../utils/API/ApiFunctions";

const BuyZone = props => {

    // PromoCODE por URL
    const [searchParams] = useSearchParams();
    const paramPromoCode = searchParams.get("refCode") || false;

    // --------------------------------------
    // CONFETTI CUANDO SE REALIZA LA COMPRA
    // --------------------------------------
    const width = window.innerWidth-100;
    const height = window.innerHeight*3;

    const confettiConfig = {
        elementCount: 190
    }

    const {reward: confettiReward} = useReward('confettiReward', 'confetti', confettiConfig);
    const {reward: balloonsReward} = useReward('balloonsReward', 'balloons');

    const [ muestraConfeti, setMuestraConfeti ] = useState(false);
    // ------------------------------------

    // Precio obtenidos del SC
    const [ precioUSD, setPrecioUSD ] = useState(null);
    const [ precioMatic, setPrecioMatic ] = useState(null);
    const [ precioFinal, setPrecioFinal ] = useState(null);

    // Cantidad marcada
    const [ cantidad, setCantidad ] = useState(1);

    // Metodo de pago
    const [ metodoPago, setMetodoPago ] = useState("MATIC");

    // Obteniendo los SC
    const { contracts, web3, networkId } = props.web3Api;
    const { MysteryCapsule, USDC } = contracts;
    
    // Obteniendo la cuenta y datos de ella
    const account = props.account;
    const { mintableChest } = props.mintDetails;

    // Texto mostrado en el selector (input)
    const [ optionAmount, setOptionAmount ] = useState([]);

    const [ referralCode, setReferralCode ] = useState(paramPromoCode === false ? undefined : paramPromoCode);

    useEffect(() => {
        if(muestraConfeti) {
            setInterval(() => {
                setMuestraConfeti(false);
            }, 20000)
        }
    }, [muestraConfeti])

    const min = 1;
    const max = 150;

    const handleChange = event => {
        const value = Math.max(min, Math.min(max, Number(event.target.value)));
        setCantidad(value);
    };

    // --------------------------
    // OBTENER DATOS DEL SC
    // --------------------------
    // Get cantidad máxima para mintear por persona (por defecto)
    
    useEffect(() => {
        const loadMintableChest = async () => {
            const _mintableCapsules = mintableChest;

            var cadena = [];

            for(var i=1; i <= _mintableCapsules; i++){
                cadena.push(i)
            }

            setOptionAmount(cadena);
        }

        MysteryCapsule && loadMintableChest()
    }, [MysteryCapsule, mintableChest])
    

    useEffect(() => {

        const loadPriceMatic = async () => {
            const precioEnUSD = await MysteryCapsule.getDefaultPrice();
            setPrecioUSD(precioEnUSD.toString());

            // Precio obtenido del SC
            const _precio = await MysteryCapsule.priceInMatic.call();
            const _precioWei = web3.utils.fromWei(_precio, "ether");

            // Redondeo a 2 decimales
            const _precioRedondo = Number(_precioWei).toFixed(2);

            // Sumo 0.03 y vuelvo a redondear (fix bug)
            const _precioFinal = (Number(_precioRedondo)+Number(0.13)).toFixed(2);

            const _precioFixed = Number(_precioFinal).toFixed(2);

            setPrecioMatic(_precioFixed.toString());
        }

        MysteryCapsule && loadPriceMatic()
    }, [MysteryCapsule, setPrecioMatic, web3])

    useEffect(() => {

        if(metodoPago === "MATIC"){
            const fixed = Number(precioMatic * cantidad).toFixed(2);
            setPrecioFinal(fixed);
        } else if (metodoPago === "USDC"){
            setPrecioFinal(precioUSD * cantidad);
        }
        
    }, [precioMatic, precioUSD, metodoPago, cantidad]);

    const actualizaCantidad = (event) => {
        const cantidad = event.target.value;
        setCantidad(cantidad);
    };

    const actualizaMetodoPago = (event) => {
        const metodo = event.target.value;
        setMetodoPago(metodo.toString());
    };

    const actualizaReferral = (event) => {
        const refCode = event.target.value;
        setReferralCode(refCode.toString());
    };

    // ----------------------------
    // ALERTAS DE INFORMACIÓN
    // ----------------------------

    const confirmAlert = () => {
        Swal.fire({
            title: "Confirm your purchase",
            html: "You are going to buy <b>" + cantidad + " Mystery Capsules</b> for <b>" + precioFinal + " " + metodoPago + "</b><br/><br/>Do you agree?",
            icon: "question",
            showConfirmButton: true,
            confirmButtonText: 'Yes, buy now!',
            showCancelButton: true,
            denyButtonText: 'Cancel',
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            footer: 'You will have to confirm the purchase with your wallet',
        }).then((result) => {
            if(result.isConfirmed){
                comprarCapsula();
            } else if(result.isDenied){
                Swal.fire({
                    title: "Order canceled",
                    width: "50rem",
                    background: "#131312",
                    color: "#FFF",
                    text: "No transaction started.",
                    icon: "info"
                });
            }
        });
    }

    const infoAlert = (fase) => {
        var msg = "";
        var status = "";
        if(fase) {
            if(fase === 1) {
                msg = "Allowance in process...";
                status = " (1/2)";
            }
            if(fase === 2) {
                msg = "Payment in process...";
                status = " (2/2)";
            }
        }
        Swal.fire({
            title: "Request in process" + status,
            html: "Accept the transaction in your wallet to continue. <br/><b>" + msg + "</b>",
            icon: "info",
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    const successAlert = (txId, cantidad) => {
        Swal.fire({
            title: "Transaction completed!",
            html:
                'The transaction has been confirmed. <br/>'+
                '<div class="alert alert-primary" role="alert">Transaction Hash<br/><br/><b>' + txId + '</b></div><br/><br/>' + 
                '<a class="btn btn-primary" target="_blank" href="'+ ConstantesAPI().NETWORK_SCANTX + txId + '">View on Block Explorer</a>',
            icon: "success",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Close",
            width: "55rem",
            background: "#131312",
            color: "#FFF",
            showConfirmButton: false
        }).then((result) => {
            if(result.isDenied || result.isDismissed || result.dismiss) setMuestraConfeti(false);
        });
        
        manejarEnvioDeFormulario(account, txId, cantidad)
    }

    const errorAlert = (error) => {
        var mensaje = null;
        if(error.includes("User denied transaction signature")){
            mensaje = "User denied transaction signature";
        } else if(error.includes("must have minter role to mint")){
            mensaje = "You are not on the Whitelist. You can't buy.";
        } else if(error.includes("The contract is temporaly suspended")) {
            mensaje = "The contract is temporaly suspended. Please wait.";
        } else if(error.includes("Not enough funds sent!")) {
            mensaje = "The price changed while the purchase was processed. Please try again.";
        } else {
            mensaje = error;
        }
        Swal.fire({
            title: "Transaction failed!",
            html: '<b>' + mensaje +'</b>',
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showCancelButton: true,
            cancelButtonText: 'Close',
        });
    }

    // -------------------
    
    const confirmarCompra = async () => {
        if(referralCode !== undefined && referralCode !== "") {
            const respuesta = await referralCheckCode(account, referralCode);
            if(respuesta !== true) {
                errorAlert(respuesta.toString()); 
                return;
            }
        }
        confirmAlert();
    }

    const comprarCapsula = () => {
        const compraCapsula = async () => {

            // Comprobar el chainId
            const chainId = networkId;
            if(!(chainId === 137 || chainId === 80001)) return;

            // Comprobación de llamada
            var peticionOK = false;

            if(metodoPago === "MATIC"){ 
                // ------------------------
                const pago = web3.utils.toWei(precioFinal.toString(), 'ether');
                // Mensaje de información -> Transacción iniciada
                infoAlert();
                
                // PRIMERA LLAMADA PARA COMPROBAR SI CUMPLE LOS REQUISITOS
                try {
                    await MysteryCapsule.purchaseChest.call(cantidad, {
                        from: account,
                        value: pago.toString()
                    })
                    peticionOK = true;
                } catch(error){
                    errorAlert(error.message);
                }

                // UNA VEZ QUE CUMPLE LOS REQUISITOS HACEMOS LA LLAMADA TOTAL
                if(peticionOK){
                    await MysteryCapsule.purchaseChest(cantidad, {
                        from: account,
                        value: pago.toString(),
                        maxPriorityFeePerGas: null,
                        maxFeePerGas: null
                    }).then((result) => {
                        props.reloadUserDetails(web3, contracts, account, false);
                        // Celebración de compra
                        confettiReward();
                        balloonsReward();
                        setMuestraConfeti(true);
                        // ---------------------
                        if(referralCode !== undefined && referralCode !== "") referralAddBuy(account, referralCode, cantidad);
                        successAlert(result.tx, cantidad);
                    }).catch((e) => {
                        errorAlert(e.message);
                    });
                }
            }  else if(metodoPago === "USDC"){
                // ------------------------
                //const pago = precioFinal;
                let pago;
                if(chainId === 137) {
                    pago = web3.utils.toWei(precioFinal.toString(), 'Mwei');
                } else if(chainId === 80001) {
                    pago = web3.utils.toWei(precioFinal.toString(), 'ether');
                }
                

                // -----------------------------
                // 1º: Comprobamos el Allowance
                // -----------------------------
                let allowance = 0;

                try {
                    const checkAllowance = await MysteryCapsule.GetAllowance.call({
                        from: account
                    }).catch((error) => {
                        errorAlert(error.message);
                    });
                    allowance = checkAllowance.toString();
                } catch(e) {
                    errorAlert(e.message);
                }
                
                // -----------------------------------
                // 2º: Petición de permiso si es menor
                // -----------------------------------

                if(allowance < pago) {
                    infoAlert(1);
                    var peticion1 = null;
                    var tokenApproved = null;

                    try {
                        await USDC.approve.call(MysteryCapsule.address, pago, {
                            from: account
                        }).catch((error) => {
                            errorAlert(error.message);
                        });
                        peticion1 = true;
                    } catch(e) {
                        errorAlert(e.message);
                    }

                    if(peticion1) {
                        await USDC.approve(MysteryCapsule.address, pago, {
                            from: account,
                            maxPriorityFeePerGas: null,
                            maxFeePerGas: null
                        }).then((result) => {
                            tokenApproved = true;
                        }).catch((error) => {
                            errorAlert(error.message);
                        });
                        
                    }

                    
                    // -----------------------------------------
                    // Si el ya tengo permiso, ejecuto petición
                    // -----------------------------------------
                    
                    var peticion2 = null;

                    if(tokenApproved) {
                        infoAlert(2);
                        try {
                            await MysteryCapsule.AcceptPayment.call(cantidad, {
                                from: account,
                                value: "0"
                             }).catch((error) => {
                                errorAlert(error.message);
                            });
                            peticion2 = true;
                        } catch(e) {
                            errorAlert(e.message);
                        }

                        if(peticion2){
                            await MysteryCapsule.AcceptPayment(cantidad, {
                                from: account,
                                value: "0",
                                maxPriorityFeePerGas: null,
                                maxFeePerGas: null
                            }).then((result) => {
                                props.reloadUserDetails(web3, contracts, account, false);
                                // Celebración de compra
                                confettiReward();
                                balloonsReward();
                                setMuestraConfeti(true);
                                // ---------------------
                                if(referralCode !== undefined && referralCode !== "") referralAddBuy(account, referralCode, cantidad);
                                successAlert(result.tx, cantidad);
                            }).catch((e) => {
                                errorAlert(e.message);
                            });
                        }
                    }
                // -----------------------------------------------------
                } else { // EL ALLOWANCE ES MAYOR, HAGO EL PAGO DIRECTO
                    infoAlert(2);
                    var comprueboPago = false;

                    try {
                        await MysteryCapsule.AcceptPayment.call(cantidad.toString(), {
                            from: account,
                            value: "0"
                        });
                        comprueboPago = true;
                    } catch(e) {
                        errorAlert(e.message);
                    }

                    if(comprueboPago){
                        await MysteryCapsule.AcceptPayment(cantidad.toString(), {
                            from: account,
                            value: "0",
                            maxPriorityFeePerGas: null,
                            maxFeePerGas: null
                        }).then((result) => {
                            props.reloadUserDetails(web3, contracts, account, false);
                            // Celebración de compra
                            confettiReward();
                            balloonsReward();
                            setMuestraConfeti(true);
                            // ---------------------
                            if(referralCode !== undefined && referralCode !== "") referralAddBuy(account, referralCode, cantidad);
                            successAlert(result.tx, cantidad);
                        }).catch((e) => {
                            errorAlert(e.message);
                        });
                    }
                }
            }
        }
        
        MysteryCapsule && compraCapsula()
    }

    

    return (
        
        <Container>
            { muestraConfeti &&
                <Confetti
                    width={width}
                    height={height}
                />
            }
            
            { !props.canConnectToContract && <Warning/>}
            <Card className={classes.buyzone}>
                <Animated animationIn="zoomInDown" animationOut="fadeOut" isVisible={true}>
                    <div style={{fontSize: "4vw"}}>
                        <h1 className="fw-bolder text-white">BUY NOW!</h1>
                    </div>
                </Animated>
                <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                <Card className={classes.nft}>
                    
                    <span id="confettiReward" />
                    <span id="balloonsReward" />
                    <div className={classes.divVideoInfo}>
                        <ReactPlayer
                            url="videos/CAPSULE_VP9.webm"
                            playing
                            muted
                            loop
                            width='100%'
                            height='100%'
                        />
                    </div>
                    <Animated animationIn="zoomInDown" animationOut="fadeOut" animationInDelay={300} isVisible={true}>
                        <div style={{fontSize: "3vw"}}>
                            <h4 className="fw-bold">MYSTERY CAPSULE</h4>
                        </div>
                    </Animated>
                    <div className={classes.efecto}>
                        <Row xs={1} sm={3} lg={5} style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <Col sm={6} style={{ paddingRight: 0 }}>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <p className="fw-bold">PAYMENT</p>
                                        <CardB.Text className="fw-bold text-center">
                                        <select onChange = { actualizaMetodoPago } className="form-control btn btn-outline-light  text-center mx-auto" id="MethodPayment">
                                            <option>MATIC</option>
                                            <option>USDC</option>
                                        </select>
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                            </Col>
                            <Col sm={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <p className="fw-bold">QUANTITY</p>
                                        <CardB.Text className="fw-bold text-center">
                                        { !props.isPublicSale ?
                                        <select onChange = { actualizaCantidad } className="form-control btn btn-outline-light  text-center mx-auto" id="BuyQuantity">
                                            { optionAmount.map((element, index) => <option key={index}>{element}</option>) }
                                        </select>
                                        :
                                        <input
                                            className="text-center fw-bolder form-control btn btn-outline-light"
                                            type="number"
                                            min={1}
                                            max={150}
                                            step={1}
                                            value={cantidad}
                                            onChange={handleChange}
                                        />
                                        }
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                            </Col>
                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <p className="fw-bold">PRICE</p>
                                        <CardB.Title className="fw-bold">
                                            <span className="form-control btn btn-outline-light  text-center mx-auto">{precioFinal} {metodoPago}</span>
                                        </CardB.Title>
                                    </CardB.Body>
                                </CardB>
                            </Col>
                            <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <p className="fw-bold">REFERRAL</p>
                                        <CardB.Title className="fw-bold">
                                            <input className="form-control btn btn-light  text-center mx-auto" placeholder="Referral" onChange={actualizaReferral} value={referralCode}/>
                                        </CardB.Title>
                                    </CardB.Body>
                                </CardB>
                            </Col>
                            <Col style={{ paddingLeft: 0}}>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <p className="fw-bold">READY?</p>
                                        <CardB.Text className="fw-bold mx-auto">
                                        { !props.canConnectToContract ?
                                            <i className="alert alert-warning">Please, connect to Polygon</i>

                                        :

                                            (mintableChest <= 0 && !props.isPublicSale) ?
                                            <button
                                                disabled={true}
                                                type="button"
                                                className="form-control btn btn-outline-light  text-center mx-auto disabled">
                                                <b>DISABLED</b>
                                            </button>
                                            
                                            :
                                                metodoPago === "USDC" ?
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay = {
                                                        <Tooltip id={`tooltip-bottom`}>
                                                            <b>REMEMBER</b><br/>
                                                            1.- Accept allowance<br/>
                                                            2.- Accept payment
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button
                                                        onClick={confirmarCompra}
                                                        disabled={!props.canConnectToContract}
                                                        type="button"
                                                        className="form-control btn btn-outline-light  text-center mx-auto">
                                                        <b>BUY</b>
                                                    </button>
                                            
                                                </OverlayTrigger>
                                                :
                                                <button
                                                    onClick={confirmarCompra}
                                                    disabled={!props.canConnectToContract}
                                                    type="button"
                                                    className="form-control btn btn-outline-light  text-center mx-auto">
                                                    <b>BUY</b>
                                                </button>
                                        }
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                            </Col>
                        </Row>
                        </div>
                        { metodoPago === "USDC" &&
                            <div className="bg-warning text-black">
                                <h4 className="display-4 fw-bolder mt-3">ATTENTION!</h4>
                                <p className="fw-bolder">IF YOU PAY WITH USDC YOU WILL HAVE TO SIGN TWICE: <u>AUTHORIZATION</u> AND <u>TRANSFER</u>.</p>
                                <p className="fw-bolder">DO NOT WORRY, THE POLYGON NETWORK HAS VERY LOW FEES.</p>
                            </div>
                        }
                </Card>
                </Animated>
            </Card>
        </Container>
        
    );
}

// ----------------------------------------------
// AÑADIR TRANSACCIONES A BASE DE DATOS
// ----------------------------------------------

const manejarEnvioDeFormulario = async (account, tx, cantidad) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        tx: tx,
        type: "buy",
        amount: cantidad
    }
    const cargaUtil = JSON.stringify(datos);
    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/guardar_tx.php`, {
        method: "POST",
        body: cargaUtil,
    });
    //const exitoso = await respuesta.json();
}


export default BuyZone;