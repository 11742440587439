import React, { Fragment } from "react";
import CardGallery from "../../components/UI/pages/CardGallery/CardGallery";
import NoDisponible from "../NoDisponible/NoDisponible";

const CardsGallery = (props) => {

    const activado = props.activo;

    return (
        <Fragment>
            <main>
                    { activado ?
                        <CardGallery
                            collection = { props.collection }
                            isLoading = { props.isLoading }
                        />
                    :
                        <NoDisponible/>
                    }
            </main>
        </Fragment>
  );

}

export default CardsGallery;