import { Fragment, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";

import { EnumMintType, MintTypeRanges } from "../../../Services/Filters/FilterTypes";
import LoaderCardsView from "../../Elements/Card/LoaderCardsView";
import GalleryArtist from "./GalleryArtist";
import { ViewCollectionGalleryCard } from "./ViewCollectionGalleryCard";
//import { useCollectionCards } from "../../../Services/DataCards/useCollectionCards";
import DetailCollectionCardView from "./DetailCollectionCardView";

const CardGallery = (props) => {
    // --------------------------------------------------------
    const [ nftSection, setNftSection ] = useState(EnumMintType.Steel);
    // --------------------------------------------------------
    //const { collection, isLoading } = useCollectionCards();
    const collection = props.collection;
    const isLoading = props.isLoading;
    // --------------------------------------------------------
    const [ toggleViewDetail, setToggleViewDetail ] = useState(false);
    const [ currentInfoCardView, setCurrentInfoCardView ] = useState({});
    
    /**
     * Handle para que los componentes hijos puedan actualizar la sección de NFT.
     * @param {number} id identificador de la sección (EnumMintType).
     */
    const handleSection = (id) => {
        setNftSection(id);
    }

    /**
     * Ordena array de cartas especiales (para método sort) como: Faction Banner < Master Art < Key Art
     */
    const orderSpecialCardsCompare = (cardA = {index:-1, assetUrl:''}, cardB = {index:-1, assetUrl:''}) => {
        const levelCardA = (cardA.index >= MintTypeRanges.factionBanner.min && cardA.index <= MintTypeRanges.factionBanner.max)?0: cardA.index < MintTypeRanges.keyArt.min?1:2;
        const levelCardB = (cardB.index >= MintTypeRanges.factionBanner.min && cardB.index <= MintTypeRanges.factionBanner.max)?0: cardB.index < MintTypeRanges.keyArt.min?1:2;
    
        if(levelCardA > levelCardB){
            return 1;
        }
        if(levelCardA < levelCardB){
            return -1;
        }
        return 0;
    }

    /**
     * Devuelve un array de la colección filtrada.
     * @returns []
     */
    const getFilterArrayCards = () => {
        let auxArrayViewCards = collection.filter(card =>
            card.index >= MintTypeRanges[nftSection].min 
            && card.index <= MintTypeRanges[nftSection].max
        );

        if(nftSection === EnumMintType.Special && auxArrayViewCards !== []){
            return auxArrayViewCards.sort(orderSpecialCardsCompare);
        }
        
        return auxArrayViewCards;
    }

    const onClickCardView = async (infoCard) => {
        let newInfoCard = infoCard;
        // if(infoCard.hasOwnProperty('id') && infoCard.hasOwnProperty('idCard')){
        //     newInfoCard = await getJsonData(infoCard);
        // }
        setCurrentInfoCardView(newInfoCard);
        setToggleViewDetail(!toggleViewDetail);
    }

    return (

        <Fragment>

            {toggleViewDetail && DetailCollectionCardView({infoCard: currentInfoCardView, handleToggleDetailCardView: onClickCardView})}
            
            <Container fluid className="mx-auto text-center"> 

                <h1 className="display-1 fw-bolder text-white">NFT GALLERY</h1>

                { isLoading &&
                    <LoaderCardsView loadingText="Loading all NFTs..." />
                }
                { !isLoading &&
                    <Row className="m-4">
                        <Col>
                            <button type="button" className="btn btn-outline-light p-2 m-2" onClick={() => handleSection(EnumMintType.Steel)}>
                                <span className="m-2">Steel version</span>
                            </button>
                            <button type="button" className="btn btn-outline-light p-2 m-2" onClick={() => handleSection(EnumMintType.Titanium)}>
                                <span className="m-2">Titanium version</span>
                            </button>
                            <button type="button" className="btn btn-outline-light p-2 m-2" onClick={() => handleSection(EnumMintType.Cosmium)}>
                                <span className="m-2">Cosmium version</span>
                            </button>
                            <button type="button" className="btn btn-outline-light p-2 m-2" onClick={() => handleSection(EnumMintType.Special)}>
                                <span className="m-2">Specials</span>
                            </button>
                        </Col>
                    </Row>
                }
                { !isLoading && 
                    <ViewCollectionGalleryCard arrayStandardInfoCard = {getFilterArrayCards()} handleElementViewOnClick = {onClickCardView}/>
                }
                { !isLoading && 
                    <GalleryArtist/>
                }

            </Container>

        </Fragment>
    );

}
export default CardGallery;