import Countdown from 'react-countdown';
import {Animated} from "react-animated-css";
import { Container } from 'react-bootstrap';
import classes from './CountdownSale.module.css';
//import { isMobile } from '../../../../App';
import useDetectMobile  from '../../../../utils/Hooks/useDetectMobile';

const CountdownSale = (props) => {

    const presaleDate   = 1657526400000;
    //const saleDate      = 1657699200000;
    const { isMobile } = useDetectMobile();

    const Completionist = () => !isMobile ? <span className="fw-bold">NFT PUBLIC SALE <strong>IS NOW LIVE!</strong></span> : <span className="fw-bold">NFT PUBLIC SALE<br/> <strong>IS NOW LIVE!</strong></span>;

    const renderer = ({ days, hours, minutes, seconds, completed }) => {

        if (completed) {
          return <Completionist />;
        } else {
          return <div>
                    <span className="display-5 fw-bold mx-3">{days}</span> DAYS  
                    <span className="display-5 fw-bold mx-3">{hours}</span> HOURS 
                    <span className="display-5 fw-bold mx-3">{minutes}</span> MINUTES 
                    <span className="display-5 fw-bold mx-3">{seconds}</span> SECONDS
                </div>;
        }
      };

    return (
        <Container fluid>
            <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true} className={ !isMobile ? classes.countStyle : classes.countStyleMobile} animationInDelay={500}>
                <div style={{fontSize:"calc(12px + 0.5vw)"}}>
                    <Countdown
                        date={presaleDate}
                        renderer={renderer}
                    />
                </div>
            </Animated>
        </Container>
    );
};

export default CountdownSale;
//                <span className="display-7 fw-bold mb-4" align="center">PRE-SALE STARTS 11Th JULY</span>



/*
<div className="display-3 fw-bold">
                    <span className={classes.dayStyle}>{hours} Days</span>
                    <span className={classes.hourStyle}>{hours} Hours</span>
                    <span className={classes.minuteStyle}>{minutes} Minutes</span>
                    <span className={classes.secondStyle}>{seconds} Seconds</span>
                </div>;
*/