import { useState, useEffect } from "react";
import { collection$ } from "../../../utils/GlobalCollection";

export const useGlobalCollection = (globalState = collection$) => {
    const [ state, setState] = useState([]);

    const nextState = (newState)=>{
        globalState.next(newState);
    };

    useEffect(()=>{
        globalState.subscribe((newState)=>{
            setState(newState);
        });

        return ()=>{
            //globalState.unsubscribe();
        }
    }, [globalState])

    return [state, nextState];
}