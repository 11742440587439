
import { Col, Container, Row } from "react-bootstrap";
import { Fragment } from "react";
import Spinner from 'react-bootstrap/Spinner';
import HoverVideoPlayer from 'react-hover-video-player';
import { Animated } from "react-animated-css";

import Card from "../../Elements/Card/Card";
import ProgressiveImg from "../../Elements/ProgressiveImage/ProgressiveImage";
import ConstantesAPI from "../../../../utils/API/Constantes";
import classes from "./VideoApertura.module.css";

const VideoAperturaGridCardView = ({ cardsSrcOfCapsule = [{srcCard: "", isVideo: false}] }) => {
    
    const CardViewOfCapsule = ({srcCard, isVideo}) => {
        return (
            <Card className = {classes.card_Img}>
                {isVideo?
                    <HoverVideoPlayer
                        videoSrc = {srcCard}
                        preload = "auto"
                        restartOnPaused={true}
                        loop={true}
                        focused={true}
                        loadingOverlay = {
                            <Spinner animation="border" role="status" variant="light">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    />
                :
                    <ProgressiveImg 
                        src={srcCard} 
                        placeholderSrc={ConstantesAPI().DEFAULT_CARD_IMAGE} 
                        alt="Dark Earth Card"
                    />
                }
            </Card>
        )
    }

    return (
    <Fragment>
        { cardsSrcOfCapsule.length === 8 &&
            <Container className={classes.card_Container} fluid>
                <Row className={classes.card_Row}>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={300} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[0].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[0].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={600} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[1].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[1].isVideo} 
                            />
                            </Animated>
                        </Col>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={900} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[2].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[2].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={1200} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[3].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[3].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                </Row>
                <Row className={classes.card_Row}>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={1500} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[4].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[4].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={1800} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[5].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[5].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={2100} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[6].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[6].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                        <Col xs={2} xxl={2} className={classes.card_Column}>
                            <Animated animationIn="zoomIn" animationInDelay={2400} animationOut="fadeOut" isVisible={true}>
                                <CardViewOfCapsule 
                                    srcCard = {cardsSrcOfCapsule[7].srcCard} 
                                    isVideo = {cardsSrcOfCapsule[7].isVideo} 
                                />
                            </Animated>
                        </Col>
                    
                </Row>
            </Container>
        }
    </Fragment>
    );
}

export default VideoAperturaGridCardView;