import {  useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Fragment } from "react";
import { Animated } from "react-animated-css";
import { useNavigate } from "react-router-dom";
//import { AiFillCloseCircle } from 'react-icons/ai';

import classes from "./VideoApertura.module.css";
import PaginatorVideoApertura from "../../Elements/Paginator/PaginatorVideoApertura";

import useDetectMobile from "../../../../utils/Hooks/useDetectMobile";
import CenterLogo from "../../Elements/CenterLogo/CenterLogo";

const VideoApertura = ({openedCapsuleCards,handleReloadUserDetails,reloadVars,handleStopVideo,...props}) => {

    const [ videoAcabado, setVideoAcabado ] = useState(false);
    const [ videoLoading, setVideoLoading ] = useState(true);
    const [ myCapsuleCards, setMyCapsuleCards ] = useState([]);
    const { isMobile } = useDetectMobile();

    const navigate = useNavigate();

    const toggleCards = () => {
        const toggle = !videoAcabado;
        setVideoAcabado(toggle);
    }

    const toggleLoader = (valor) => {
        setVideoLoading(valor);
    }

    const handleCloseOpenCapsule = () => {
        handleStopVideo();
        handleReloadUserDetails(reloadVars);
        navigate("/pre-mint");
    }

    useEffect(() => {

        const loadCapsulecards = async () => {

            let auxMyCapsuleCards = [];

            if(openedCapsuleCards === []){return;}
            
            for (const dirtyCapsuleCard of openedCapsuleCards) {
                const cleanCapsuleCards = {srcCard: dirtyCapsuleCard.srcCard, isVideo: dirtyCapsuleCard.isVideo};
                auxMyCapsuleCards = auxMyCapsuleCards.concat(cleanCapsuleCards);
            }

            
            setMyCapsuleCards(auxMyCapsuleCards);
        }

        loadCapsulecards();

    }, [openedCapsuleCards])

    return (
        <Fragment>
            <Container fluid className={classes.videoApertura_Container}>
                <Row>
                    <Col>
                        <ReactPlayer
                            url="https://nft-hub.darkearth.gg/capsules/EXPERIENCIA_CARTAS_marco_1440p.mp4"
                            playing={!isMobile && !videoLoading && !videoAcabado}
                            muted={false}
                            volume={0.5}
                            onEnded = {toggleCards}
                            onBuffer = {() => toggleLoader(true)}
                            onBufferEnd = {() => toggleLoader(false)}
                            onReady = {() => toggleLoader(false)}
                            width = "auto"
                            height ="100vh"
                        />
                        
                        { videoLoading && 
                            <div className={classes.loader}>
                                <div className={classes.test}>
                                    <img className="img-fluid" width="10%" alt="Loader" src="images/coheteLoadingV3.gif"/>
                                </div>
                                <h1 className="display-1 fw-bolder">TRAVELING TO<br/>LEIRA...</h1>
                            </div>
                        }

                        { isMobile && 
                            <div className={classes.giraMovil}>
                                <div className="h-100 bg-black p-4">
                                    <CenterLogo/>
                                    <img className="img-fluid" width="50%" alt="Loader" src="images/rotate.gif"/>
                                    <h1 className="fw-bolder text-white m-4 p-4">PLEASE, ROTATE<br/>YOUR DEVICE!</h1>
                                </div>
                                
                            </div>
                        }

                    </Col>
                </Row>
            </Container>
            { (videoAcabado && !isMobile) &&
                <Animated className={classes.btn_close_videoApertura} animationIn="bounceInRight" animationInDelay={600} animationOut="fadeOut" isVisible={true}>
                    <div className="mx-auto text-center">
                        <button type="button" className="btn btn-warning" onClick={handleCloseOpenCapsule}>
                            <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                            <span className="p-3 fw-bolder">RETURN</span>
                        </button>
                    </div>
                </Animated>
            }
            { (videoAcabado && !isMobile) &&
                <PaginatorVideoApertura arrayViewItems={myCapsuleCards} countItemsPerPage = {8}/>
            }
        </Fragment>
    );

}


export default VideoApertura;