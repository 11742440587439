import { FilterTypes } from "./FilterTypes";

export const FilterMyCards = (arrayAllMyCardsToFilter = [], filters = FilterTypes) => {
    const auxArrayCards = arrayAllMyCardsToFilter.filter(
        (card) => {
            const isName = filters.name.includes('ALL')? true : filters.name.includes(card.infoCard.nombre);
            const isFaction = filters.faction.includes('ALL')? true : filters.faction.includes(card.infoCard.faccion);
            const isMintType = filters.mintType.includes('ALL')? true : filters.mintType.includes(card.infoCard.mintType);
            const isRarity = filters.rarity.includes('ALL')? true : filters.rarity.includes(card.infoCard.rareza);
            const isType = filters.type.includes('ALL')? true : filters.type.includes(card.infoCard.tipo);
            return isName && isFaction && isMintType && isRarity && isType;
    });

    return auxArrayCards;
}