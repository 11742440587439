import { Animated } from "react-animated-css";
import { Col} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import HoverVideoPlayer from 'react-hover-video-player';

//Components
import Card from "../Card/Card";
import classes from "./CapsuleView.module.css";
import ConfirmAlertView from "../Alerts/ConfirmAlertView";
import { SendSaveOpenCapsulesToServerAPICapsules, CreateMintToServerAPICapsules } from "../../../Services/Capsules/ServerAPICapsules";
import OpeningCapsuleAlertView from "../Alerts/OpeningCapsuleAlertView";
import ErrorAlertView from "../Alerts/ErrorAlertView";
import InfoAlertView from "../Alerts/InfoAlertView";


const CapsuleView = ({
    account,
    idCapsule,
    MysteryCapsule,
    handleToggleCheckersCapsules,
    handleIsChecked,
    handleOpenCapsuleFinishFunction,
    urlCapsuleServer
}) => {

    //const urlOpensea = urlCapsuleServer + idCapsule;
    const isChecked = handleIsChecked({idCapsule})

    const handleOnChange = () => {
        handleToggleCheckersCapsules({idCapsule, isChecked: !isChecked});
    }

    const cacheVideoPlayer = <HoverVideoPlayer
                                videoSrc="videos/CAPSULE_VP9.webm"
                                preload="auto"
                                loadingOverlay={
                                    <Spinner animation="border" role="status" variant="light">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                              />;

    return(
        <Col>
            <Animated animationIn="zoomIn" animationInDelay={300} animationOut="fadeOut" isVisible={true}>
                <Card className={classes.infoCapsules}>
                    
                    <Card className={handleIsChecked({idCapsule})?classes.capsule_mark:classes.capsule} onClick={()=>handleOnChange()}>
                        <div onClick={()=>handleOnChange()}>
                            {cacheVideoPlayer}
                        </div>
                    </Card>
                    <Card className={classes.infoCapsulesCard}>
                        
                            <h3 className="display-8 text-center">Mystery Capsule #{idCapsule}</h3>
                            <center>
                                <button type="button" className="btn btn-outline-light mt-2 w-75" 
                                    onClick={()=>ConfirmAlertView({
                                        account, 
                                        arrayCheckersCapsules: [idCapsule], 
                                        MysteryCapsule,
                                        handleOpenCapsuleAlertView: OpeningCapsuleAlertView, 
                                        handleSaveAPICapsules: SendSaveOpenCapsulesToServerAPICapsules, 
                                        handleCreateMintAPICapsules: CreateMintToServerAPICapsules, 
                                        handleInfoAlertView: InfoAlertView, 
                                        handleErrorAlertView: ErrorAlertView,
                                        handleFinishFunction: handleOpenCapsuleFinishFunction
                                    })}
                                >
                                    <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                                    <span className="m-2 fw-bolder">OPEN</span>
                                </button>
                                <br/>
                            </center>      
                                                    
                    </Card>
                    <br/>
                </Card>
            </Animated>
        </Col>
    );
}

export default CapsuleView;