export const EnumAttributes = {
    Edition: "Edition",
    MintType: "Mint type",
    Rarity: "Rarity",
    Type: "Type",
    Faction: "Faction",
    Used: "Used",
    Ilustrator: "Ilustrator",
    Animator: "Animator",
    Manufactured: "Manufactured",
}

export const nullCollectionCard = {
    name: '', 
    description: '', 
    external_url: '', 
    background_color: '', 
    image: '',
    animation_url: '',
    attributes: [
        {trait_type: 'Edition', value: ''},
        {trait_type: 'Mint type', value: ''},
        {trait_type: 'Rarity', value: ''},
        {trait_type: 'Type', value: ''},
        {trait_type: 'Faction', value: ''},
        {trait_type: 'Used', value: false},
        {trait_type: 'Ilustrator', value: ''},
        {trait_type: 'Animator', value: ''},
        {trait_type: '', value: 1655742591}
    ]
}

export const StandardTokenType = {
    index: -1,
    name: '', 
    description: '', 
    external_url: '',
    background_color: '', 
    image: '',
    animation_url: '',
    assetUrl: '',
    isVideo: false,
    isImage: true,
    edition: '',
    mintType: '',
    rarity: '',
    type: '',
    faction: '',
    used: '',
    ilustrator: '',
    manufactured: ''
}

export const CollectionServiceState = {
    collection: StandardTokenType,
    isLoading: true
}