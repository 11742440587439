import { useState } from "react";//Fragment, useEffect,
//import { TiDelete } from 'react-icons/ti';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import Select from 'react-select'
import { Animated } from "react-animated-css";
import { Image } from "react-bootstrap";

import classes from "./FilterCard.module.css";
import { FilterTypesForAutoComplete as FILTERS, EnumFilterType as FILTER_TYPE, FilterTypes as FILTER_OPTIONS} from "../../../Services/Filters/FilterTypes";

const FilterCard = ({
    handleUpdateCurrentFilterOwner = (currentFilterOwner = {name: ['ALL'], faction: ['ALL'], mintType: ['ALL'], rarity: ['ALL'], type: ['ALL']})=>{}
}) => {

    const [ toggleFilter, setToggleFilter ] = useState(false);
    const [ currentFilterItems, setCurrentFilterItems ] = useState({name: ['ALL'], faction: ['ALL'], mintType: ['ALL'], rarity: ['ALL'], type: ['ALL']});

    const handleToggleFilter = () => {
        setToggleFilter(!toggleFilter);
    }

    const addItemAndGetNewCurrentFilterOwner = (filterType = '', filterItem = '') => {
        let auxCurrentFilter = currentFilterItems;
        if(auxCurrentFilter[filterType].includes(filterItem)===false){
            auxCurrentFilter[filterType] = [filterItem];
            return {newCurrentFilterItems: auxCurrentFilter, error: false};
        }
        return {newCurrentFilterItems: currentFilterItems, error: true};
    }

    const handleOnSelect = (item = {id: -1, name: 'ALL'}, updateCurrentFilterOwner = handleUpdateCurrentFilterOwner)=>{
        console.group(`Filter card by: ${FILTER_TYPE.name}`);
        const {newCurrentFilterItems, error} = addItemAndGetNewCurrentFilterOwner(FILTER_TYPE.name, item.name);
        if(!error){
            setCurrentFilterItems(newCurrentFilterItems);
            updateCurrentFilterOwner(newCurrentFilterItems);
        }
        console.groupEnd();
    }

    const handleOnChangeSelectOptionInput = (filterItem='', filterType='', updateCurrentFilterOwner = handleUpdateCurrentFilterOwner) => {
        const {newCurrentFilterItems, error} = addItemAndGetNewCurrentFilterOwner(filterType, filterItem);
        if(!error){
            setCurrentFilterItems(newCurrentFilterItems);
            updateCurrentFilterOwner(newCurrentFilterItems);
        }
    }

    const handleClearAllFilters = () => {
        const clearCurrentFilterItems = {name: ['ALL'], faction: ['ALL'], mintType: ['ALL'], rarity: ['ALL'], type: ['ALL']};
        setCurrentFilterItems(clearCurrentFilterItems);
        handleUpdateCurrentFilterOwner(clearCurrentFilterItems);
    }

    const formatResultSearchAutocomplete = (item) => {
        return (
            <>
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const getOptionsByFilterType = (filterType) => {
        return FILTER_OPTIONS[filterType].map(type => ({value: type, label: type}))
    }

    const getCurrentFilterByType = (filterType) => {
        const currentFilter = currentFilterItems[filterType][0];
        return {value: currentFilter, label: currentFilter};
    }

    return (
        <div className = {classes.container_FilteredCardView}>
            <Animated animationIn="fadeInLeft" animationInDelay={150} animationOut="fadeOutLeft" isVisible={!toggleFilter} className="w-25">
                <Image fluid src="images/Dedo_Filters.png" className={classes.btn_toggle_OpenFilterCardView} onClick={handleToggleFilter}/>
            </Animated>
            <Animated animationIn="fadeInLeft" animationInDelay={150} animationOut="fadeOutLeft" isVisible={toggleFilter}>
                <div className={classes.box_filter_FilterCardView} >

                    <p className="text-white text-center pt-3 fw-bolder display-6"> Filters<br/>
                        <button type="button" className="btn btn-outline-light text-center mx-auto" onClick={handleClearAllFilters}>
                            <img src="images/clear.png" alt="Dark Earth LOGO" />
                            <span className="m-2">Clear filters</span>
                        </button>
                        <button type="button" className="btn btn-outline-light text-center mx-auto" onClick={handleToggleFilter}>
                            <img src="images/close.png" alt="Dark Earth LOGO" />
                            <span className="m-2">Close filters</span>
                        </button>
                    </p>

                    <div className={classes.container_SearchAutocomplete}>
                        <span className={classes.filter_titles}>Search by name</span>
                        <div className={classes.search_custom}>
                            <ReactSearchAutocomplete 
                                items={FILTERS.name}
                                onSelect={handleOnSelect}
                                onClear={handleOnSelect}
                                autoFocus
                                formatResult={formatResultSearchAutocomplete}
                                className={classes.search_custom}
                            />
                        </div>
                    </div>
                    {
                    }
                    <div className={classes.container_filters}>
                        <span className={classes.filter_titles}>Type</span>
                        <Select
                            isClearable = {!(getCurrentFilterByType(FILTER_TYPE.type).value === 'ALL')}
                            value = {getCurrentFilterByType(FILTER_TYPE.type)}
                            options = {getOptionsByFilterType(FILTER_TYPE.type)} 
                            onChange = {
                                (optionSelected = {value: 'ALL', label:'ALL'}) => {
                                    handleOnChangeSelectOptionInput(
                                        optionSelected? optionSelected.value : 'ALL',
                                        FILTER_TYPE.type
                                    )
                                }
                            }
                        />
                    </div>
                    
                    <div className={classes.container_filters}>
                        <span className={classes.filter_titles}>Faction</span>
                        <Select
                            isClearable = {!(getCurrentFilterByType(FILTER_TYPE.faction).value === 'ALL')}
                            value = {getCurrentFilterByType(FILTER_TYPE.faction)}
                            options = {getOptionsByFilterType(FILTER_TYPE.faction)} 
                            onChange = {
                                (optionSelected = {value: 'ALL', label:'ALL'}) => {
                                    handleOnChangeSelectOptionInput(
                                        optionSelected? optionSelected.value : 'ALL',
                                        FILTER_TYPE.faction
                                    )
                                }
                            }
                        />
                    </div>

                    <div className={classes.container_filters}>
                        <span className={classes.filter_titles}>Rarity</span>
                        <Select
                            isClearable = {!(getCurrentFilterByType(FILTER_TYPE.rarity).value === 'ALL')}
                            isDisabled = {FILTER_OPTIONS.specialType.includes(getCurrentFilterByType(FILTER_TYPE.mintType).value)}
                            value = {getCurrentFilterByType(FILTER_TYPE.rarity)}
                            options = {getOptionsByFilterType(FILTER_TYPE.rarity)} 
                            onChange = {
                                (optionSelected = {value: 'ALL', label:'ALL'}) => {
                                    handleOnChangeSelectOptionInput(
                                        optionSelected? optionSelected.value : 'ALL',
                                        FILTER_TYPE.rarity
                                    )
                                }
                            }
                        />
                    </div>

                    <div className={classes.container_filters}>
                        <span className={classes.filter_titles}>Mint type</span>
                        <Select
                            isClearable = {!(getCurrentFilterByType(FILTER_TYPE.mintType).value === 'ALL')}
                            value = {getCurrentFilterByType(FILTER_TYPE.mintType)}
                            options = {getOptionsByFilterType(FILTER_TYPE.mintType)} 
                            onChange = {
                                (optionSelected = {value: 'ALL', label:'ALL'}) => {
                                    handleOnChangeSelectOptionInput(
                                        optionSelected? optionSelected.value : 'ALL',
                                        FILTER_TYPE.mintType
                                    )
                                }
                            }
                        />
                    </div>

                </div>
            </Animated>
        </div>
    );

}

export default FilterCard;


    /* FUNCIONES PARA EL SEARCH AUTOCOMPLETE
    const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
        //console.log(string, results)
    }

    const handleOnHover = (result) => {
    // the item hovered
        //console.log(result)
    }

    const handleOnFocus = () => {
        //console.log('Focused')
    }
    */

    /**Elimina un nombre de los filtros */
    /*const removeItemAndGetNewCurrentItemFilterArray = (filterType = '', filterItem = '') => {
        let auxCurrentFilter = currentFilterItems;
        auxCurrentFilter[filterType] = auxCurrentFilter[filterType].filter(item => item !== filterItem);
        return auxCurrentFilter;
    }*/
    
    /*const handleRemoveFilter = (filterType = '', filterItem = '', updateCurrentFilterOwner = handleUpdateCurrentFilterOwner) => {
        const newCurrentFilterItems = removeItemAndGetNewCurrentItemFilterArray(filterType, filterItem);
        console.log(`handleRemoveFilter.newCurrentFilterItems: ${newCurrentFilterItems}`);
        setCurrentFilterItems(newCurrentFilterItems);
        updateCurrentFilterOwner(newCurrentFilterItems);
    }*/
    
    /*const filterView = (filterType="", filterItem="", removeFilter = handleRemoveFilter) => {
        //<TiDelete style={{color: 'white'}} onClick={deleteFactionView(faction)} />
        return (
            <div key={`${filterType}_${filterItem}_${Math.random()*1000000}`} style={{ display: 'block', textAlign: 'left'}} >
                <TiDelete style={{color: 'red'}} onClick = {()=>{removeFilter(filterType, filterItem)}}/><span style={{color: 'white' }}>{filterItem}</span>
            </div>          
        )
    }*/    

    /*<div className={classes.container_CurrentFilters}>
        currentFilterItems.name.map(name => filterView(FILTER_TYPE.name, name, handleRemoveFilter))
        currentFilterItems.faction.map(faction => filterView(FILTER_TYPE.faction, faction, handleRemoveFilter))
        currentFilterItems.mintType.map(mintType => filterView(FILTER_TYPE.mintType, mintType, handleRemoveFilter))
        currentFilterItems.rarity.map(rarity => filterView(FILTER_TYPE.rarity, rarity, handleRemoveFilter))
        currentFilterItems.type.map(type => filterView(FILTER_TYPE.type, type, handleRemoveFilter))
        currentFilterItems.specialType.map(specialType => filterView(FILTER_TYPE.specialType, specialType, handleRemoveFilter))
    </div>*/