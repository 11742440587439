import { useState, useEffect } from "react";

import Card from "../../../Elements/Card/Card";
import classes from "./InfoAccount.module.css";
//import CenterLogo from "../../../Elements/CenterLogo/CenterLogo";
import { Card as CardB, CardGroup, Container, Row, Col } from "react-bootstrap";

import { Animated } from "react-animated-css";
import BuyZone from "../../../pages/BuyZone/BuyZone";
import CenterLogo from "../../../Elements/CenterLogo/CenterLogo";
import SmartContractsView from "../../../Elements/SmartContractsView/SmartContractsView";
//import Referrals from "../../../Elements/Referrals/Referrals";

const InfoAccount = (props) => {

    const { contracts, web3 } = props.web3Api;
    const { isPublicSale, isWhitelisted, mintableChest, userMinted } = props.userMintDetails;
    const { MysteryCapsule } = contracts;

    const [ showInfo , setShowInfo ] = useState(true);

    // -------------------------
    // WALLET INFO
    // -------------------------
    // Formateo de balance en cuenta y wallet
    const balance = Number(props.currentBalance).toFixed(4);
    const account = props.currentAccount;

    const balanceUSDC = props.balanceUSDC;
    
    // -------------------------
    // ACCOUNT PRESALE INFO
    // -------------------------
    // Limite de capsulas por wallet
    const [ defaultMintAmount, setDefMintAmount ] = useState(0);

    // -------------------------
    // PRESALE INFO
    // -------------------------
    // Limite de capsulas
    const [ limitChess, setLimitChess ] = useState(0);

    // Precio por cada capsula
    const [ precioUSD, setPrecioUSD ] = useState(null); // Por defecto en el SC
    const [ precioMatic, setPrecioMatic ] = useState(null); // Oraculo

    // Get cantidad máxima para mintear por persona (por defecto)
    useEffect(() => {
        const loadDefaultMintAmount = async () => {
            const _defaultMintAmount = await MysteryCapsule.getDefaultMintAmount();
            setDefMintAmount(_defaultMintAmount.toString());
        }

        const loadMaxChest = async () => {
            const _limitChest = await MysteryCapsule.getLimitChest();
            setLimitChess(_limitChest.toString());
        }

        MysteryCapsule && loadDefaultMintAmount() && loadMaxChest()
    }, [MysteryCapsule, setDefMintAmount])

    // ---------------------
    // CALCULO DE PRECIOS
    // ---------------------
    // Get precio por cada capsula en USD (Por defecto)
    useEffect(() => {

        const loadPrices = async () => {
            const precioEnUSD = await MysteryCapsule.getDefaultPrice.call();
            setPrecioUSD(precioEnUSD.toString());

            // Cargar precio en MATIC
            const _precio = await MysteryCapsule.priceInMatic.call();
            const _precioWei = web3.utils.fromWei(_precio, "ether");

            // Redondeo de decimales
            const _precioRedondo = Number(_precioWei).toFixed(2);

            // Sumo 0.03 y redondeo de nuevo
            const _finalPrice = (Number(_precioRedondo)+Number(0.13)).toFixed(2);

            const _priceFixed = Number(_finalPrice).toFixed(2);

            setPrecioMatic(_priceFixed.toString());
        }

        MysteryCapsule && loadPrices()
    }, [MysteryCapsule, setPrecioMatic, setPrecioUSD, web3])

    const handleShowInfo = () => {
        const newBool = !showInfo;
        setShowInfo(newBool);
    }

    return (
        <Container fluid>
            { (!isPublicSale && !isWhitelisted) && <CenterLogo/>}

            { props.activado && (isWhitelisted || isPublicSale) &&

                <BuyZone
                    reloadUserDetails = { props.reloadUserDetails }
                    canConnectToContract = { props.canConnectToContract }
                    web3Api = { props.web3Api }
                    account = { account }
                    mintDetails = { props.userMintDetails }
                    isPublicSale = { isPublicSale }
                />

            }

            { isWhitelisted &&
                <div className="mx-auto text-center p-4">
                    <button type="button" className="btn btn-outline-light p-4 w-25" onClick={handleShowInfo}>
                        <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                        <span className="m-2 fw-bolder">{showInfo ? "HIDE ALL INFO" : "SHOW ALL INFO"}</span>
                    </button>
                </div>
            }

            <Container>
                <Row>
                { !isPublicSale &&
                
                    <Col>
                        <Animated className="pb-3" animationIn="zoomIn" animationOut="zoomOut" isVisible={(showInfo || !isWhitelisted)}>
                            <div style={{fontSize: "4vw"}}>
                                <h1 className="fw-bolder text-white">YOUR PRESALE</h1>
                            </div>
                            
                            <Card className={classes.infoPresale}>
                                <div style={{fontSize: "3vw"}}>
                                { isWhitelisted ?
                                        <h1 className="fw-bolder pb-2">WHITELISTED</h1>
                                    :
                                        <h1 className="fw-bolder pb-2 text-muted">NOT WHITELISTED</h1>
                                }
                                </div>
                                    <CardGroup>
                                        <CardB className={classes.infoBorder}>
                                            <CardB.Body>
                                                <CardB.Title>PRE-SALE LIMIT</CardB.Title>
                                                <CardB.Title className="fw-bold">
                                                    {defaultMintAmount} capsules
                                                </CardB.Title>
                                            </CardB.Body>
                                        </CardB>
                                        <CardB className={classes.infoBorder}>
                                            <CardB.Body>
                                                <CardB.Title>YOUR LIMIT</CardB.Title>
                                                <CardB.Title className="fw-bold">
                                                    {mintableChest} capsules
                                                </CardB.Title>
                                            </CardB.Body>
                                        </CardB>
                                        <CardB className={classes.infoBorder}>
                                            <CardB.Body>
                                                <CardB.Title>MINTED</CardB.Title>
                                                <CardB.Title className="fw-bold">
                                                    { !isWhitelisted ? 
                                                        "You cant mint"
                                                        :
                                                        userMinted + " capsules"
                                                    }
                                                </CardB.Title>
                                            </CardB.Body>
                                        </CardB>
                                    </CardGroup>
                                    <CardGroup className="mt-3 mx-auto">
                                        <CardB className={classes.infoBorder}>
                                            <CardB.Body>
                                                <CardB.Title>MATIC BALANCE</CardB.Title>
                                                <CardB.Title className="fw-bold">
                                                    {balance} MATIC
                                                </CardB.Title>
                                            </CardB.Body>
                                        </CardB>
                                        <CardB className={classes.infoBorder}>
                                            <CardB.Body>
                                                <CardB.Title>USDC BALANCE</CardB.Title>
                                                <CardB.Title className="fw-bold">
                                                    {balanceUSDC} USDC
                                                </CardB.Title>
                                            </CardB.Body>
                                        </CardB>
                                    </CardGroup>
                            </Card>
                        </Animated>
                    </Col>
                
                }
                { isPublicSale ?
                <Col>
                    <Animated className="pb-3" animationIn="zoomIn" animationOut="zoomOut" isVisible={(showInfo || !isWhitelisted)}>
                        <div style={{fontSize: "5vw"}}>
                            <h1 className="fw-bolder text-white pb-3 text-center">SALE INFO</h1>
                        </div>
                    
                        <Card className={classes.infoPresale}>
                            <div style={{fontSize: "3vw"}}>
                            { !isPublicSale ?
                                <h3 className="fw-bolder pb-2">PRE-SALE!</h3>
                            :
                                <h3 className="fw-bolder pb-2">PUBLIC SALE!</h3>
                            } 
                            </div>
                            <CardGroup>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>TOTAL CAPSULES</CardB.Title>
                                        <CardB.Text className="fw-bold">
                                            {limitChess} capsules
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                                { isPublicSale &&
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>YOUR MINT</CardB.Title>
                                        <CardB.Text className="fw-bold">
                                            {userMinted} capsules
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                                }
                            </CardGroup>
                            <CardGroup className="mt-3">
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>Your balance</CardB.Title>
                                        <CardB.Text className="fw-bold">
                                            {balance} MATIC / {balanceUSDC} USDC
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>Price (MATIC)</CardB.Title>
                                        <CardB.Text className="fw-bold">
                                            {precioMatic} MATIC
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>Price (USDC)</CardB.Title>
                                        <CardB.Text className="fw-bold">
                                            {precioUSD} USDC
                                        </CardB.Text>
                                    </CardB.Body>
                                </CardB>
                            </CardGroup>
                        </Card>
                    </Animated>
                </Col>
                :
                <Col>
                    <Animated className="pb-3" animationIn="zoomIn" animationOut="zoomOut" isVisible={(showInfo || !isWhitelisted)}>
                        <div style={{fontSize: "5vw"}}>
                            <h1 className="fw-bolder text-white">SALE INFO</h1>
                        </div>
                    
                        <Card className={classes.infoPresale}>
                            <div style={{fontSize: "3vw"}}>
                            { !isPublicSale ?
                                <h3 className="display-6 fw-bolder pb-2">PRE-SALE!</h3>
                            :
                                <h3 className="display-6 fw-bolder pb-2">PUBLIC SALE!</h3>
                            } 
                            </div>
                            <CardGroup>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>TOTAL CAPSULES</CardB.Title>
                                        <CardB.Title className="fw-bold">
                                            {limitChess} capsules
                                        </CardB.Title>
                                    </CardB.Body>
                                </CardB>
                            </CardGroup>
                            <CardGroup className="mt-3">
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>Price (MATIC)</CardB.Title>
                                        <CardB.Title className="fw-bold">
                                            {precioMatic} MATIC
                                        </CardB.Title>
                                    </CardB.Body>
                                </CardB>
                                <CardB className={classes.infoBorder}>
                                    <CardB.Body>
                                        <CardB.Title>Price (USDC)</CardB.Title>
                                        <CardB.Title className="fw-bold">
                                            {precioUSD} USDC
                                        </CardB.Title>
                                    </CardB.Body>
                                </CardB>
                            </CardGroup>
                        </Card>
                    </Animated>
                </Col>
                }
            </Row>
            </Container>
            
            <SmartContractsView/>
        </Container>
    );

}
export default InfoAccount;