import { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Animated } from "react-animated-css";
//Services
//import { NFTCards } from "../../../Services/DataCards/CollectionsCards";
//Components
import LoaderCardsView from "../../Elements/Card/LoaderCardsView";
import Paginator from "../../Elements/Paginator/Paginator";
import classes from "./MyNFT.module.css";
import ConstantesAPI from "../../../../utils/API/Constantes";
import CardView from "../../Elements/Card/CardView";
import FilterCard from "../../Elements/Filters/FilterCardView";
import { FilterMyNFTs as FILTER_CARDS } from "../../../Services/Filters/FilterMyNFTs";
import DetailCardView from "../../Elements/Card/DetailCardView";

import { actualLoader } from "../../../Services/Loader/LoaderFunctions";
//import useLoadWalletsDataFromDB from "../../../../utils/FastLoading/useLoadWalletsDataFromDB";
//import { useCollectionCards } from "../../../Services/DataCards/useCollectionCards";

const MyNFT = (props) => {

    const { DECollection } = props.contracts;
    const { account } = props.infoAccount;
    const [ myNFTs, setMyNFTs ] = useState([]);
    const [ myViewNFTs, setMyViewNFTs ] = useState([]);
    const [ currentViewNFTsFiltered, setCurrentViewNFTsFiltered ] = useState([]);
    const [ isLoadCards, setIsLoadCards ] = useState(false)
    const [ toggleViewDetail, setToggleViewDetail ] = useState(false);
    const [ currentInfoCardView, setCurrentInfoCardView ] = useState({});

    const useLoad = props.useLoad;
    const { userTokensIds, userTokensTypes, tokenSync, userTokensIsUsed } = useLoad;
    //const { collection, isLoading } = useCollectionCards();   
    const collection = props.collection;
    const isLoading = props.isLoading; 

    // Cargar IDs de los NFTs
    useEffect(() => {

        const getAllViewCards = ({ clearInfoCards = [], arrayTokenID = [], arrayTokenType = [], defaultImage, defaultVideo, urlCardServer = "" }) => {
            let auxAllViewCards = [];
        
            for (let index = 0; index < clearInfoCards.length; index++) {
                
                const clearInfoCard = clearInfoCards[index];
        
                const isFinded = auxAllViewCards.find(viewCard => viewCard.infoCard.tokenType === arrayTokenType[index])
                
                if(isFinded){
        
                    isFinded.infoCard.tokensID.push(arrayTokenID[index]);
                    isFinded.infoCard.tokensIDused.push(userTokensIsUsed[index]);
        
                }else{
        
                    let auxInfoCard;
                    if(arrayTokenID.length + arrayTokenType.length === 0){
                        auxInfoCard = {tokensID: [], tokenType: -1,...clearInfoCard };
                    }else{
                        auxInfoCard = {tokensID: [arrayTokenID[index]], tokensIDused: [userTokensIsUsed[index]], tokenType: arrayTokenType[index],...clearInfoCard };
                    }
        
                    auxAllViewCards.push(
                        {
                            infoCard: auxInfoCard,
                            defaultImage: defaultImage,//ConstantesAPI.DEFAULT_CARD_IMAGE, 
                            defaultVideo: defaultVideo,//'videos/DH_MKT_34_1x1_alpha5_VP9.webm'
                            urlCardServer: urlCardServer//ConstantesAPI.RUTA_CARDS_MEDIA
                        });
                }        
            }
        
            return auxAllViewCards;
        }

        const NFTCards = ({ defaultImage, defaultVideo }) => {

            const clearInfoCards = [];

            userTokensTypes.forEach(element => {
                const encontrado = collection.filter(o1 => o1.index === element);
                if(encontrado) clearInfoCards.push(encontrado[0]);
            });

            const arrayCardViewJSON = getAllViewCards({ clearInfoCards, arrayTokenID: userTokensIds, arrayTokenType: userTokensTypes, defaultImage, defaultVideo });
        
            return [clearInfoCards, arrayCardViewJSON]
        }


        const loadCards = () => {
            const rutaDefaultCard = actualLoader;

            const [auxMyNFTs, auxMyViewNFTs] = NFTCards({
                defaultImage: ConstantesAPI().DEFAULT_CARD_IMAGE,
                defaultVideo: rutaDefaultCard
            });

            setMyNFTs(auxMyNFTs);
            setMyViewNFTs(auxMyViewNFTs);
            setCurrentViewNFTsFiltered(auxMyViewNFTs);
            setIsLoadCards(true);
        }
        
        account && DECollection && tokenSync && !isLoading && loadCards();
    }, [account, DECollection, userTokensIds, userTokensTypes, tokenSync, collection, isLoading, userTokensIsUsed])

    const onClickCardView = async (infoCard) => {
        
        if(toggleViewDetail === false) {
            let arrayTokenSerial = [];
            for (const tokenID of infoCard.tokensID) {                
                const tokenSerial =  await DECollection.getTokenSerial.call(tokenID);
                arrayTokenSerial.push(tokenSerial);
            }
            setCurrentInfoCardView({tokenSerials: arrayTokenSerial,...infoCard});
        } else {
            setCurrentInfoCardView(infoCard);
        }
        
        setToggleViewDetail(!toggleViewDetail);
    }

    const updateCurrentFilterViewNFTs = (currentFilterItems = {name: [], faction: [], mintType: [], rarity: [], type: []}) => {
        setCurrentViewNFTsFiltered(FILTER_CARDS(myViewNFTs, currentFilterItems));
    }
    
    return(    
        <Fragment>
            {toggleViewDetail && DetailCardView({infoCard: currentInfoCardView, handleToggleDetailCardView: onClickCardView})}
            <div className={classes.centrador}>
                <Animated animationIn="zoomInDown" animationOut="fadeOut" isVisible={true}>
                    <h1 className="display-1 fw-bolder text-white">My NFTs</h1>
                </Animated>
                { !isLoadCards? 
                    <LoaderCardsView loadingText="Loading your NFTs"/>
                :
                    myNFTs.length !== 0 ?
                        <Fragment>

                            <Animated animationIn="zoomInDown" animationOut="fadeOut" animationInDelay={300} isVisible={true}>
                                <p className="text-white fw-bolder">({myNFTs.length} NFTS CARDS AVAILABLES)</p>
                            </Animated>

                            <FilterCard handleUpdateCurrentFilterOwner={updateCurrentFilterViewNFTs} />
                            
                            <Container fluid>
                                <Paginator arrayViewItems={currentViewNFTsFiltered} countItemsPerPage={8} handleElementView={CardView} handleElementViewOnClick={onClickCardView} />
                            </Container>
                        </Fragment>
                    :
                        <LoaderCardsView loadingText="You don't have Dark Earth NFTs"/>
                }
            </div>
            
        </Fragment>
    );

}

export default MyNFT;