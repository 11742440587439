import MyNFT from "../../components/UI/pages/MyNFT/MyNFT";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import NoDisponible from "../NoDisponible/NoDisponible";

const MyNFTs = (props) => {

    const { isConnected } = props.infoAccount;

    const activado = props.activo;

    return (
        <main>
            { !isConnected ?
                    <NoConectado/>

                    :
                    
                    activado ?
                        <MyNFT
                            contracts = { props.contracts }
                            infoAccount = { props.infoAccount }
                            collection = { props.collection }
                            isLoading = { props.isLoading }
                            useLoad = { props.useLoad }
                        />
                    :
                        <NoDisponible/>
                }
        </main>
    );

}



export default MyNFTs;