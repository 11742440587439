import classes from "./Footer.module.css";

import { Navbar, Container, Nav, Row, Col } from "react-bootstrap";

import useDetectMobile  from "../../../utils/Hooks/useDetectMobile";
const Footer = () => {

    const version = "Version: " + process.env.REACT_APP_GIT_SHA;
    const { isMobile } = useDetectMobile();

    return (
        <Navbar className={`${classes.fooBackground}`} collapseOnSelect scrolling="true" dark="true" variant="dark">
        <Container fluid className="m-3">
            <Row className="w-100">
                <Navbar.Collapse className="text-center" id="responsive-navbar-nav2">
                    <Col>
                        <Row className="d-flex justify-content-start text-warning">
                            <Nav.Link className="d-flex justify-content-start text-warning" href="https://github.com/solidproof/projects/tree/main/DarkEarth">Audited by SOLIDProof</Nav.Link>
                        </Row>
                    </Col>
                    { !isMobile && 
                    <Col sm={6}>
                        <Row>
                            <Nav className="justify-content-center" navbarScroll>
                                <Nav.Link href="https://www.darkearth.gg" target="_blank">Website</Nav.Link>
                                <Nav.Link href="https://presale.darkearth.gg" target="_blank">Presale</Nav.Link>
                                <Nav.Link href="https://litepaper.darkearth.gg" target="_blank">Litepaper</Nav.Link>
                            </Nav>
                        </Row>
                        <Row>
                            <Nav className="justify-content-center small" navbarScroll>
                                <Nav.Link href="https://darkearth.gg/en/privacy-policy/" target="_blank">Privacy Policy</Nav.Link>
                                <Nav.Link href="https://darkearth.gg/en/extra-information-about-cookies-policy/" target="_blank">Cookies Policy</Nav.Link>
                                <Nav.Link href="https://darkearth.gg/en/terms-and-conditions/" target="_blank">Terms&Conditions</Nav.Link>
                            </Nav>
                        </Row>
                    </Col>
                    }
                    <Col>
                        <Row className="d-flex justify-content-end">
                            <Nav className="d-flex justify-content-end">
                                <Navbar.Brand href="https://twitter.com/DarkEarthgame" target="_blank">
                                    <img
                                        src="images/rrss/twitter.png"
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-right"
                                        alt="Twitter"
                                    />
                                </Navbar.Brand>
                                <Navbar.Brand href="https://discord.darkearth.gg" target="_blank">
                                    <img
                                        src="images/rrss/discord.png"
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-right"
                                        alt="Discord"
                                    />
                                </Navbar.Brand>
                                <Navbar.Brand href="https://t.me/darkearthgame" target="_blank">
                                    <img
                                        src="images/rrss/telegram.png"
                                        width="30"
                                        height="30"
                                        className="d-inline-block align-right"
                                        alt="Telegram"
                                    />
                                </Navbar.Brand>
                            </Nav>
                        </Row>
                        <Row>
                            <small className="text-muted d-flex justify-content-end mt-2">{version}</small>
                        </Row>
                    </Col>
                </Navbar.Collapse>
            </Row>
        </Container>
        </Navbar>
    );
}

export default Footer;