import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Card as CardB, CardGroup, Col, Container, Row } from "react-bootstrap";
import { Animated } from "react-animated-css";
import { FcAbout } from "react-icons/fc";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";


import ConstantesAPI from "../../../../utils/API/Constantes";
import { getRewardFromDB, getFreeMintsFromDB, createMintReward, updateUsedWalletsData } from "../../../../utils/API/ApiFunctions";
import { consensusAddUnverified, consensusSetVerified } from "../../../../utils/Consensus/Consensus";
//import { getUserNotUsedTokens, getUserNotUsedTokensTypes, loadWalletsDataFromDB } from "../../../../utils/FastLoading/FastLoading";
import classes from "./Reward.module.css";
import Card from "../../Elements/Card/Card";
//import useLoadWalletsDataFromDB from "../../../../utils/FastLoading/useLoadWalletsDataFromDB";


const Reward = (props) => {

    const { account } = props.infoAccount;
    const { MysteryCapsule, DECollection } = props.contracts;
    const collection = props.collection;

    const [ cardsNotUsed, setCardsNotUsed ] = useState([]);
    const [ cardsTypes, setCardsTypes ] = useState([]);

    // Free mints
    const [ freeCapsules, setFreeCapsules ] = useState(0);
    const [ cardsNames, setCardsNames ] = useState([]);
    const [ countFreeCardsMint, setCountFreeCardsMint ] = useState(0);

    const [ needReload, setNeedReload ] = useState(false);
    const [ isLoad, setIsLoad ] = useState(false);
    const [ needFreeReload, setNeedFreeReload ] = useState(true);
    const [ isClaimingReward, setIsClaimingReward ] = useState(false);

    const [ factionSelected, setFactionSelected ] = useState(null);
    // 5 - Terran
    // 6 - Askari
    // 7 - Bioshara
    // 8 - Gladio
    // 9 - Musktar

    const useLoad = props.useLoad;
    const { tokenSync, userNotUsedTokens, userNotUsedTokenIdGenerales, haveDesync } = useLoad;

    const rewardDisabled = false;

    const navigate = useNavigate();

    var cardsSelect = [];
    var cardsSelectTypes = [];

    useEffect(() => {

        const loadCacheCards = async () => {
            const tokensIds = userNotUsedTokens;
            const tokenTypes = userNotUsedTokenIdGenerales;
            setCardsNotUsed(tokensIds);
            setCardsTypes(tokenTypes);
            setIsLoad(true);
        }

        !isLoad && !needReload && tokenSync && loadCacheCards()
    }, [account, DECollection, isLoad, needReload, tokenSync, userNotUsedTokens, userNotUsedTokenIdGenerales])

    useEffect(() => {

        const reloadRewards = async () => {
            setIsLoad(false);
            setCardsNotUsed([]);
            setCardsTypes([]);
            haveDesync(true);
            setNeedReload(false);
        }

        needReload && reloadRewards();
    }, [account, DECollection, needReload, haveDesync])

    useEffect(() => {
        
        const loadFreeMints = async () => {
            const freeMintsCards = await getFreeMintsFromDB(account);
            setCountFreeCardsMint(freeMintsCards.length);
            setCardsNames(freeMintsCards);
            // -----------
            const freeMintsCapsules = await MysteryCapsule.getWalletFreeMints.call(account);
            setFreeCapsules(freeMintsCapsules.words[0]);
            // ------------
            setNeedFreeReload(false);
        }

        account && MysteryCapsule && needFreeReload && loadFreeMints()

        return () => {
            setNeedFreeReload(false);
        };

        
    }, [account, MysteryCapsule, needFreeReload])

    const buscaCarta = (typeCard) => {
        var respuesta = false;
        var contador = 0;

        while(!respuesta && contador < cardsTypes.length) {
            if(typeCard === cardsTypes[contador]) {
                cardsSelect.push(cardsNotUsed[contador]);
                cardsSelectTypes.push(cardsTypes[contador]);
                respuesta = true;
            } 
            contador += 1;
        }

        return respuesta;
    }

    const allItsTrue = (array) => {
        var contador = 0;

        array.forEach(element => {
            if(element !== true) contador += 1;
        });

        return contador;
    }

    const checkReward = (arrayCards, onlyOne = false) => {

        if(!onlyOne) {
            var checkingCards = [];
            for(var i = 0; i < arrayCards.length; i++) {
                checkingCards[i] = false;
            }

            // Comprobar si tiene todas las cartas
            for(var j = 0; j < arrayCards.length; j++) {
                if(buscaCarta(arrayCards[j])) checkingCards[j] = true;
            }
        
            var cartasFaltantes = allItsTrue(checkingCards);

            return cartasFaltantes;
        } else {
            for(var h = 0; h < arrayCards.length; h++) {
                if(buscaCarta(arrayCards[h])) return 0;
            }
            return 1;
        }

    }

    const handleChangeFaction = (event) => {
        const faction = event.target.value;
        setFactionSelected(faction);
    };

    const handleReward = async (id, silencioso = false) => {

        cardsSelect = [];
        cardsSelectTypes = [];

        if(id === "null" || id === null) {
            errorAlert("Choose the faction for which you want to claim the prize.");
            return;
        }

        // Obtener reward de BD (solo idCards que necesito)
        const idCardNeeds = await getRewardFromDB(id);
        const idCardArray = JSON.parse(idCardNeeds);

        var cartasFaltantes = 0;

        if(idCardArray.length > 0) {
            cartasFaltantes = checkReward(idCardArray);
    
            if(cartasFaltantes === 0) {
                markCards(id);
            } else {
                let tiposFaltantes = idCardArray.filter(x => !cardsSelectTypes.includes(x));

                const infoCards = [];

                tiposFaltantes.forEach(element => {
                    const encontrado = collection.filter(o1 => o1.index === element);
                    if(encontrado) infoCards.push(encontrado[0]);
                });

                const nameCards = infoCards.flatMap((tokenInfo)=> tokenInfo.name);

                errorAlert("Faltan cartas", cartasFaltantes, nameCards);

            }

        } else {
            errorAlert("Error loading reward");
        }
    }
    /*
    const handleMultiReward = async (ids) => {
        var tengoReward = false;
        var index = 0;

        while(!tengoReward && index < ids.length) {
            const element = ids[index];
            const res = await handleReward(element, true);
            if(res) {
                var faccion;
                switch (element) {
                    case 5:
                        faccion = "Terrans";
                        break;

                    case 6:
                        faccion = "Askari";
                        break;

                    case 7:
                        faccion = "Bioshara";
                        break;

                    case 8:
                        faccion = "Gladios";
                        break;

                    case 9:
                        faccion = "Musktars";
                        break;
                
                    default:
                        break;
                }

                tengoReward = true;
                markCards(element);
                infoCardsAlert(1, faccion);
            }
            index += 1;
        }
        if(!tengoReward) errorAlert("You do not have all the cards of a faction.");
    }
    */

    const markCards = async (rewardId) => {
        var peticionOk = false;
        
        try {
            await DECollection.bulkSetUsedCard.call(cardsSelect, {
                from: account
            }).then((r) => {
                peticionOk = true;
            }).catch((e) => {
                errorAlert(e.message);
            })
        } catch(error){
            errorAlert(error.message);
        }

        if(peticionOk) {
            infoAlert();
            const consensusId = await consensusAddUnverified(account, "Reward", JSON.stringify(cardsSelect));
            if(!consensusId) {
                errorAlert("You already have a transaction in process.<br/> Wait for the blockchain to finish and refresh the page.");
                return;
            }
            setIsClaimingReward(true);
            await DECollection.bulkSetUsedCard(cardsSelect, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then(async (result) => {
                await consensusSetVerified(account, consensusId, result.tx);
                await updateUsedWalletsData(account, cardsSelect);
                // Mensaje todo correcto
                successAlert(result.tx);
                // Guardo TX en BD
                saveMarkCardsTx(account, result.tx, cardsSelect.toString());
                // Genero el minteo de los rewards
                createMintReward(account, result.tx, rewardId);
                // Recargo todos los datos del usuario
                await props.reloadUserDetails(props.web3, props.contracts, account, false);
                // Recargamos las cartas no usadas
                setNeedReload(true);
                // Redirigimos
                navigate("/pre-mint");
            }).catch((e) => {
                errorAlert(e.message);
            }).finally(() => {
                // Desbloqueamos
                setIsClaimingReward(false);
            });
        }
    }

    const handleFreeCaps = async () => {
        var peticionOk = false;

        infoCardsAlert(3);
                
        try {
            await MysteryCapsule.bulkTakeFreeMint.call({
                from: account
            }).then((r) => {
                peticionOk = true;
            }).catch((e) => {
                errorAlert(e.message);
            })
        } catch(error){
            errorAlert(error.message);
        }

        if(peticionOk) {
            infoAlert();
            await MysteryCapsule.bulkTakeFreeMint({
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((result) => {
                // Mensaje todo correcto
                successAlert(result.tx);
                // Recargamos cartas y cuenta
                setNeedFreeReload(true);
                props.reloadUserDetails(props.web3, props.contracts, account, false);
                // Redirigimos
                navigate("/my-capsules");
            }).catch((e) => {
                errorAlert(e.message);
            });
        }
    }

    const handleFreeCards = async () => {

        infoCardsAlert(0);
        
        try {
            await obtenerFreeCardsMint(account);
            successFreeMintAlert();
            setNeedFreeReload(true);
            navigate("/pre-mint");
        } catch(error){
            errorAlert(error.message);
        }

    }

    const infoCardsAlert = (detalles, faction) => {
        var msg = "";
        if(detalles === 0) {
            msg = "You go to pre-mint your free cards.</b><br/><br/>Accept the transaction in your wallet to continue.";
        } else if(detalles === 1) {
            msg = "You go to mint your <b>" + faction + " faction banner</b> <br/><br/>Accept the transaction in your wallet to continue.";
        } else if(detalles === 2) {
            msg = "You go to mint <b>" + freeCapsules + " free capsules.</b><br/><br/>Accept the transaction in your wallet to continue.";
        }

        Swal.fire({
            title: "Request in process",
            html: msg,
            icon: "info",
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    const successFreeMintAlert = () => {
        Swal.fire({
            title: "Transaction completed!",
            html:
                'The transaction has been confirmed. <br/><br/>'+
                '<div class="alert alert-primary" role="alert">Status<br/><br/><b>PRE-MINTED</b></div><br/><br/>',
            icon: "success",
            cancelButtonText: "Close",
            showCloseButton: true,
            showCancelButton: true,
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showConfirmButton: false
        });
    }

    const infoAlert = () => {
        Swal.fire({
            title: "Request in process",
            html: "Accept the transaction in your wallet to continue. <br/><br/>"+
                    "<span class='fw-bold'>Please dont refresh or close this window.</span><br/>"+
                    "Wait for the transaction.",
            icon: "info",
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    const successAlert = (txId) => {
        Swal.fire({
            title: "Transaction completed!",
            html:
                'The transaction has been confirmed. <br/>'+
                '<div class="alert alert-primary" role="alert">Transaction Hash<br/><br/><b>' + txId + '</b></div><br/><br/>' + 
                '<a class="btn btn-primary" target="_blank" href="'+ ConstantesAPI().NETWORK_SCANTX + txId + '">View on Block Explorer</a>',
            icon: "success",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Close",
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showConfirmButton: false
        });
    }

    const errorAlert = (error, numFaltantes, nameFaltantes) => {
        var mensaje = null;
        console.log(nameFaltantes);
        if(error.includes("Faltan cartas")){
            mensaje = "<b>You are missing " + numFaltantes + " NFTs to get this reward.</b><br/><br/> <div class='alert alert-primary' role='alert'><b>Name of missing cards:</b><br/><br/>" + nameFaltantes.toString() + "</div><br/>Come back when you have them all.";
        } else {
            mensaje = error;
        }
        Swal.fire({
            title: "Claim reward failed!",
            html: mensaje,
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Close',
            width: "50rem",
            background: "#131312",
            color: "#FFF",
        });
    }

    return(
        <Container fluid>
            <Row>
            <Animated animationIn="zoomInDown" animationOut="fadeOut" isVisible={true}>
                <h1 className="display-1 fw-bolder text-center text-white">REWARDS</h1>
                {rewardDisabled && <h3 className="display-6 fw-bold text-white text-center">VERY SOON!</h3>}
            </Animated>
            </Row>
            
            { ( (countFreeCardsMint !== 0) || (freeCapsules !== 0) ) && 
            <Row>
                { (freeCapsules !== 0) && 
                <Col>
                    <Animated className="w-50 h-100 mx-auto text-center" animationIn="zoomInDown" animationOut="fadeOut" animationInDelay={500} isVisible={freeCapsules !== 0}>
                        <Card className="m-3 mx-auto">
                            <h3 className="text-white text-center p-4">FREE MYSTERY CAPSULES</h3>
                            <p className="text-white text-center p-4 fw-bolder">{freeCapsules} FREE CAPSULES UNCLAIMED!</p>
                            <div className="mx-auto text-center">
                                <button type="button" className="btn btn-outline-light mb-4 p-2 w-50 mx-auto text-center" onClick={handleFreeCaps} disabled={isClaimingReward}>
                                    <img src="images/iconDark.png" alt="DarkEarth" />
                                    <span className="m-2 fw-bolder">CLAIM NOW</span>
                                </button>
                            </div>
                        </Card>
                    </Animated>
                </Col>
                }

                { (countFreeCardsMint !== 0) &&
                <Col>
                    <Animated className="w-50 mx-auto text-center" animationIn="zoomInDown" animationOut="fadeOut" animationInDelay={1000} isVisible={countFreeCardsMint !== 0}>
                        <Card className="m-3">
                            <h3 className="text-white text-center p-4">{countFreeCardsMint} FREE NFT UNCLAIMED!</h3>
                            <div className="row mx-auto p-3">
                                {cardsNames.map((element) => 
                                    <div key={`${Math.random()*1000000}`} className="col-md text-white p-3 text-center fw-bold">{element}</div>
                                )}
                            </div>
                            <div className="mx-auto text-center">
                                <button type="button" className="btn btn-outline-light mb-4 p-2 w-50 mx-auto text-center" onClick={handleFreeCards} disabled={isClaimingReward}>
                                    <img src="images/iconDark.png" alt="DarkEarth" />
                                    <span className="m-2 fw-bolder">CLAIM NOW</span>
                                </button>
                            </div>
                        </Card>
                    </Animated>
                </Col>
                }
            </Row>
            }
            
            <Row xs={1} xl={2}>
                <Col>
                    <Card className={classes.buyzone}>
                        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                        <Card className={classes.nft}>
                            <div className={classes.efecto}>
                                <img alt="Reward" className="img-fluid" src="/images/rewards/unit.jpg"/>
                            </div>
                            <CardGroup className="mt-5">
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">NEEDs</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU NEED</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                ALL UNIT CARDS
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">REWARD</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU RECEIVE</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                UNIT MASTER ART
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">CLAIM</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">CLAIM THIS REWARD</CardB.Subtitle>
                                            {(!isLoad || isClaimingReward || rewardDisabled) ?
                                                <button
                                                    disabled={true}
                                                    type="button"
                                                    className="form-control btn btn-outline-light  text-center text-muted mx-auto">
                                                    <b>LOADING...</b>
                                                </button>
                                            :
                                                <button
                                                    onClick={(e) => handleReward(1)}
                                                    type="button"
                                                    disabled={!isLoad || isClaimingReward || rewardDisabled}
                                                    className="form-control btn btn-outline-light  text-center mx-auto">
                                                    <b>CLAIM NOW</b>
                                                </button>
                                            }
                                        </CardB.Body>
                                    </CardB>
                                </CardGroup>
                        </Card>
                        </Animated>
                    </Card>
                </Col>

                <Col>
                    <Card className={classes.buyzone}>
                        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                        <Card className={classes.nft}>
                            <div className={classes.efecto}>
                                <img alt="Reward" className="img-fluid" src="/images/rewards/support.jpg"/>
                            </div>
                            <CardGroup className="mt-5">
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">NEEDs</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU NEED</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                ALL SUPPORT CARDS
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">REWARD</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU RECEIVE</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                SUPPORT MASTER ART
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">CLAIM</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">CLAIM THIS REWARD</CardB.Subtitle>
                                            {(!isLoad || isClaimingReward || rewardDisabled) ?
                                            <button
                                                disabled={true}
                                                type="button"
                                                className="form-control btn btn-outline-light  text-center text-muted mx-auto">
                                                <b>LOADING...</b>
                                            </button>
                                            :
                                            <button
                                                onClick={(e) => handleReward(2)}
                                                type="button"
                                                disabled={!isLoad || isClaimingReward || rewardDisabled}
                                                className="form-control btn btn-outline-light  text-center mx-auto">
                                                <b>CLAIM NOW</b>
                                            </button>
                                            }
                                        </CardB.Body>
                                    </CardB>
                                </CardGroup>
                        </Card>
                        </Animated>
                    </Card>
                </Col>
            </Row>
            <Row xs={1} xl={2}>
                <Col>
                    <Card className={classes.buyzone}>
                        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                        <Card className={classes.nft}>
                            <div className={classes.efecto}>
                                <img alt="Reward" className="img-fluid" src="/images/rewards/equipment.jpg"/>
                            </div>
                            <CardGroup className="mt-5">
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">NEEDs</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU NEED</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                ALL EQUIPMENT CARDS
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">REWARD</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU RECEIVE</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                EQUIPMENT MASTER ART
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">CLAIM</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">CLAIM THIS REWARD</CardB.Subtitle>
                                            {(!isLoad || isClaimingReward || rewardDisabled) ?
                                            <button
                                                disabled={true}
                                                type="button"
                                                className="form-control btn btn-outline-light  text-center text-muted mx-auto">
                                                <b>LOADING...</b>
                                            </button>
                                            :
                                            <button
                                                onClick={(e) => handleReward(3)}
                                                type="button"
                                                disabled={!isLoad || isClaimingReward || rewardDisabled}
                                                className="form-control btn btn-outline-light  text-center mx-auto">
                                                <b>CLAIM NOW</b>
                                            </button>
                                            }
                                            
                                        </CardB.Body>
                                    </CardB>
                                </CardGroup>
                        </Card>
                        </Animated>
                    </Card>
                </Col>

                <Col>
                    <Card className={classes.buyzone}>
                        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                        <Card className={classes.nft}>
                            <div className={classes.efecto}>
                                <img alt="Reward"  className="img-fluid" src="/images/rewards/tactics.jpg"/>
                            </div>
                            <CardGroup className="mt-5">
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">NEEDs</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU NEED</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                ALL TACTIC CARDS
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">REWARD</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU RECEIVE</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                TACTIC MASTER ART
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">CLAIM</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">CLAIM THIS REWARD</CardB.Subtitle>
                                            {(!isLoad || isClaimingReward || rewardDisabled) ?
                                            <button
                                                disabled={true}
                                                type="button"
                                                className="form-control btn btn-outline-light  text-center text-muted mx-auto">
                                                <b>LOADING...</b>
                                            </button>
                                            :
                                            <button
                                                onClick={(e) => handleReward(4)}
                                                type="button"
                                                disabled={!isLoad || isClaimingReward || rewardDisabled}
                                                className="form-control btn btn-outline-light  text-center mx-auto">
                                                <b>CLAIM NOW</b>
                                            </button>
                                            }
                                        </CardB.Body>
                                    </CardB>
                                </CardGroup>
                        </Card>
                        </Animated>
                    </Card>
                </Col>
            </Row>

            <Row xs={1} xl={2}>
                <Col>
                    <Card className={classes.buyzone}>
                        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                        <Card className={classes.nft}>
                            <div className={classes.efecto}>
                                <img alt="Reward" className="img-fluid" src="/images/rewards/allfaction.jpg"/>
                            </div>
                            <CardGroup className="mt-5">
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">NEEDs</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU NEED</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                ALL SAME FACTION
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">REWARD</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU RECEIVE</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                <select onChange={handleChangeFaction} className="form-control btn btn-light  text-center mx-auto">
                                                    <option value="null">CHOOSE</option>
                                                    <option value="5">TERRAN FACTION BANNER</option>
                                                    <option value="6">ASKARI FACTION BANNER</option>
                                                    <option value="7">BIOSHARA FACTION BANNER</option>
                                                    <option value="8">GLADIOS FACTION BANNER</option>
                                                    <option value="9">MUSKTAR FACTION BANNER</option>
                                                </select>
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">CLAIM</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">CLAIM THIS REWARD</CardB.Subtitle>
                                            {(!isLoad || isClaimingReward || rewardDisabled) ?
                                            <button
                                                disabled={true}
                                                type="button"
                                                className="form-control btn btn-outline-light  text-center text-muted mx-auto">
                                                <b>LOADING...</b>
                                            </button>
                                            :
                                            <button
                                                onClick={(e) => handleReward(factionSelected)}
                                                type="button"
                                                disabled={!isLoad || isClaimingReward || rewardDisabled}
                                                className="form-control btn btn-outline-light  text-center mx-auto">
                                                <b>CLAIM NOW</b>
                                            </button>
                                            }
                                        </CardB.Body>
                                    </CardB>
                                </CardGroup>
                        </Card>
                        </Animated>
                    </Card>
                </Col>
                <Col>
                    <Card className={classes.buyzone}>
                        <Animated animationIn="zoomIn" animationOut="fadeOut" isVisible={true}>
                        <Card className={classes.nft}>
                            <div className={classes.efecto}>
                                <img alt="Reward" className="img-fluid" src="/images/rewards/keyart.png"/>
                            </div>
                            <CardGroup className="mt-5">
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">NEEDs</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU NEED</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                                ALL MASTER ART
                                            </CardB.Text>
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">REWARD</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">WHAT YOU RECEIVE</CardB.Subtitle>
                                            <CardB.Text className="fw-bold text-center">
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2"><b>You can stake this NFT to receive 10% fees generated in Marketplace</b></Tooltip>}>
                                                <span>KEYART <FcAbout size={18}/></span>
                                             </OverlayTrigger>
                                            </CardB.Text>
                                            
                                        </CardB.Body>
                                    </CardB>
                                    <CardB className={classes.infoBorder}>
                                        <CardB.Body>
                                            <CardB.Title className="fw-bold">CLAIM</CardB.Title>
                                            <CardB.Subtitle className="mb-4 text-muted">CLAIM THIS REWARD</CardB.Subtitle>
                                            {(!isLoad || isClaimingReward || rewardDisabled) ?
                                            <button
                                                disabled={true}
                                                type="button"
                                                className="form-control btn btn-outline-light  text-center text-muted mx-auto">
                                                <b>LOADING...</b>
                                            </button>
                                            :
                                            <button
                                                onClick={(e) => handleReward(10)}
                                                type="button"
                                                disabled={!isLoad || isClaimingReward || rewardDisabled}
                                                className="form-control btn btn-outline-light  text-center mx-auto">
                                                <b>CLAIM NOW</b>
                                            </button>
                                            }
                                        </CardB.Body>
                                    </CardB>
                                </CardGroup>
                        </Card>
                        </Animated>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

}

const saveMarkCardsTx = async (account, tx, cardIds) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        tx: tx,
        type: "Reward",
        amount: cardIds
    }
    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    await fetch(`${ConstantesAPI().RUTA_API}/guardar_tx.php`, {
        method: "POST",
        body: cargaUtil,
    });
    
    //const exitoso = await respuesta.json();
}

const obtenerFreeCardsMint = async (cuenta) => {

    // Codificar nuestros datos como JSON
    const datos = {
        wallet: cuenta
    }

    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/claim_FreeMint.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const exitoso = await respuesta.json();

    return exitoso;

    
}

export default Reward;