import { Fragment } from "react";
import NoConectado from "./NoConectado/NoConectado";
//import EstaConectado from "./Conectado/EstaConectado";
import InfoAccount from "./Conectado/InfoAccount/InfoAccount";
import Warning from "./Conectado/Warning/Warning";

const Main = props => {

    const { account, balance, balanceUSDC, isConnected, mintDetails } = props.infoAccount;
    const activado = props.activoBuyCap;

    return (
        <Fragment>
            <main>

                { !isConnected ?
                    <NoConectado/>

                    :
                    <Fragment>
                        {!props.canConnectToContract && <Warning/>}

                        <InfoAccount
                            infoAccount = { props.infoAccount }
                            userMintDetails = { mintDetails }
                            currentBalance = { balance }
                            balanceUSDC = { balanceUSDC }
                            currentAccount = { account }
                            web3Api = { props.web3Api }
                            // ----------------
                            reloadUserDetails = { props.reloadUserDetails }
                            canConnectToContract = { props.canConnectToContract }
                            account = { account }
                            mintDetails = { mintDetails }
                            activado = { activado }
                        />
                    </Fragment>
                }

            </main>
        </Fragment>
    );

}
export default Main;