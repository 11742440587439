import { Fragment, useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import ReactPlayer from "react-player";
import { Container } from "react-bootstrap";

import ConstantesAPI from "../../../../utils/API/Constantes";
import { consensusRun } from "../../../../utils/Consensus/Consensus";
import CapsuleView from "../../Elements/Capsule/CapsuleView";
import Paginator from "../../Elements/Paginator/Paginator";
import ConfirmAlertView from "../../Elements/Alerts/ConfirmAlertView";
import OpeningCapsuleAlertView from "../../Elements/Alerts/OpeningCapsuleAlertView";
import { SendSaveOpenCapsulesToServerAPICapsules } from "../../../Services/Capsules/ServerAPICapsules";
import { CreateMintToServerAPICapsules } from "../../../Services/Capsules/ServerAPICapsules";
import InfoAlertView from "../../Elements/Alerts/InfoAlertView";
import ErrorAlertView from "../../Elements/Alerts/ErrorAlertView";
import classes from "./MyCapsule.module.css";
import Card from "../../Elements/Card/Card";

import useDetectMobile  from "../../../../utils/Hooks/useDetectMobile";

const MyCapsule = ({handleToggle, handleReloadUserDetail, ...props}) => {

    const { totalCaps, capsIds, burnedCaps } = props.genesisCapsDetails;
    const infoAccount = props.infoAccount;
    const account = infoAccount.account;
    const { MysteryCapsule } = props.contracts;

    const [ myCapsulesViewJSON, setMyCapsulesView ] = useState([]);
    const [ checkersCapsules, setCheckerCapsules ] = useState([]);
    // ----------------
    const [ syncBurnedCaps, setSyncBurnedCaps ] = useState(true);

    const { isMobile } = useDetectMobile();

    // -----------------------
    // Apertura de capsulas V2
    // -----------------------
    // Cantidad marcada
    const [ cantidad, setCantidad ] = useState(1);

    const minCaps = 1;
    const maxCaps = totalCaps < 50 ? totalCaps : 50;

    const handleChange = event => {
        const value = Math.max(minCaps, Math.min(maxCaps, Number(event.target.value)));
        setCantidad(value);
    };

    // -----------------------

    useEffect(() => {

        const checkSyncBurnedCaps = async () => {
            setSyncBurnedCaps(false);
            await consensusRun();
            setSyncBurnedCaps(true);
        }

        infoAccount && burnedCaps && checkSyncBurnedCaps()
    }, [infoAccount, burnedCaps])

    // -----------------------------------

    //Hook de cambio de estado para lanzamiento de videos al abrir capsulas en componente 
    const launchVideo = useCallback((openedCapsuleCards) => {
            handleToggle(openedCapsuleCards);
    },[handleToggle]);
    
    //Hook para inyectar Capa de Presentación de ConfirmAlertView
    const OpenAllCapsules = () => {

        const auxArrayCapsules = checkersCapsules.length >= 1?
            checkersCapsules
            :(myCapsulesViewJSON.flatMap(capsuleViewJSON => capsuleViewJSON.idCapsule)).slice(0,cantidad)

        //Activar una vez terminado el launchVideo
        return ConfirmAlertView({
            account, 
            arrayCheckersCapsules: auxArrayCapsules, 
            MysteryCapsule,
            handleOpenCapsuleAlertView: OpeningCapsuleAlertView, 
            handleSaveAPICapsules: SendSaveOpenCapsulesToServerAPICapsules, 
            handleCreateMintAPICapsules: CreateMintToServerAPICapsules, 
            handleInfoAlertView: InfoAlertView, 
            handleErrorAlertView: ErrorAlertView,
            handleFinishFunction: launchVideo
        });

    }

    //Controlador de estados
    useEffect(() => {

        //Hook de consulta de estado para Capa de Presentación CapsuleView
        const isCheckedCapsule = ({idCapsule}) => {
            const isChecked = checkersCapsules.find( checkersCapsules => checkersCapsules === idCapsule)?true:false;
            return isChecked;
        }

        //Hook de cambio de estado (Checked/no Checked) para Capa de Presentacio CapsuleView
        const toggleCheckerCapsule = ({idCapsule, isChecked}) => {
            let auxCheckersCapsules = [];
            if(isChecked){
                auxCheckersCapsules = checkersCapsules.concat([idCapsule])
            }else{
                auxCheckersCapsules = checkersCapsules.filter(checkerCapsule => checkerCapsule !== idCapsule);
            }
            setCheckerCapsules(auxCheckersCapsules);
            
        }

        const loadCapsules = () => {

            let auxMyViewCapsulesJSON = [];

            capsIds.forEach(infoCapsule => {

                const idCapsule = infoCapsule.words[0];

                auxMyViewCapsulesJSON.push({
                    account,
                    idCapsule,
                    MysteryCapsule,
                    handleToggleCheckersCapsules: toggleCheckerCapsule, 
                    handleIsChecked: isCheckedCapsule,
                    handleOpenCapsuleFinishFunction: launchVideo,
                    urlCapsuleServer: ConstantesAPI().RUTA_CAPSULA
                });
            });

            setMyCapsulesView(auxMyViewCapsulesJSON);
        } 

        capsIds && loadCapsules();

    }, [MysteryCapsule, account, capsIds, checkersCapsules, launchVideo])


    return (
        totalCaps !== 0 ?
        <Fragment>
            <div className={classes.centrador}>
                <Animated animationIn="zoomInDown" animationOut="fadeOut" isVisible={true}>
                    <h1 className="display-1 fw-bolder text-white">MY CAPSULES</h1>
                </Animated>
                <Animated animationIn="zoomInDown" animationOut="fadeOut" animationInDelay={300} isVisible={true}>
                    <p className="text-white fw-bolder">YOU HAVE {totalCaps} UNOPENED CAPSULES</p>
                
                { ((totalCaps >= 1 && syncBurnedCaps) && 
                    ((checkersCapsules.length >= 1 || isMobile) ?
                        <center>
                            <button type="button" className={!isMobile ? "btn btn-outline-light p-4 mb-4 w-25" : "btn btn-outline-light p-4 mb-4 w-75"} disabled={!syncBurnedCaps || checkersCapsules.length > maxCaps || checkersCapsules.length === 0} onClick={()=>OpenAllCapsules()}>
                                <img src="images/iconDark.png" alt="Dark Earth LOGO" />
                                <span className="m-2 fw-bolder"> { checkersCapsules.length === 0 ? "PICK CAPSULES" : "OPEN " + checkersCapsules.length + " CAPSULES"}</span>
                            </button>
                        </center>
                    :
                        <div className="w-50 mx-auto m-2 p-2 bg-dark rounded">
                            <span className="text-white fw-bold text-center m-2 p-2">How many capsules do you want to open?</span> <br/>
                            <input
                                className="text-center fw-bolder form-control btn btn-outline-light w-25 m-2 mx-2"
                                type="number"
                                min={1}
                                max={maxCaps}
                                step={1}
                                value={cantidad}
                                onChange={handleChange}
                            />
                            <button type="button" className="btn btn-success w-25" disabled={!syncBurnedCaps || checkersCapsules.length > maxCaps} onClick={()=>OpenAllCapsules()}>
                                <span className="m-2 fw-bolder"> SUBMIT</span>
                            </button>
                        </div>)
                )}
                </Animated>

                <Container fluid>
                    <Paginator arrayViewItems={myCapsulesViewJSON} countItemsPerPage = {8} handleElementView={CapsuleView} />
                </Container>
                
            </div>
        </Fragment>
        :
        <Fragment>
            <div className={classes.centrador}>
                <Animated animationIn="zoomInDown" animationOut="fadeOut" isVisible={true}>
                    <h1 className="display-1 fw-bolder text-white">MY CAPSULES</h1>
                </Animated>

                <Card className={classes.infoCapsules}>
                        <ReactPlayer
                            url="videos/CAPSULE_VP9.webm"
                            playing
                            muted
                            loop
                            width='100%'
                            height='100%'
                        />
                        <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true} animationInDelay={1000}>
                            <h3 className="display-6 text-center pt-3">You don't have Mystery Capsules</h3>
                        </Animated>
                    <br/>
                </Card>
            </div>
        </Fragment>
    );

}

export default MyCapsule;