import { Animated } from "react-animated-css";
import { Col, Image } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import HoverVideoPlayer from 'react-hover-video-player';

//import ProgressiveImg from "../ProgressiveImage/ProgressiveImage";
import Card from "./Card";
import classes from "./Card.module.css";

const getServerCardJson = ({infoCard, defaultVideo, defaultImage, urlCardServer}) => {
    //atributos
    const id = infoCard.id;
    const name = infoCard.nombre;
    const type = infoCard.tipo;
    const faction = infoCard.faccion;
    const mintType = infoCard.mintType;
    const rarity = infoCard.rareza;
    const count = infoCard.counterTypes;
    const finalUrlServer = urlCardServer+id;

    //video or animation
    const isImage = infoCard.mintType === "Steel" || infoCard.mintType === "Master Art" || infoCard.mintType === "KeyArt";
    
    const assetUrl = (!isImage && finalUrlServer+".mp4") || (isImage && finalUrlServer+".png");

    return {
        name: name,
        type: type,
        faction: faction,
        mintType: mintType,
        rarity: rarity,
        assetUrl: assetUrl,
        isVideo: !isImage,
        count: count,
        isImage: isImage
    }
}

const getNFTCardJson = ({infoCard, defaultVideo, defaultImage}) => {
    /*
    //atributos
    console.log(infoCard);
    const name = infoCard.name;
    const faction = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Faction').value : 'None';
    const mintType = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Mint type').value : 'Special';
    const rarity = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Rarity').value : 'Special';

    const type = infoCard.attributes.find( atribute => atribute.trait_type === 'Type').value;
    const count = infoCard.tokensID.length? infoCard.tokensID.length:0;
    
    //video or animation
    const isVideo = infoCard.hasOwnProperty('animation_url')? true : false;
    const isImage = infoCard.hasOwnProperty('image')? true : false;
    
    const assetUrl = (isVideo && infoCard.animation_url ) || (isImage && infoCard.image);

    return {
        name,
        type,
        faction,
        mintType,
        rarity,
        assetUrl,
        isVideo,
        isImage,
        count
    }*/
    const name = infoCard.name;
    const type = infoCard.type;
    const faction = infoCard.faction;
    const mintType = infoCard.mintType;
    const rarity = infoCard.rarity;
    const assetUrl = infoCard.assetUrl;
    const isVideo = infoCard.isVideo;
    const isImage = infoCard.isImage;

    const canStake = infoCard.tokenId ? true : false;
    const tokenId = infoCard.tokenId;

    let count = 0;

    if(infoCard.tokensID) {
        count = infoCard.tokensID.length ? infoCard.tokensID.length : 0;
    } else if(infoCard.counterTypes) {
        count = infoCard.counterTypes ? infoCard.counterTypes : 0;
    }
    return {
        name,
        type,
        faction,
        mintType,
        rarity,
        assetUrl,
        isVideo,
        isImage,
        count,
        canStake,
        tokenId
    }
    //return infoCard;
}

const getCardJson = ({infoCard, defaultVideo, defaultImage, urlCardServer}) => {
    if(infoCard.hasOwnProperty('id') && infoCard.hasOwnProperty('idCard')){
        return getServerCardJson({infoCard, defaultVideo, defaultImage, urlCardServer});
    } else {
        return getNFTCardJson({infoCard, defaultVideo, defaultImage});
    }
}

const CardView = ({infoCard, defaultImage, defaultVideo, urlCardServer}, handleOnClick = (info)=>{console.log(info)}) => {
    const cardJson = getCardJson({infoCard, defaultImage, defaultVideo, urlCardServer});

    return(
            <Col onClick={()=>{handleOnClick(infoCard)}}>
                <Animated animationIn="zoomIn" animationInDelay={Math.random()*500} animationOut="fadeOut" isVisible={true}>
                    <Card className={classes.cardContainer}>

                        { cardJson.count > 1 &&
                            <div className={classes.cardCountNumber}>{cardJson.count}</div>
                        }

                        <Card className={classes.cardImg}>
                            {cardJson.isVideo?
                                <HoverVideoPlayer
                                    videoSrc={cardJson.assetUrl}
                                    preload="auto"
                                    restartOnPaused = "true"
                                    volume={0.5}
                                    muted={false}
                                    loadingOverlay={
                                        <Spinner animation="border" role="status" variant="light">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    }
                                />
                                :
                                <Image fluid key={Math.random()*123456789} src={cardJson.assetUrl} alt="Dark Earth Card"/>
                            }
                        </Card>

                        <Card className={classes.cardInfo}>
                            <h3 className="mx-auto text-center pb-3">
                                <span className={classes.title_8}>{cardJson.name}</span> <br/>
                                <span className="text-muted text-uppercase">
                                    <span className={classes.title_4}>({cardJson.rarity} - {cardJson.mintType})</span>
                                </span>
                                {/* cardJson.canStake && 
                                    <button className='btn btn-outline-light w-100' onClick={() => confirmAlert(cardJson.tokenId)}>
                                        STAKE NOW
                                    </button>
                                */}
                            </h3>
                        </Card>

                    </Card>
                </Animated>
            </Col>
    );
}

export default CardView;