import { Container, Col, Row } from "react-bootstrap";
import Card from "../Card/Card";

import useDetectMobile  from "../../../../utils/Hooks/useDetectMobile";
const SmartContractsView = () => {
    const { isMobile } = useDetectMobile();
    
    return (
        <Container fluid="xxl" className="bg-black mx-auto text-center pt-4">
            <Row>
                <p className="text-white display-6 pt-4">Our Smart Contracts</p>
            </Row>
            
            <Row>
                <Col>
                    <div className="p-2">
                        <Card>
                            <p className="text-white text-center p-4">
                                <strong>Genesis Mystery Capsules</strong> (ERC721)<br/>
                                { !isMobile && <code className="bg-light text-black rounded p-1">0xcF06A29C07a1CD8f232C9Ad3e3FFcF1feDA4cee5</code> }
                            </p>

                            <div className="px-2 pb-4 mx-auto">
                                <code className="text-white">
                                    Mystery Capsules by Dark Earth are special NFTs that gives 8 random NFTs from Genesis Edition when are opened.
                                </code>
                            </div>

                            <div className="m-2">
                                <a href="https://github.com/solidproof/projects/tree/main/DarkEarth" target="_blank" rel="noreferrer"><img src="images/audit/badge.png" alt="Badge audited"/></a>
                            </div>

                            <a href="https://polygonscan.com/address/0xcF06A29C07a1CD8f232C9Ad3e3FFcF1feDA4cee5" target="_blank" rel="noreferrer" className="mx-2">
                                <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                                    <span className="m-2">PolygonScan</span>
                                </button>
                            </a>
                        </Card>
                    </div>
                </Col>
                <Col>
                    <div className="p-2">
                        <Card>
                            <p className="text-white text-center p-4">
                                <strong>Dark Earth Collection</strong> (ERC721)<br/>
                                { !isMobile && <code className="bg-light text-black rounded p-1">0xF57F3A0b3415cC2daE6b3A3c4Fd3FC58626bE16E</code>}
                            </p>

                            <div className="px-2 pb-4 mx-auto">
                                <code className="text-white">
                                    All these NFTs will be playable in the Games of the ecosystem Dark Earth. <br/>These NFTs can be collected for special rewards.
                                </code>
                            </div>

                            <div className="m-2">
                                <a href="https://github.com/solidproof/projects/tree/main/DarkEarth" target="_blank" rel="noreferrer"><img src="images/audit/badge.png" alt="Badge audited"/></a>
                            </div>

                            <div className="pb-2">
                                <a href="https://polygonscan.com/address/0xF57F3A0b3415cC2daE6b3A3c4Fd3FC58626bE16E" target="_blank" rel="noreferrer" className="mx-1">
                                    <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                                        <span className="mx-1">PolygonScan</span>
                                    </button>
                                </a>
                                <a href="https://opensea.io/collection/dark-earth-collection" target="_blank" rel="noreferrer" className="mx-1">
                                    <button type="button" className="btn btn-outline-light text-center mx-auto m-3">
                                        <span className="mx-1">Opensea</span>
                                    </button>
                                </a>
                            </div>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default SmartContractsView;