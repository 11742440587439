import { Col, Row, Container } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import HoverVideoPlayer from 'react-hover-video-player';
import { Animated } from "react-animated-css";
import { Fragment } from "react";

import ProgressiveImg from "../../Elements/ProgressiveImage/ProgressiveImage";
import { _ConstCollectionService } from "../../../../utils/API/Constantes";
import { StandardTokenType } from "../../../Services/DataCards/CollectionTypes";
import classes from "./DetailCollectionCard.module.css";

const DetailCollectionCardView = ({
    infoCard = StandardTokenType,
    handleToggleDetailCardView = (infoCard)=>{}
}) => {

    return (
        
            <Container fluid>
                <div className={classes.container_DetailCard}>
                    <Animated  className={classes.cardInfoRow} animationIn="fadeIn" animationInDelay={0} animationOut="fadeOut" isVisible={true}>
                        <Row>
                            <Col className={classes.cardImageCol}>
                                {infoCard.isVideo?
                                    <HoverVideoPlayer
                                        videoSrc={infoCard.assetUrl}
                                        preload="auto"
                                        restartOnPaused = "true"
                                        volume={0.5}
                                        muted={false}
                                        className={classes.cardImage}
                                        loadingOverlay={
                                            <Spinner animation="border" role="status" variant="light">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        }
                                    />
                                    :
                                    <ProgressiveImg className={classes.cardImage} src={infoCard.assetUrl} placeholderSrc={_ConstCollectionService.DEFAULT_CARD_IMAGE} alt="Dark Earth Card"/>
                                }
                            </Col>

                            <Col xs={9} xxl={8} className={classes.descriptionBox_DetailCard}>
                                <Row>
                                    <p className="display-3 mb-2 fw-bolder text-uppercase">{infoCard.name}</p>
                                    <p className="mb-2 fw-bold fst-italic">{infoCard.rarity} || {infoCard.mintType}</p>

                                    {infoCard.description && <p className="text-white mb-4 w-75">{infoCard.description}</p> }
                                </Row>
                                <p className="display-6 fw-bold pt-3">DATA</p>
                                <Row className={classes.metadataRow}>
                                    <Col>
                                        <span className="fw-bolder">Faction</span>
                                            <p>{infoCard.faction}</p>

                                        <span className="fw-bolder">Type</span>
                                            <p>{infoCard.type}</p>

                                    </Col>
                                    <Col>
                                        <span className="fw-bolder">Ilustrador</span>
                                            <p>{infoCard.ilustrator}</p>
                                        {infoCard.animator && 
                                        <Fragment>
                                        <span className="fw-bolder">Animador</span>
                                            <p>{infoCard.animator}</p>
                                        </Fragment>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="mx-auto text-center">
                            <button type="button" className="btn btn-warning" onClick={()=>handleToggleDetailCardView(infoCard)}>
                                <span className="p-3 fw-bolder">Click to close</span>
                            </button>
                        </div>
                    </Animated>
                </div>
                
            </Container>
    );
}

export default DetailCollectionCardView;

/* InfoCard
faccion: "Askari"
id: 15
idCard: 15
mintType: "Steel"
nombre: "Nisal Den"
rareza: "Basic"
supply: 5000
tipo: "Support"
*/