import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { JsonToTable } from "react-json-to-table";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

import classes from "./DropdownWallet.module.css";
import BDLoader from '../../BDLoader/BDLoader';
import ConstantesAPI from '../../../../../utils/API/Constantes';

import useDetectMobile from '../../../../../utils/Hooks/useDetectMobile';

const DropdownWallet = (props) => {

    const [ modalShow, setModalShow ] = useState(false);
    const [ txWallet, setTxWallet ] = useState();
    const [ needReload, setNeedReload ] = useState(false);
    const { isMobile } = useDetectMobile();

    const getTransactions = async () => {
      const id = props.currentAccount;
      const respuesta = await fetch(`${ConstantesAPI().RUTA_API}/obtener_txs.php?id=${id}`);
      const transTx = await respuesta.json();
      setTxWallet(transTx);
      setModalShow(true);
    }

    const account = (props.currentAccount).substr(0,5)+"....."+(props.currentAccount).substr(38,43);
    const balance = !isMobile ? Number(props.balance).toFixed(4) : Number(props.balance).toFixed(2);
    const balanceUSDC = Number(props.balanceUSDC).toFixed(2);

    const isMumbai = props.chainId === 80001;
    const chainVariant = isMumbai ? "warning" : "light";

    // <small>{isMumbai && "(MUMBAI)"}</small>

    const handleNeedReload = (toggle) => {
      setNeedReload(toggle);
    }

    return (
      <Container className="d-flex justify-content-end">
          {!isMobile ?
            <Dropdown>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2"><b>You are in sync!</b> 👌<br/>Press RELOAD to <b>force update all your data.</b></Tooltip>}>
                <Dropdown.Toggle variant={chainVariant} id="dropdown-basic">
                  <BDLoader
                    getBlockchainData = { props.getBlockchainData }
                    account = { props.currentAccount }
                    useLoad = { props.useLoad }
                    message = { account }
                    needReload = { needReload }
                    handleNeedReload = { handleNeedReload }
                  />
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu>
                <Dropdown.Item disabled className={classes.dropMenu}><b>{balance} MATIC</b></Dropdown.Item>
                <Dropdown.Item disabled className={classes.dropMenu}><b>{balanceUSDC} USDC</b></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className={classes.dropMenu} onClick = { () => handleNeedReload(true) }>RELOAD</Dropdown.Item>
                <Dropdown.Item className={classes.dropMenu} onClick = { getTransactions }>See transactions</Dropdown.Item>
                <Dropdown.Item className={classes.dropMenu} onClick = {props.onLogout} >Disconnect</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          :
            <button className='btn btn-outline-light w-100' onClick={() => handleNeedReload(true)}>
              <BDLoader
                getBlockchainData = { props.getBlockchainData }
                account = { props.currentAccount }
                useLoad = { props.useLoad }
                message = { account }
                needReload = { needReload }
                handleNeedReload = { handleNeedReload }
              />
              <small><span className='text-warning fw-bold'>{balance}</span> MATIC | <span className='text-warning fw-bold'>{balanceUSDC}</span> USDC</small>
            </button>
          }
          
        { txWallet &&
          <VerTransacciones
            wallet = {props.currentAccount}
            txs = {txWallet}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        }
      </Container>
    );
}

function VerTransacciones(props) {
  const valores = props.txs;
  return (
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Your transactions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="display-8">{props.wallet}</h4>
        <JsonToTable json={valores} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DropdownWallet;

/*
    
    const addMysteryCapsuleToken = async () => {
        const tokenAddress = MysteryCapsule.address;
        const tokenSymbol = 'Mystery Cap';
        const tokenDecimals = 0;
        const tokenImage = 'https://i.imgur.com/1nSmmCW.png';
    
        try {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20', // Initially only supports ERC20, but eventually more!
              options: {
                address: tokenAddress, // The address that the token is at.
                symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                decimals: tokenDecimals, // The number of decimals in the token
                image: tokenImage, // A string url of the token logo
              },
            },
          });
        } catch (error) {
          console.log(error);
        }
    }
    
*/