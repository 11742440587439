import { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { Col, Container, Row } from "react-bootstrap";
import { Animated } from "react-animated-css";

import classes from "./Paginator.module.css";
import VideoAperturaGridCardView from "../../General/VideoApertura/VideoAperturaGridCardView";

const PaginatorVideoApertura = ({ arrayViewItems, countItemsPerPage }) => {
    
    const [currentItems, setCurrentItems] = useState([{srcCard: "", isVideo: false}]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  

    useEffect(() => {

      const endOffset = itemOffset + countItemsPerPage;
      setCurrentItems(arrayViewItems.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(arrayViewItems.length / countItemsPerPage));

    }, [itemOffset, arrayViewItems, countItemsPerPage]);
  

    const handlePageClick = (event) => {

      const newOffset = (event.selected * countItemsPerPage) % arrayViewItems.length;
      setItemOffset(newOffset);

    };
  
    return (
      <>
        <VideoAperturaGridCardView cardsSrcOfCapsule = {currentItems} />
        <Animated className={classes.selector_video_apertura} animationIn="bounceIn" animationInDelay={300} animationOut="fadeOut" isVisible={true}>
            <Container fluid>
                <Row>
                    <Col>
                        <ReactPaginate
                            nextLabel="➡️"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="⬅️"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </Col>
                </Row>
            </Container>
        </Animated>
      </>
    );
}

export default PaginatorVideoApertura