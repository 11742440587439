import { GlobalVariables } from "../GlobalVariables";

const ConstantesAPI = () => {

    let web3Api = GlobalVariables;
    let networkId = web3Api.networkId;

    let websiteUrl = window.location.href;

    const polygon = {
        NETWORK_ID: 137,
        NETWORK_NAME: "polygon",
        NETWORK_NAME2: "matic",
        NETWORK_SHOWNAME: "Polygon - MATIC",
        NETWORK_RPC: "https://polygon-rpc.com",
        NETWORK_BLOCKSCAN: "https://polygonscan.com/",
        NETWORK_SCANTX: "https://polygonscan.com/tx/",
        INFURA_ID: "62559bba8bef40748d7823c29b2ea045",
        RUTA_API: "https://api.darkearth.gg",
        RUTA_CAPSULA: "https://opensea.io/assets/matic/0xcf06a29c07a1cd8f232c9ad3e3ffcf1feda4cee5/",
        RUTA_COLLECTION: "https://opensea.io/assets/matic/0xf57f3a0b3415cc2dae6b3a3c4fd3fc58626be16e/",
        RUTA_CARDS_MEDIA: "https://nft-hub.darkearth.gg/cards/media/",
        RUTA_CARDS: "https://nft-hub.darkearth.gg/cards/",
        DEFAULT_CARD_IMAGE: "images/EmptyCard.png",
        CORS_MODE: "cors"//cors | no-cors
    };

    const mumbai = {
        NETWORK_ID: 80001,
        NETWORK_NAME: "mumbai",
        NETWORK_NAME2: "mumbai",
        NETWORK_SHOWNAME: "Mumbai TESTNET",
        NETWORK_RPC: "https://rpc-mumbai.maticvigil.com",
        NETWORK_BLOCKSCAN: "https://mumbai.polygonscan.com/",
        NETWORK_SCANTX: "https://mumbai.polygonscan.com/tx/",
        INFURA_ID: "62559bba8bef40748d7823c29b2ea045",
        RUTA_API: "https://apitest.darkearth.gg",
        RUTA_CAPSULA: "https://opensea.io/assets/matic/0xcf06a29c07a1cd8f232c9ad3e3ffcf1feda4cee5/",
        RUTA_COLLECTION: "https://opensea.io/assets/matic/0xf57f3a0b3415cc2dae6b3a3c4fd3fc58626be16e/",
        RUTA_CARDS_MEDIA: "https://nft-hub.darkearth.gg/cards/media/",
        RUTA_CARDS: "https://nft-hub.darkearth.gg/cards/",
        DEFAULT_CARD_IMAGE: "images/EmptyCard.png",
        CORS_MODE: "cors"//cors | no-cors
    }

    let respuesta = null;

    if(networkId === polygon.NETWORK_ID) {

        const isVerified = websiteUrl.startsWith("https://app.darkearth.gg");
        //const isDev = websiteUrl.startsWith("https://dev.darkearth.gg");
        if(isVerified) respuesta = polygon;
        else throw new Error("Not verified website");

    } else if(networkId === mumbai.NETWORK_ID) {

        respuesta = mumbai;

    } else {
        console.log("Other chain");
        respuesta = null;
    }

    return respuesta;
}


export const _ConstCollectionService = {
    RUTA_CARDS_MEDIA: "https://nft-hub.darkearth.gg/cards/media/",
    RUTA_CARDS: "https://nft-hub.darkearth.gg/cards/",
    DEFAULT_CARD_IMAGE: "images/EmptyCard.png",
    CORS_MODE: "cors"//cors | no-cors
}
export default ConstantesAPI;

