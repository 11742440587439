import React, { Fragment } from "react";
import { Container } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Unity, useUnityContext } from "react-unity-webgl";

import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import NoDisponible from "../NoDisponible/NoDisponible";


const GameTest = (props) => {

    const { isConnected, roles } = props.infoAccount;
    const { isTester } = roles;
    const zonaActiva = props.activo;

    const { unityProvider, isLoaded, loadingProgression, requestFullscreen, unload } = useUnityContext({
        loaderUrl: "https://gametest.darkearth.gg/Build/DARK_EARTH.loader.js",
        dataUrl: "https://gametest.darkearth.gg/Build/DARK_EARTH.data",
        frameworkUrl: "https://gametest.darkearth.gg/Build/DARK_EARTH.framework.js",
        codeUrl: "https://gametest.darkearth.gg/Build/DARK_EARTH.wasm",
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    function handleClickEnterFullscreen() {  requestFullscreen(true);}

    async function handleUnload() {  await unload();}

    return (
        <Fragment>
            <main>

                { !isConnected ?
                    <NoConectado/>

                    :
                    (zonaActiva && isTester) ?
                        <Container fluid className="mx-auto text-center">
                                {!isLoaded &&
                                    <Fragment>
                                        <span className="text-white text-center fw-bold"> WAIT PLEASE, WE ARE LOADING THE DEMO-TECH...</span>
                                        <ProgressBar striped variant="info" className="w-25 mx-auto" now={loadingPercentage} label={`${loadingPercentage}%`} />;
                                    </Fragment>
                                }
                                <div className="w-100">
                                    {isLoaded && <span className="text-white text-center fw-bold"> THIS IS A DEMO-TECH!<br/><br/></span>}
                                    <button className="btn btn-light w-25" onClick={handleClickEnterFullscreen}>Enter Fullscreen</button>
                                    <button className="btn btn-danger fw-bold w-25" onClick={handleUnload}>Exit game</button><br/>
                                    <Unity className="w-75" unityProvider={unityProvider} />
                                </div>
                        </Container>
                    :
                        <NoDisponible/>
                }

            </main>
        </Fragment>
  );

}

export default GameTest;