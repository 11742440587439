import { Fragment } from "react";
import { Animated } from "react-animated-css";
import Spinner from 'react-bootstrap/Spinner';
import HoverVideoPlayer from 'react-hover-video-player';

import Card from "./Card";
import classes from "./Card.module.css";
import { actualLoader } from "../../../Services/Loader/LoaderFunctions";

const LoaderCardsView = ({loadingText})=>{
    return(
        <Fragment>
            <Card className={classes.cardContainer}>
                <Card className={classes.cardLoading}>
                    <HoverVideoPlayer
                        videoSrc={actualLoader}
                        preload="auto"
                        loadingOverlay={
                            <Spinner animation="border" role="status" variant="light">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        }
                    />
                </Card>
                <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true} animationInDelay={400} className="mt-2 pb-4">
                    <h3 className="display-6 text-center pt-4 pb-4">{loadingText}</h3>
                </Animated>
            </Card>
        </Fragment>
    );
}

export default LoaderCardsView;