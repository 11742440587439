    /*
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(defaultChain) }],
      });
    } catch (err) {
      // El error 4902 indica que la Network no está configurada
      if (err.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: "Mumbai",
                chainId: web3.utils.toHex(defaultChain),
                nativeCurrency: {
                    name: 'MATIC',
                    decimals: 18,
                    symbol: 'MATIC'
                  },
                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
              },
            ],
          });
        } catch(addError){
          console.error("ERROR on addChain -> ", addError);
        }
      }
     }
    */

import { getWalletIsTester } from "./API/ApiFunctions";


export const changeNetwork = async (web3) => {
    const defaultChain = 137;

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(defaultChain) }],
      });
    } catch (err) {
      // El error 4902 indica que la Network no está configurada
      if (err.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: "Polygon (MATIC)",
                chainId: web3.utils.toHex(defaultChain),
                nativeCurrency: {
                    name: 'MATIC',
                    decimals: 18,
                    symbol: 'MATIC'
                  },
                rpcUrls: ["https://polygon-rpc.com"],
                blockExplorerUrls: ["https://polygonscan.com/"],
              },
            ],
          });
        } catch(addError){
          console.error(addError);
        }
      }
     }
}

export const reloadDetails = async (web3, contracts, account, networkId) => {

    if(web3 && contracts && account){

      const { MysteryCapsule, DECollection, USDC } = contracts;

      // BALANCE DE LA CUENTA
      const balance = await web3.eth.getBalance(account);
      const balancePol = web3.utils.fromWei(balance, "ether");

      let _usdcBalance = await USDC.balanceOf(account);
      let balanceUsdc;
      // Balance USDC
      if(networkId === 80001) {
        balanceUsdc = web3.utils.fromWei(_usdcBalance.toString(), "ether");
      } else if(networkId === 137) {
        balanceUsdc = web3.utils.fromWei(_usdcBalance.toString(), "Mwei");
      }
      

      // COMPROBAR ROLES ASIGNADOS
      // Rol de OWNER
      const esOwner = await MysteryCapsule.existOwner.call(account);

      const ADMIN_ROLE = web3.utils.soliditySha3("ADMIN_ROLE");
      const esAdmin = await MysteryCapsule.hasRole.call(ADMIN_ROLE, account);

      const WHITELIST_ROLE = web3.utils.soliditySha3("WHITELIST_ROLE");
      const canWhitelist = await MysteryCapsule.hasRole.call(WHITELIST_ROLE, account);

      const MINTER_ROLE = web3.utils.soliditySha3("MINTER_ROLE");
      const esMinter = await MysteryCapsule.hasRole.call(MINTER_ROLE, account);

      // COMPROBAR SI ESTÁ EN LA WL
      const _isWhitelisted = await MysteryCapsule.isWhitelisted.call(account);

      // COMPROBAR SI ESTÁ ACTIVA LA VENTA PÚBLICA
      const _isPublicSale = await MysteryCapsule.isPublicSale.call();

      // LIMITE DE CAPSULAS DEL USUARIO
      const _mintableChest = await MysteryCapsule.getMintableChest.call(account);
      
      // Capsulas restantes por mintear
      const _defaultMintAmount = await MysteryCapsule.getDefaultMintAmount.call();
      const _userMinted = await MysteryCapsule.getMintedCapsules.call(account);

      // Comprobar total de capsulas que tiene el usuario
      const _totalCaps = await MysteryCapsule.getChests.call(account);

      // Capsulas que a quemado el usuario
      const _dirtyBurnedCaps = await MysteryCapsule.getBurnedCapsules.call(account);
      const _burnedCaps = _dirtyBurnedCaps.flatMap(dirtyTokenId => dirtyTokenId.words[0]);

      // Comprobar total de capsulas que tiene el usuario
      const _totalCards = await DECollection.getTokenIds.call(account);
      const _totalCleanCards = _totalCards.flatMap(dirtyTokenId => dirtyTokenId.words[0]);

      const isTester = await getWalletIsTester(account);

      // ---------------------
      /*
      let _stakedIds = [];
      let _stakedTypes = [];
      let _stakedTime = [];

      const _stakedInfo = await DEStaking.getStaked.call({from: account});

      _stakedInfo.forEach(element => {
        _stakedIds.push(element[0]);
        _stakedTypes.push(element[1]);
        _stakedTime.push(element[2]);
      });

      stakingDetails: {
          stakedIds: _stakedIds,
          stakedTypes: _stakedTypes,
          stakedTime: _stakedTime,
          stakedMasterArt: _stakedIds.filter(o1 => o1 >= 47 && o1 <= 50),
          stakedFactionBanner: _stakedIds.filter(o1 => o1 >= 41 && o1 <= 45),
          stakedKeyArt: _stakedIds.filter(o1 => o1 === 46),
          stakedInfo: _stakedInfo
        },
      */
      
      const respuesta = {
        account: account,
        isConnected: true,
        balance: balancePol,
        balanceUSDC: balanceUsdc.toString(),
        mintDetails: {
          isPublicSale: _isPublicSale,
          isWhitelisted: _isWhitelisted,
          mintableChest: _mintableChest.toString(),
          userMinted: _userMinted.toString(),
          maxMintAmount: _defaultMintAmount,
        },
        genesisCapsDetails: {
          totalCaps: _totalCaps.length,
          capsIds: _totalCaps,
          burnedCaps: _burnedCaps
        },
        collectionDetails: {
          totalCards: _totalCleanCards.length,
          cardsIds: _totalCleanCards
        },
        roles: {
          isOwner: esOwner,
          canWhitelist: canWhitelist,
          isAdmin: esAdmin,
          isMinter: esMinter,
          isTester: isTester
        }
      }

      return respuesta;
    }
  }

export const resetInfoAccount = () => {
  
  const vacio = {
    account: null,
    isConnected: false,
    balance: 0,
    balanceUSDC: 0,
    mintDetails: {
      isPublicSale: false,
      isWhitelisted: false,
      mintableChest: 0,
      userMinted: 0,
      maxMintAmount: 0,
    },
    genesisCapsDetails: {
      totalCaps: 0,
      capsIds: [],
      burnedCaps: []
    },
    collectionDetails: {
      totalCards: 0,
      cardsIds: [],
    },
    stakingDetails: {
      stakedIds: [],
      stakedTypes: [],
      stakedTime: []
    },
    roles: {
      isOwner: false,
      canWhitelist: false,
      isAdmin: false,
      isMinter: false,
      isTester: false
    }
  }

  return vacio;
}