//import ReactLoading from "react-loading";
import { Container } from "react-bootstrap";
import CenterLogo from "../../Elements/CenterLogo/CenterLogo";
import classes from "./Loader.module.css";

const Loader = (props) => {

    return (
        <Container fluid className="bg-black">
            <div className={classes.loaderDiv}>
                <CenterLogo/>
                <div className="container-fluid d-flex col-sm">
                    <div className={classes.moon}>
                        <img src="https://cdn2.iconfinder.com/data/icons/thesquid-ink-40-free-flat-icon-pack/64/space-rocket-512.png" alt="Leira" className={classes.loader}/>
                        <ul className={classes.ulstyle}>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                            <li className={classes.listyle}></li>
                        </ul>
                    </div>
                </div>
                <h3 className="mt-3 display-6 fw-bold text-center text-white">Landing on Leira...</h3>
            </div>
        </Container>
    );
    
}

export default Loader;