import Reward from "../../components/UI/pages/Reward/Reward";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import NoDisponible from "../NoDisponible/NoDisponible";


const Rewards = (props) => {

     const { isConnected } = props.infoAccount;

    const activado = props.activo;

    return (
        <main>
            { !isConnected ?
                    <NoConectado/>

                    :
                    
                    activado ?
                        <Reward
                            infoAccount = { props.infoAccount }
                            contracts = { props.contracts }
                            web3 = { props.web3 }
                            reloadUserDetails = { props.reloadUserDetails }
                            useLoad = { props.useLoad }
                            collection = { props.collection }
                        />
                    :
                        <NoDisponible/>
                }
        </main>
    );

}



export default Rewards;