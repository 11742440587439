import { Fragment } from "react";
import { Image } from "react-bootstrap";
import HoverVideoPlayer from 'react-hover-video-player';

import { StandardTokenType } from "../../../Services/DataCards/CollectionTypes";
import { MintTypeRanges } from "../../../Services/Filters/FilterTypes";
import { _ConstCollectionService } from "../../../../utils/API/Constantes";
import ProgressiveImg from "../../Elements/ProgressiveImage/ProgressiveImage.js";
import classes from "./CardGallery.module.css";

/**
 * Componente de visualización de carta para Galería.
 * @param {StandardTokenType} card StandardTokenType
 * @returns Componente de visualización de carta.
 */
export const ViewGalleryCard = (card = StandardTokenType, handleOnClick = (card)=>{/*console.log(card)*/}) => {
    return (
        <Fragment key={card.index}>
            {(card.index === MintTypeRanges.masterArt.min) && <div><br/><h1 className="display-3 text-white text-center fw-bold">Master Art</h1><br/></div>}
            {(card.index === MintTypeRanges.factionBanner.min) && <div><br/><h1 className="display-3 text-white text-center fw-bold">Faction banner</h1><br/></div>}
            {(card.index === MintTypeRanges.keyArt.min) && <div><br/><h1 className="display-3 text-white text-center fw-bold">Key Art</h1><br/></div>}
            {card.isVideo?
            <span onClick={()=>{handleOnClick(card)}}>
                <HoverVideoPlayer
                    videoSrc={card.assetUrl}
                    preload="auto"
                    restartOnPaused = "true"
                    volume={0.5}
                    muted={false}
                    className={classes.tamCard}
                />
            </span>
            :
                card.index === MintTypeRanges.keyArt.max?
                    <Image fluid src={card.assetUrl} className="w-75 rounded"  onClick={()=>{handleOnClick(card)}}/>
                    :
                    <ProgressiveImg src={card.assetUrl} className={classes.tamCard} placeholderSrc={_ConstCollectionService.DEFAULT_CARD_IMAGE} alt="Dark Earth Card"  onClick={()=>{handleOnClick(card)}}/>
            }
        </Fragment>
    );

}