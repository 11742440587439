import { Fragment, useEffect, useState } from "react";
import { CardGroup, Card as CardB, Container, Row } from "react-bootstrap";
import { JsonToTable } from "react-json-to-table";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import classes from "./Referrals.module.css";

import { getReferralCode, getReferralInfo, insertOrUpdateReferral } from "../../utils/API/ApiFunctions";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import Card from "../../components/UI/Elements/Card/Card";
import CenterLogo from "../../components/UI/Elements/CenterLogo/CenterLogo";

const Referrals = (props) => {

    const infoAccount = props.infoAccount;
    const { isConnected } = infoAccount;
    const showDetails = props.showDetails || false;
    const navigate = useNavigate();

    const [ refCode, setRefCode ] = useState();
    const [ refAmount, setRefAmount ] = useState(0);
    const [ refData, setRefData ] = useState(null);
    const [ isUpdating, setIsUpdating ] = useState(false);
    const [ newCode, setNewCode ] = useState(undefined);
    const [ needReload, setNeedReload ] = useState(true);

    useEffect(() => {

        const getRefInfo = async () => {
            let refInfo = await getReferralCode(infoAccount.account);

            if(refInfo.code === null) {
                await insertOrUpdateReferral(infoAccount.account, (infoAccount.account).substr(0,8)+(infoAccount.account).substr(35,43));
                refInfo = await getReferralCode(infoAccount.account);
            } 

            setRefCode(refInfo.code);
            setRefAmount(refInfo.count);
            const datos = await getReferralInfo(infoAccount.account);
            const cleanDatos = tratamientoRefInfo(datos);
            setRefData(cleanDatos);
            setNeedReload(false);
            
        }

        infoAccount.account && needReload && getRefInfo();
    }, [infoAccount.account, needReload])

    const tratamientoRefInfo = (auxRefInfo) => {
        let clearInfo = [];

        auxRefInfo.forEach(element => {
            const { wallet, buy_amount, used, creation_date, modification_date } = element;
            let aux = {
                "Buyer": (wallet).substr(0,5)+"....."+(wallet).substr(38,43),
                "Purchased amount": buy_amount,
                "Date of purchase": creation_date,
                "Can claim?": buy_amount >= 3 ? "Yes" : "Need more capsules",
                "It is claimed?": used === 0 ? "False" : "True",
                "Claim date" : used === 0 ? "Not claimed" : modification_date
            };
            
            clearInfo.push(aux)
        });

        return clearInfo;
    }

    const toggleIsUpdating = (toggle) => {

        if(!toggle && newCode !== undefined && newCode !== "") {
            confirmAlert(newCode);
        }

        setIsUpdating(toggle);
    }

    const handleChangeCode = (event) => {
        const auxCode = event.target.value;
        setNewCode(auxCode);
    }

    const confirmAlert = () => {
        Swal.fire({
            title: "Are you sure?",
            html: "You are going to change your referral code to <strong>" + newCode + "</strong><br/>",
            icon: "question",
            showConfirmButton: true,
            confirmButtonText: 'Yes!',
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showCancelButton: true,
            denyButtonText: 'Cancel',
            footer: 'Accept to continue.',
        }).then(async (result) => {
            if(result.isConfirmed){
                const respuesta = await insertOrUpdateReferral(infoAccount.account, newCode);
                console.log(respuesta);
                if(respuesta !== true) {
                    Swal.fire({
                        title: "Transaction failed!",
                        html: '<b>' + respuesta +'</b>',
                        icon: "error",
                        showCloseButton: true,
                        showConfirmButton: false,
                        width: "50rem",
                        background: "#131312",
                        color: "#FFF",
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                    });
                }
                setNewCode(undefined);
                setNeedReload(true);
            }
        });
    }

    const toastConfig = {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(refCode);
        toast.info("Copied to clipboard!", toastConfig);
    }

    const handleCopyUrl = () => {
        let finalMsgToCopy = "https://app.darkearth.gg?refCode="+refCode; 
        navigator.clipboard.writeText(finalMsgToCopy);
        toast.info("Copied to clipboard!", toastConfig);
    }
    
    return (
        <Fragment>
            <main>
            { !isConnected ?
                <NoConectado/>
            :
                <Container>
                    { showDetails && <CenterLogo/>}
                    <Card className="mx-auto pb-4">
                        <h1 className="text-white text-center fw-bold pb-2 pt-4">REFERRALS</h1>
                        <Row>
                            <p className="mx-4 mb-4 text-center bg-light w-50 mx-auto rounded">
                                <small>
                                    <strong>Prize for REFERRER:</strong> US$5 per each referred that buys a minimum of 3 Mystery Capsules.<br/>
                                    <strong>Prize for REFERRED:</strong> Buys 3 MCs and receives 1 MC for free, which totals 4 MCs.
                                </small>
                            </p>
                        </Row>
                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                            <Row className="pb-2 mx-auto w-75">
                                <div className={classes.efecto} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <CardGroup bg="black">
                                        <CardB className={classes.boxStyle}>
                                            <CardB.Body>
                                                <CardB.Title className="text-center fw-bold">YOUR CODE</CardB.Title>
                                                <CardB.Text>
                                                    {!isUpdating ?
                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip-2">Click to copy!</Tooltip>}>
                                                            <button className="btn btn-outline-light w-100" onClick={() => {handleCopyCode()}}>{refCode}</button>
                                                        </OverlayTrigger>
                                                    :
                                                        <input placeholder="YOUR NEW PROMO CODE" className="btn btn-warning w-100" onChange={handleChangeCode}/>
                                                    }
                                                </CardB.Text>
                                            </CardB.Body>
                                        </CardB>
                                        <CardB className={classes.boxStyle}>
                                            <CardB.Body>
                                                <CardB.Title className="text-center fw-bold">YOUR ARMY</CardB.Title>
                                                <CardB.Text>
                                                    <button className="btn btn-outline-light w-100">{refAmount} WARRIORs</button>
                                                </CardB.Text>
                                            </CardB.Body>
                                        </CardB>
                                        { !showDetails && 
                                        <CardB className={classes.boxStyle}>
                                            <CardB.Body>
                                                <CardB.Title className="text-center fw-bold">EDIT</CardB.Title>
                                                <CardB.Text>
                                                    <button className="btn btn-outline-success w-100 fw-bolder" onClick={() => navigate("/referrals")}>ADVANCED VIEW</button>
                                                </CardB.Text>
                                            </CardB.Body>
                                        </CardB>
                                        }
                                    </CardGroup>
                                </div>
                            </Row>
                            {showDetails && 
                                <Row className="d-flex justify-content-center">
                                    <button className={isUpdating ? "btn btn-success w-25 fw-bold p-2 mt-2 mx-2 text-center" : "btn btn-success w-25 p-2 mt-2 mx-2 text-center" } onClick={() => toggleIsUpdating(!isUpdating)}>
                                        {isUpdating ? "SAVE NEW INFO" : "UPDATE INFO"}
                                    </button>
                                    <button className={"btn btn-success w-25 p-2 mt-2 mx-2 text-center"} onClick={() => handleCopyUrl()}>
                                        COPY URL TO CLIPBOARD
                                    </button>
                                </Row>
                            }
                        </Row>
                        { (showDetails && refData !== null && refData.length !== 0) &&
                            <Row>
                                <div className="mt-4 mx-auto bg-light rounded mb-4 mt-4 w-75">
                                    <h3 className="text-center fw-bold pb-4 pt-4">YOUR ARMY PURCHASES</h3>
                                    <JsonToTable json={refData}/>
                                </div>
                            </Row>
                        }
                    </Card>
                </Container>
            }
            </main>
        </Fragment>
    );
}

export default Referrals;