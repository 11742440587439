import Swal from "sweetalert2";

const InfoAlertView = ({title, text, ms}) => {
    let auxAlert = {
        title: title,
        text: text,
        icon: "info",
        width: "50rem",
        background: "#131312",
        color: "#FFF",
        timer: ms || 0,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading();
        }
    }
    Swal.fire(auxAlert);
}

export default InfoAlertView;