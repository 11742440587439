import { Col, Row, Container, Image } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import HoverVideoPlayer from 'react-hover-video-player';

import ProgressiveImg from "../ProgressiveImage/ProgressiveImage";
import { actualLoader } from "../../../Services/Loader/LoaderFunctions";
import ConstantesAPI from "../../../../utils/API/Constantes";
import classes from "./DetailCard.module.css";
import { Animated } from "react-animated-css";
import { Fragment } from "react";

import { deviceIsMobile as isMobile } from "../../../../App";

const defaultInfoCardPreMint = {
    faccion: "Askari",
    id: 15,
    idCard: 15,
    mintType: "Steel",
    nombre: "Nisal Den",
    rareza: "Basic",
    supply: 5000,
    tipo: "Support",
    tokenID: "Loading..."
}

const getNFTCardJson = ({infoCard, defaultVideo, defaultImage}) => {
    return infoCard;
}

const getCardJson = ({infoCard, defaultVideo, defaultImage, urlCardServer}) => {
    return getNFTCardJson({infoCard, defaultVideo, defaultImage});
}

const getTokenInfoDetailed = (cardJson) => {
    const tokenIds = cardJson.tokensID;
    const tokenSerials = cardJson.tokenSerials;
    const isUsed = cardJson.tokensIDused;

    let showTokenInfo = [];

    for (let index = 0; index < tokenIds.length; index++) {
        const tokenId = tokenIds[index];
        const tokenSerial = tokenSerials[index];
        const tokenUsed = isUsed[index];
        
        const objeto = <tr key={tokenId}><td>{tokenId}</td><td>{tokenSerial}</td><td>{tokenUsed ? "Used" : "Not used"}</td></tr>;
        showTokenInfo.push(objeto);
    }

    return showTokenInfo;
}

const getRarityItem = (rarity) => {

    if(rarity === "Basic") {
        return <Image fluid src="images/details/rarity/basic.png" className="w-50"/>
    } else if(rarity === "Rare") {
        return <Image fluid src="images/details/rarity/rare.png" className="w-50"/>
    } else if(rarity === "Epic") {
        return <Image fluid src="images/details/rarity/epic.png" className="w-50"/>
    } else if(rarity === "Legendary") {
        return <Image fluid src="images/details/rarity/legendary.png" className="w-50"/>
    } else if(rarity === "Special") {
        return <Image fluid src="images/details/rarity/special.png" className="w-50"/>
    }

}

const getMintTypeItem = (mint) => {

    if(mint === "Steel") {
        return <Image fluid src="images/details/mint/steel.png" className="w-50"/>
    } else if(mint === "Titanium") {
        return <Image fluid src="images/details/mint/titanium.png" className="w-50"/>
    } else if(mint === "Cosmium") {
        return <Image fluid src="images/details/mint/cosmium.png" className="w-50"/>
    }
}


const DetailCardView = ({
    infoCard =  defaultInfoCardPreMint,
    defaultImage = ConstantesAPI().DEFAULT_CARD_IMAGE,
    defaultVideo = actualLoader,
    urlCardServer = ConstantesAPI().RUTA_CARDS_MEDIA,
    handleToggleDetailCardView = (infoCard)=>{}
}) => {
    
    const cardJson = getCardJson({infoCard, defaultImage, defaultVideo, urlCardServer});

    return (
            <Container fluid>
                <div className={classes.container_DetailCard}>
                    <Animated  className={classes.cardInfoRow} animationIn="fadeIn" animationInDelay={0} animationOut="fadeOut" isVisible={true}>
                        <div className={classes.container_background}>
                        <Row>
                            <Col xs={12} md={4}>
                                {cardJson.isVideo?
                                    <HoverVideoPlayer
                                        videoSrc={cardJson.assetUrl}
                                        preload="auto"
                                        restartOnPaused = "true"
                                        volume={0.5}
                                        muted={false}
                                        className={classes.cardImage}
                                        loadingOverlay={
                                            <Spinner animation="border" role="status" variant="light">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        }
                                    />
                                    :
                                    <ProgressiveImg className={classes.cardImage} src={cardJson.assetUrl} placeholderSrc={defaultImage} alt="Dark Earth Card"/>
                                }
                            </Col>

                            <Col xs={12} md={8} className={classes.descriptionBox_DetailCard}>
                                <Row>
                                        <div style={!isMobile ? {fontSize: "4vw", paddingRight: "5rem"} : {fontSize: "2rem"}}>
                                            <p className={!isMobile ? "mb-2 fw-bolder text-uppercase" : "m-4 text-center fw-bolder text-uppercase"}>{cardJson.name}</p>
                                        </div>
                                        <span className={isMobile ? "w-100 mx-auto text-center" : "w-50"}>
                                            {getRarityItem(cardJson.rarity)}
                                            {getMintTypeItem(cardJson.mintType)}
                                        </span>
                                        {cardJson.description && <p className="text-white mb-4 mt-4">{cardJson.description}</p> }
                                </Row>
                                <div style={!isMobile ? {fontSize: "4vw", paddingRight: "5rem"} : {fontSize: "2rem"}}>
                                    <p className={!isMobile ? "fw-bold pt-3" : "fw-bold pt-3 text-center"}>NFT DATA</p>
                                </div>
                                <Row className={classes.metadataRow}>
                                    <Col>
                                    <p className="fw-bolder">Faction</p>
                                        <p>{cardJson.faction}</p>

                                    <p className="fw-bolder">Type</p>
                                        <p>{cardJson.type}</p>

                                    </Col>
                                    <Col>
                                        <p className="fw-bolder">Ilustrator</p>
                                            <p>{cardJson.ilustrator}</p>
                                        
                                        {cardJson.animator && 
                                        <Fragment>
                                            <p className="fw-bolder">Animator</p>
                                            <p>{cardJson.animator}</p>
                                        </Fragment>
                                        }
                                    </Col>
                                    <Col>
                                        { /* ESTÁ EN EL ELEMENTO SUPERIOR POR QUE LAS PRE-MINTED TAMPOCO TIENEN USED */
                                        (cardJson.tokenSerials && cardJson.tokenSerials.length > 0) && 
                                        <Fragment>
                                            <div className={classes.tokenSerials}>
                                                <table className={classes.tokenInfoData}>
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>SERIAL</th>
                                                            <th>Is it used?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {getTokenInfoDetailed(cardJson)}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Fragment>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className={classes.buttonClose}>
                            <button type="button" className={!isMobile ? "btn btn-warning" : "btn btn-warning mt-4"} onClick={()=>handleToggleDetailCardView(infoCard)}>
                                <span className="p-3 fw-bolder">Click to close</span>
                            </button>
                        </div>
                    </div>
                    </Animated>
                </div>
                
            </Container>
    );
}

export default DetailCardView;

/* InfoCard
faccion: "Askari"
id: 15
idCard: 15
mintType: "Steel"
nombre: "Nisal Den"
rareza: "Basic"
supply: 5000
tipo: "Support"
*/

/*
const getFactionItem = (faction) => {

    if(faction === "Terrans") {
        return <Image fluid src="images/details/faction/terran.png" className="w-50" />
    } else if(faction === "Bioshara") {
        return <Image fluid src="images/details/faction/bioshara.png" className="w-50" />
    } else if(faction === "Gladios") {
        return <Image fluid src="images/details/faction/gladios.png" className="w-50" />
    } else if(faction === "Musktars") {
        return <Image fluid src="images/details/faction/musktar.png" className="w-50" />
    } else if(faction === "Askari") {
        return <Image fluid src="images/details/faction/askari.png" className="w-50" />
    }

}

const getTypeItem = (type) => {

    console.log(type);
    if(type === "Unit") {
        return <Image fluid src="images/details/types/unit.png" className="w-50" />
    } else if(type === "Equipment") {
        return <Image fluid src="images/details/types/equipment.png" className="w-50" />
    } else if(type === "Support") {
        return <Image fluid src="images/details/types/support.png" className="w-50" />
    } else if(type === "Tactic") {
        return <Image fluid src="images/details/types/tactic.png" className="w-50" />
    } else if(type === "Reward") {
        return <Image fluid src="images/details/types/reward.png" className="w-50" />
    }

}

const getSignIlustrator = (ilustrator) => {
    if(ilustrator.includes("Zayas")) {
        return <Image fluid title="Rodrigo Zayas" src="images/firmasArte/Zayas.png" className="w-50"/>
    } else if(ilustrator.includes("Guillermo")) {
        return <Image fluid title="Guillermo Berdugo" src="images/firmasArte/Guillermo.png" className="w-50"/>
    } else if(ilustrator.includes("Hernán")) {
        return <Image fluid title="Hernán Dvojak" src="images/firmasArte/Hernan.png" className="w-50"/>
    } else if(ilustrator.includes("Jana")) {
        return <Image fluid title="Jana Domínguez" src="images/firmasArte/Jana.png" className="w-50"/>
    } else if(ilustrator.includes("Jesús")) {
        return <Image fluid title="Jesús Gonzalo" src="images/firmasArte/Jesus.png" className="w-50"/>
    } else if(ilustrator.includes("Rubén")) {
        return <Image fluid title="Rubén Megía" src="images/firmasArte/Ruben.png" className="w-50"/>
    }
}

const getSignAnimator = (animator) => {
    if(animator.includes("Carlos")) {
        return <Image fluid title="Carlos López" src="images/firmasArte/Carlos.png" className="w-50"/>
    } else if(animator.includes("Hernán")) {
        return <Image fluid title="Hernán Dvojak" src="images/firmasArte/Hernan.png" className="w-50"/>
    }
}
*/



/*
// GETNFTCARDJSON -> FUNCION

    
    //atributos
    const name = infoCard.name;
    const serials = infoCard.tokenSerials || [];
    const faction = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Faction').value : 'None';
    const mintType = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Mint type').value : 'Special';
    const rarity = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Rarity').value : 'Special';
    const description = infoCard.description;
    // ---------------
    const ilustrador = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Ilustrator').value : 'Not found';
    const auxUsada = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Used').value : 'Not found';
    const edition = infoCard.attributes.length > 4? infoCard.attributes.find( atribute => atribute.trait_type === 'Edition').value : 'Not found';
    // ---------------
    var foundAnimator = false;
    var auxAnimator = null;

    try {
        auxAnimator = infoCard.attributes.find( atribute => atribute.trait_type === 'Animator').value;
        foundAnimator = true;
    } catch(error) {
        foundAnimator = false;
    }
    
    const animador = foundAnimator !== false ? auxAnimator : false;
    const usada = auxUsada ? "Used" : "Not used";

    // --------------
    
    const type = infoCard.attributes.find( atribute => atribute.trait_type === 'Type').value;
    
    //video or animation
    const isVideo = infoCard.hasOwnProperty('animation_url')? true : false;
    const isImage = infoCard.hasOwnProperty('image')? true : false;
    
    const assetUrl = (isVideo && infoCard.animation_url ) || (isImage && infoCard.image);

    return {
        idCard: infoCard.idCard,
        name: name,
        type: type,
        faction: faction,
        mintType: mintType,
        rarity: rarity,
        description: description,
        assetUrl: assetUrl,
        isVideo: isVideo,
        isImage: isImage,
        ilustrator: ilustrador,
        animator: animador,
        used: usada,
        edition: edition,
        tokenID: infoCard.tokenID,
        tokenSerials: serials
    }
*/