import React, { Fragment } from "react";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import BuyZone from "../../components/UI/pages/BuyZone/BuyZone";
import NoDisponible from "../NoDisponible/NoDisponible";

const BuyCapsules = (props) => {

    const { account, isConnected, mintDetails } = props.infoAccount;
    const activado = props.activo;

    return (
        <Fragment>
            <main>

                { !isConnected ?
                    <NoConectado/>

                    :

                    activado ?
                        <BuyZone
                            reloadUserDetails = { props.reloadUserDetails }
                            canConnectToContract = { props.canConnectToContract }
                            web3Api = { props.web3Api }
                            account = { account }
                            mintDetails = { mintDetails }
                        />
                    :
                        <NoDisponible/>
                }

            </main>
        </Fragment>
  );

}

export default BuyCapsules;