import { useState, useLayoutEffect } from "react";
import debounce from 'lodash/debounce';

export default function useDetectMobile() {
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  useLayoutEffect(() => {
    const updateSize = () => {
      setMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    // updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { isMobile };
}