//GET VIEW OF ALL CARD COMING FROM FETCH
const getAllViewCards = ({ clearInfoCards = [], defaultImage, defaultVideo, urlCardServer = "" }) => {
    let auxAllViewCards = [];

    for (let index = 0; index < clearInfoCards.length; index++) {
        
        const clearInfoCard = clearInfoCards[index];

        const isFinded = auxAllViewCards.find(viewCard => viewCard.infoCard.id === clearInfoCard.id);
        
        if(isFinded){

            isFinded.infoCard.counterTypes += 1;

        }else{

            let auxInfoCard = {counterTypes: 1, ...clearInfoCard };

            auxAllViewCards.push(
                {
                    infoCard: auxInfoCard,
                    defaultImage: defaultImage,//ConstantesAPI.DEFAULT_CARD_IMAGE, 
                    defaultVideo: defaultVideo,//'videos/DH_MKT_34_1x1_alpha5_VP9.webm'
                    urlCardServer: urlCardServer//ConstantesAPI.RUTA_CARDS_MEDIA
                });
        }        
    }

    return auxAllViewCards;
}

export const ServerCards = async ({ account, urlAPIServer, urlCardServer, defaultImage, defaultVideo }) => {
    //ConstantesAPI.RUTA_API
    const dirtyInfoCards = await fetch(`${urlAPIServer}/obtener_Cards.php?id=${account}`);
    const clearInfoCards = await dirtyInfoCards.json();

    const arrayCardViewJSON = getAllViewCards({ clearInfoCards, defaultImage, defaultVideo, urlCardServer });

    return [clearInfoCards, arrayCardViewJSON];
}








//GET NFT TOKEN CARDS FROM SC
/*
export const NFTCards = async ({ account, collections, defaultImage, defaultVideo }) => {

    const { userTokensIds, userTokensTypes } = useLoadWalletsDataFromDB();
    const arrayTokenID = userTokensIds;
    const arrayTokenType = userTokensTypes;
    const clearInfoCards = arrayTokenType.map((tokenType) => { return "https://nft-hub.darkearth.gg/cards/"+tokenType+".json"});

    //const arrayTokenInfo = await getUserTokenInfo();
    //const arrayTokenID = arrayTokenInfo.flatMap((tokenInfo)=> tokenInfo.tokenId);
    //const arrayTokenType = arrayTokenInfo.flatMap((tokenInfo)=> tokenInfo.tokenType);

    const arrayCardViewJSON = getAllViewCards({ clearInfoCards, arrayTokenID, arrayTokenType, defaultImage, defaultVideo });

    return [clearInfoCards, arrayCardViewJSON]
}
*/