import { useEffect, useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import Table from 'react-bootstrap/Table';
import { Animated } from "react-animated-css";


import CardView from "../../Elements/Card/CardView";
import Paginator from "../../Elements/Paginator/Paginator";
import ConstantesAPI from "../../../../utils/API/Constantes";
import { getStakingReward } from "../../../../utils/API/ApiFunctions";

const StakeV1 = (props) => {

    const { stakingDetails, account } = props.infoAccount;
    const { stakedMasterArt, stakedFactionBanner, stakedKeyArt, stakedInfo } = stakingDetails;

    const { contracts } = props.web3Api;
    const { DEStaking, DECollection } = contracts;

    const useLoad = props.useLoad;
    const collection = props.collection;

    const { tokenSync, userTokensIds, userTokensTypes, userTokenIdGenerales, haveDesync } = useLoad;

    //const [ cleanTokens, setCleanTokens ] = useState([]);
    const [ nftView, setNftView ] = useState([]);
    const [ needReload, setNeedReload ] = useState(true);
    const [ showStaked, setShowStaked ] = useState(false);
    const [ actualReward, setActualReward ] = useState(0);
    const [ isApproved, setIsApproved ] = useState(false);

    useEffect(() => {
        const loadInfo = async () => {
            //let auxInfo = [];
            let filterCollection = [];
            for (let index = 0; index < userTokensIds.length; index++) {
                if(userTokenIdGenerales[index] >= 41) {

                    const encontrado = collection.filter(o1 => o1.index === userTokensTypes[index]);

                    filterCollection.push({
                        infoCard: {tokenId: userTokensIds[index],...encontrado[0]}
                    });
                }
            }

            const _actualReward = await getStakingReward(account);
            setActualReward(_actualReward.acumulado || 0);

            const _isApproved = await DECollection.isApprovedForAll.call(account, DEStaking.address);
            setIsApproved(_isApproved);

            setNftView(filterCollection);
            setNeedReload(false);
        }

        tokenSync && needReload && loadInfo();
    }, [tokenSync, userTokensIds, userTokensTypes, userTokenIdGenerales, needReload, collection, stakingDetails, account, DECollection, DEStaking])

    const confirmStakingAlert = (infoCard) => {
        Swal.fire({
            title: "Are you sure?",
            html: "You are going to staking your NFT with ID <strong>" + infoCard.tokenId + "</strong><br/>",
            icon: "question",
            showConfirmButton: true,
            confirmButtonText: 'Yes!',
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showCancelButton: true,
            denyButtonText: 'Cancel',
            footer: 'Accept to continue.',
        }).then((r) => {
            if(r.isConfirmed) handleStake(infoCard.tokenId);
        })
    }

    const confirmUnstakingAlert = (tokenId) => {
        Swal.fire({
            title: "Are you sure?",
            html: "You are going to unstake your NFT with ID <strong>" + tokenId + "</strong><br/>",
            icon: "question",
            showConfirmButton: true,
            confirmButtonText: 'Yes!',
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            showCancelButton: true,
            denyButtonText: 'Cancel',
            footer: 'Accept to continue.',
        }).then((r) => {
            if(r.isConfirmed) handleUnstake(tokenId);
        })
    }

    const errorAlert = (error) => {
        var mensaje = null;
        if(error.includes("SIGNATURE ERROR: What are you trying to do?")){
            mensaje = "SIGNATURE ERROR: What are you trying to do?";
        } else if(error.includes("LENGTH ERROR: Data malformed")){
            mensaje = "LENGTH ERROR: Data malformed";
        } else if(error.includes("ERROR: This transaction is already in our system.")) {
            mensaje = "ERROR: This transaction is already in our system.";
        } else if(error.includes("gas required exceeds allowance")) {
            mensaje = "ERROR: Gas required esceeds allowance.";
        } else if(error.includes("insufficient funds for gas * price + value")){
            mensaje = "ERROR: You do not have enough MATIC to complete the transaction.";
        } else if(error.includes("ERC721: transfer from incorrect owner")) {
            mensaje = "ERROR: This NFT is locked. Refresh the page.";
        } else {
            mensaje = error;
        }
        Swal.fire({
            title: "Transaction failed!",
            html: '<b>' + mensaje +'</b>',
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            width: "50rem",
            background: "#131312",
            color: "#FFF",
            cancelButtonText: 'Close',
        });
    }

    const successAlert = (txId) => {
        Swal.fire({
            title: "Transaction completed!",
            html:
                'The transaction has been confirmed. <br/>'+
                '<div class="alert alert-primary" role="alert">Transaction Hash<br/><br/><b>' + txId + '</b></div><br/><br/>' + 
                '<a class="btn btn-primary" target="_blank" href="'+ ConstantesAPI().NETWORK_SCANTX + txId + '">View on Block Explorer</a>',
            icon: "success",
            showConfirmButton: false,
            confirmButtonText: "Next mint",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Close",
            width: "60rem",
            background: "#131312",
            color: "#FFF",
        }).then((result) => {
            if(result.isDenied || result.isDismissed || result.dismiss) setNeedReload(true);
        });
    }

    // Request in process
    const infoAlert = () => {
        Swal.fire({
            title: "Request in process",
            text: "Accept the transaction in your wallet to continue.",
            icon: "info",
            width: "50rem",
                    background: "#131312",
                    color: "#FFF",
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }
    
    const handleStake = async (tokenId) => {

        if(!isApproved) await handleApproveStaking();
        
        var peticionOk = false;
                
        try {
            await DEStaking.stake.call(tokenId, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((r) => {
                peticionOk = true;
            }).catch((e) => {
                errorAlert(e.message);
                setNeedReload(true);
            })
        } catch(error){
            errorAlert(error.message);
            setNeedReload(true);
        }

        if(peticionOk) {
            infoAlert();
            /*
            const checkId = await consensusAddUnverified(account, "NFTs Minted", JSON.stringify(txIds));
            if(!checkId) {
                errorAlert("You already have a transaction in process.<br/> Wait for the blockchain to finish and refresh the page.");
                return;
            }
            */

            await DEStaking.stake(tokenId, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((result) => {
                successAlert(result.tx);
                haveDesync();
                setNeedReload(true);
            }).catch((e) => {
               errorAlert(e.message);
            }).finally(() => {
                setNeedReload(true);
            });

        }
    }

    const handleUnstake = async (tokenId) => {
        var peticionOk = false;
                
        try {
            await DEStaking.unstake.call(tokenId, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((r) => {
                peticionOk = true;
            }).catch((e) => {
                errorAlert(e.message);
                setNeedReload(true);
            })
        } catch(error){
            errorAlert(error.message);
            setNeedReload(true);
        }

        if(peticionOk) {
            infoAlert();
            await DEStaking.unstake(tokenId, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((result) => {
                successAlert(result.tx);
                setNeedReload(true);
            }).catch((e) => {
               errorAlert(e.message);
            }).finally(() => {
                setNeedReload(true);
            });
        }
    }

    const handleApproveStaking = async () => {
        var peticionOk = false;
                
        try {
            await DECollection.setApprovalForAll.call(DEStaking.address, true, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((r) => {
                peticionOk = true;
            }).catch((e) => {
                errorAlert(e.message);
                setNeedReload(true);
            })
        } catch(error){
            errorAlert(error.message);
            setNeedReload(true);
        }

        if(peticionOk) {
            infoAlert();
            await DECollection.setApprovalForAll(DEStaking.address, true, {
                from: account,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null
            }).then((result) => {
                successAlert(result.tx);
                //setNeedReload(true);
            }).catch((e) => {
               errorAlert(e.message);
            }).finally(() => {
                setNeedReload(true);
            });
        }
    }

    const dameTipoDeNFT = (id) => {
        if(id >= 94 && id <= 133) {
            return "Master Art";
        } else if(id >= 134 && id <= 138) {
            return "Faction Banner";
        } else if(id === 139) {
            return "Key Art";
        } else {
            return "Unknown, contact support!"
        }
    }
    
    return (
        
        <Container fluid>
            <Container>
                <Row>
                    <h1 className="text-white text-center mb-4 fw-bold">STAKING</h1>
                </Row>
                <Row>
                    <Col>
                        <Card bg="light" className="text-center" border="light">
                            <Card.Body>
                                <Card.Title>Staked Master Art</Card.Title>
                                <Card.Text className="display-6 fw-bold">
                                    {stakedMasterArt.length}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card bg="light" className="text-center" border="light">
                            <Card.Body>
                                <Card.Title>Staked Faction banner</Card.Title>
                                <Card.Text className="display-6 fw-bold">
                                    {stakedFactionBanner.length}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card bg="light" className="text-center" border="light">
                            <Card.Body>
                                <Card.Title>Staked Key Art</Card.Title>
                                <Card.Text className="display-6 fw-bold">
                                    {stakedKeyArt.length}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={4}>
                        <Card bg="light" className="text-center" border="light">
                            <Card.Body>
                                <Card.Title>Acumulated ($CSU)</Card.Title>
                                <Card.Text className="display-6 fw-bold">
                                    {actualReward}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
            <Row>
                <Col>
                    <Animated animationIn="zoomIn" animationInDelay={300} animationOut="fadeOut" isVisible={showStaked} style={{display: !showStaked ? "none" : "inline"}}>
                        <h2 className="text-white text-center mt-4 pt-4 fw-bold">STAKED NFTs</h2>
                    </Animated>
                    <Animated animationIn="zoomIn" animationInDelay={300} animationOut="fadeOut" isVisible={!showStaked} style={{display: showStaked ? "none" : "inline"}}>
                        <h2 className="text-white text-center mt-4 pt-4 fw-bold">NFTs SUITABLE FOR STAKING</h2>
                    </Animated>
                </Col>
            </Row>

            <Row>
                {isApproved ? 
                <Col xs={3} className="mx-auto m-4 text-center">
                    <button className='btn btn-outline-light w-100 p-4 fw-bolder' onClick={() => setShowStaked(!showStaked)}>{!showStaked ? "SHOW STAKED NFTs" : "SHOW NFTs SUITABLE FOR STAKING."}</button>
                </Col>
                :
                <Col xs={4} className="mx-auto m-4 text-center">
                    <button className='btn btn-warning w-100 p-4 fw-bolder' onClick={() => handleApproveStaking()}>NEED APPROVE STAKING</button>
                </Col>
                }
            </Row>

            <Row>
                { showStaked ?
                    stakedInfo.length === 0 ?
                        <Col>
                            <h4 className="text-white text-center mt-4 pt-4 pb-4 mb-4">You dont have NFTs in Staking</h4>
                            <h6 className="text-white text-center mt-4 pt-4 pb-4 mb-4 fw-bold">You have problems?<br/> Create a ticket in our discord.</h6>
                        </Col>
                        
                    :
                    <Col xs={9} className="mx-auto text-center">
                        <Animated animationIn="fadeIn" animationInDelay={600} animationOut="fadeOut" isVisible={showStaked} style={{display: !showStaked ? "none" : "inline"}}>
                            <Table bordered hover striped variant="dark">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Type</th>
                                        <th>Timestamp</th>
                                        <th>OPTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stakedInfo.map((e) => {
                                        const fechaTratada = new Date(e[2]*1000);
                                        return(
                                            <tr>
                                                <th>{e[0]}</th>
                                                <th>{dameTipoDeNFT(e[1])}</th>
                                                <th>{fechaTratada.toLocaleDateString("en-US")}</th>
                                                <th><button className='btn btn-light w-100 fw-bolder' onClick={() => confirmUnstakingAlert(e[0])}>UNSTAKE</button></th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Animated>
                    </Col>
                    
                :
                    <Paginator arrayViewItems={nftView} countItemsPerPage={8} handleElementView={CardView} handleElementViewOnClick={confirmStakingAlert}/>
                }
            </Row>
        </Container>
    );

}
export default StakeV1;