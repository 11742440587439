import ConstantesAPI from "../../../utils/API/Constantes";

export const SendSaveOpenCapsulesToServerAPICapsules = async ({account, tx, count}) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        tx: tx,
        type: "Open Capsules",
        amount: count
    }

    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    //mejorar sistema de captura de errores

    try {
        await fetch(`${ConstantesAPI().RUTA_API}/guardar_tx.php`, {
            method: "POST",
            body: cargaUtil,
        });
        return true;
    } catch (error) {
        return false;
    }

    //const exitoso = await respuesta.json();
}

/**
 * 
 * @param {*} account dirección de wallet
 * @param tx
 * @param idCaps contiene array de las ids de las capsulas que se abriran.
 * @returns {[{Card1_Properties}, ... {CardN_Properties}] } 
 */
export const CreateMintToServerAPICapsules = async ({account, tx, idCaps}) => {

    // Codificar nuestro videojuego como JSON
    const datos = {
        wallet: account,
        tx: tx,
        idCaps: idCaps
    }
    
    const cargaUtil = JSON.stringify(datos);

    // ¡Y enviarlo!
    const response = await fetch(`${ConstantesAPI().RUTA_API}/crea_Minteo.php`, {
        method: "POST",
        body: cargaUtil,
    });

    //mejorar sistema de captura de errores
    try {
        const dirtyOpenedCapsuleCards = await response.json();
        const cleanOpenedCapsuleCards = CleanArrayCapsuleCardsFromServer(dirtyOpenedCapsuleCards);
        return cleanOpenedCapsuleCards;
    } catch (error) {
        return error;
    }
}

/**
 * 
 * @param {*} arrayDirtyOpenedCapsuleCards array que contiene array de las cartas obtenidas en cada capsula.
 * @returns {[{Card1_Properties}, ... {CardN_Properties}] } 
 */
const CleanArrayCapsuleCardsFromServer = (arrayDirtyOpenedCapsuleCards = []) => {

    let auxArrayOpenedCapsuleCards = [];
    
    for (const dirtyOpenedCapsuleCards of arrayDirtyOpenedCapsuleCards) {
        auxArrayOpenedCapsuleCards = auxArrayOpenedCapsuleCards.concat(CleanCapsuleCardsFromServer(dirtyOpenedCapsuleCards));
    }

    return auxArrayOpenedCapsuleCards
    
}

const CleanCapsuleCardsFromServer = (dirtyOpenedCapsuleCards) => {

    return dirtyOpenedCapsuleCards.flatMap((card)=>{

        const auxIsVideo = card.mintType === "Steel" ? false : true;
        const cardExtension = card.mintType === "Steel" ? ".png" : ".mp4";
        const auxSrcCard = ConstantesAPI().RUTA_CARDS_MEDIA + card.id + cardExtension;

        return {
            cargada: 1,                                         // ESTO SOBRA
            faccion: card.faccion,
            id: card.id,
            idCard: card.idCard,
            mintType: card.mintType,
            nombre: card.nombre,
            rareza: card.rareza,
            supply: card.supply,                             // ESTO SOBRA
            tipo: card.tipo,
            srcCard: auxSrcCard,
            isVideo: auxIsVideo                               // ¿Se trata luego la URL de srcCard?
        }

    })
}