// --------------------------------------
// FAST LOADING - TOKEN URL + JSON DATA
// --------------------------------------
import { GlobalVariables } from "../GlobalVariables";
import { GlobalInfoAccount } from "../GlobalInfoAccount";
//import ConstantesAPI from "../API/Constantes";
import { wait } from "@testing-library/user-event/dist/utils";
//import { getAndUpdateWalletsData } from "../API/ApiFunctions";

/*
let fetchingCards = false;
let userJsonData = [];
let cachedTokenInfo = [];
const getURLCards = async () => {
    if(!fetchingCards) {
        try {
            let auxJsonData = []
            
            const allTokenInfo = await getUserTokenInfo();
            let equals = cachedTokenInfo.length === allTokenInfo.length && cachedTokenInfo.every((e, i) => e === allTokenInfo[i]);

            if(!equals){
                cachedTokenInfo = allTokenInfo;
                fetchingCards = true;

                for (let index = 0; index < allTokenInfo.length; index++) {
                    const tokenInfo = allTokenInfo[index];
                    let jsonData, urlCard;

                    if(tokenInfo.isUsed) {
                        urlCard = "https://nft-hub.darkearth.gg/cards/"+tokenInfo.tokenType+"-used.json";
                        jsonData = await getInfoCard(urlCard);
                    } else {
                        urlCard = "https://nft-hub.darkearth.gg/cards/"+tokenInfo.tokenType+".json";
                        jsonData = await getInfoCard(urlCard);
                    }
                    auxJsonData.push(jsonData);
                }

                userJsonData = auxJsonData;
                fetchingCards = false;
            }

        } catch(error) {
            fetchingCards = false;
            //console.log("ERROR in getURLCards -> ", error);
            await wait(5000);
            await getURLCards();
        }
    }
}

const getInfoCard = async (urlCard) => {
    let respuesta;
        await fetch(`${urlCard}`, { mode: 'cors' })
            .then(async (infoCard) => {
                if (infoCard.ok) {
                    respuesta = await infoCard.json();
                }
            });
    return respuesta;
}
*/
// -----------------------------------
// EXPORTAMOS LA INFORMACIÓN OBTENIDA
// -----------------------------------
/*
export const getCachedUserJsonData = async () => {
    await getURLCards();
    while(fetchingCards) {
        await wait(500);
    }
    return userJsonData;
}
*/

/*
export const getCachedUserJsonUrl = async (account) => {
    
    await waitIsUpdated();

    const userInfo = getUserCache(account);
    const cleanJsonData = JSON.parse(userInfo.jsonUrl);
    return cleanJsonData;
}

export const getCachedUserJsonData = async (account) => {
    
    await waitIsUpdated();

    const userInfo = getUserCache(account);
    const cleanJsonData = JSON.parse(userInfo.jsonData);
    return cleanJsonData;
}

const waitIsUpdated = async () => {
    var isUpdated = await checkIsUpdated();
    ////console.log(isUpdated);
    //if(!isUpdated) fastLoadingRun();
    
    while(!isUpdated) {
        //console.log("Espero");
        await wait(500);
        isUpdated  = await checkIsUpdated();
        //console.log(isUpdated);
    }
    //console.log("waitIsUpdated -> Datos actualizados", isUpdated);
}
*/
// --------------------------------------
// Rewards
// --------------------------------------
/*
let tokenSync = false;
let userNotUsedTokens = [];
let userNotUsedTokensTypes = [];
let userTokensTypes = [];
let userTokenInfo = [];

export const loadWalletsDataFromDB = async (fullReload = false) => {
    reloadWalletsDataFromDB();

    const infoAccount = await waitInfoAccount();
    const wallet = infoAccount.account;
    const tokenIds = infoAccount.collectionDetails.cardsIds;

    try {
        const tokenInfo = await getAndUpdateWalletsData(wallet, tokenIds, fullReload);
        userTokenInfo = tokenInfo;

        tokenInfo.forEach(element => {
            if(!element.isUsed) {
                userNotUsedTokens.push(element.tokenId);
                userNotUsedTokensTypes.push(element.tokenType);
            }
            userTokensTypes.push(element.tokenType);
        });

        tokenSync = true;
    }catch(error) {
        //console.log("ERROR in loadWalletsDataFromDB -> ", error);
    }
}

export const getUserTokenInfo = async () => {
    while(!tokenSync) {
        await wait(500);
    }
    return userTokenInfo;
}

export const getUserTokenTypes = async () => {
    while(!tokenSync) {
        await wait(500);
    }
    return userTokensTypes;
}

export const getUserNotUsedTokens = async () => {
    while(!tokenSync) {
        await wait(500);
    }
    return userNotUsedTokens;
}

export const getUserNotUsedTokensTypes = async () => {
    while(!tokenSync) {
        await wait(500);
    }
    return userNotUsedTokensTypes;
}

const reloadWalletsDataFromDB = () => {
    tokenSync = false;
    userNotUsedTokens = [];
    userNotUsedTokensTypes = [];
    userTokensTypes = [];
    userTokenInfo = [];
}
*/
// -----------------------------
// PRINCIPAL
// -----------------------------


export const waitInfoAccount = async () => {
    let infoAccount = GlobalInfoAccount;
    let account = infoAccount.account;
    let nftArray = infoAccount.collectionDetails.cardsIds;
    while(account === null || nftArray === null) {
        //console.log("Fast Loading - InfoAccount - Waiting data...");
        await wait(1000);
        infoAccount = GlobalInfoAccount;
        account = infoAccount.account;
        nftArray = infoAccount.collectionDetails.cardsIds;
    }
    return infoAccount;
}

export const waitWeb3Api = async () => {
    let web3Api = GlobalVariables;
    let DECollection = web3Api.contracts.DECollection;
    while(DECollection === null) {
        //console.log("Fast Loading - Web3API - Waiting data...");
        await wait(1000);
        web3Api = GlobalVariables;
        DECollection = web3Api.contracts.DECollection;
    }
    return web3Api;
}

/*
var runningFastLoading = false;

export const fastLoadingRun = async () => {
    if(!runningFastLoading) {
        runningFastLoading = true;
        //console.log("Fast Loading - Started");
        try {
            await updateCache();
        } catch(error) {
            //console.log("Fast Loading - Failed... Retrying!");
            //console.log("ERROR: ", error);
            await wait(5000);
            fastLoadingRun();
        }
        runningFastLoading = false;
        //console.log("Fast loading - Ended")
    }
}

const updateCache = async () => {
    const isUpdated = await checkIsUpdated();
    if(!isUpdated){
        //console.log("Fast loading - This info is not updated - Updating...");
        await getURLCards();
    }
}

export const removeCache = (account) => {
    const cacheName = getCacheName(account);
    //console.log(cacheName);
    localStorage.removeItem(cacheName);
}

export const reloadAllCache = async (account) => {
    //console.log(account);
    removeCache(account);
    await fastLoadingRun();
}
*/

// -----------------------------
// UTILIDADES
// -----------------------------
/*
const getCacheName = (account) => {
    const subAccount = String(account).substr(0,5)+"_"+String(account).substr(38,43);
    const cacheName = "DECache_" + subAccount;
    return cacheName;
}

const getUserCache = (account) => {
    const cacheName = getCacheName(account);
    const userInfo = JSON.parse(localStorage.getItem(cacheName)) || false;
    return userInfo;
}

const checkIsUpdated = async () => {
    const infoAccount = await waitInfoAccount();
    const account = infoAccount.account;
    const nftArray = infoAccount.collectionDetails.cardsIds;
    
    const cachedInfo = getUserCache(account);

    let respuesta = false;

    if(cachedInfo !== false) {
        if(JSON.stringify(cachedInfo.nftArray) === JSON.stringify(nftArray)) {
            respuesta = true;
        }
    }

    return respuesta;
}
*/
// -------------------------------
// GESTOR DE CACHE PARA USUARIOS
// -------------------------------
/*
const updateUserCache = (account, indice = null, urlJson = null, dataJson = null, arrayNft = null) => {

    if(account !== null) {
        const cacheName = getCacheName(account);
        const isCached = getUserCache(account);
        var userCache;
    
        //console.log("Fast loading - Updating user: ", account);
    
        if(isCached !== false) {
            //console.log("Fast loading - Updating user - isCached");
            userCache = {
                contador: indice !== null ? indice : isCached.contador,
                nftArray: arrayNft !== null ? arrayNft : isCached.nftArray,
                jsonUrl: urlJson !== null ? urlJson : isCached.jsonUrl,
                jsonData: dataJson !== null ? dataJson : isCached.jsonData
            }
        } else {
            //console.log("Fast loading - Updating user - NO isCached")
            userCache = {
                contador: indice,
                nftArray: arrayNft,
                jsonUrl: urlJson,
                jsonData: dataJson
            }
        }
        
        localStorage.setItem(cacheName, JSON.stringify(userCache));
    } else {
        //console.log("Fast Loading - Try save null user");
    }
    
}
*/