import { Fragment } from "react";
import classes from "./CenterLogo.module.css";
import { Image } from "react-bootstrap";

import { deviceIsMobile as isMobile } from "../../../../App";

const CenterLogo = (props) => {
    return (
        <Fragment>
            <div className = { !isMobile ? classes.centradorPc : classes.centrador}>
                <Image fluid src="images/LogoDE.png" alt="Logo Dark Earth" className="pt-5 pb-5 mt-2 mb-2"/>
            </div>
            <div className={classes.espaciador}/>
        </Fragment>
    );
}

export default CenterLogo;