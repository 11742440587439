import ConstantesAPI from "../API/Constantes";
import { CreateMintToServerAPICapsules } from "../../components/Services/Capsules/ServerAPICapsules";
import { SendSaveOpenCapsulesToServerAPICapsules } from "../../components/Services/Capsules/ServerAPICapsules";
import { getBurnedCapsFromDB, getTxFromMint, getScTxMinteos, saveTxMints, saveConsensusMint, getAllRewards, getSpecialMinteos, createMintReward, updateUsedWalletsData } from "../API/ApiFunctions";
import { wait } from "@testing-library/user-event/dist/utils";
import { waitInfoAccount, waitWeb3Api } from "../FastLoading/FastLoading";
// ---------------------------------
//    NUEVO SISTEMA DE CONSENSO
// ---------------------------------

// Añadir una nueva accion sin verificar
export const consensusAddUnverified = async (account, action, details) => {

    const datos = {
        wallet: account,
        action: action,
        details: details
    }

    const cargaUtil = JSON.stringify(datos);
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/consensus_AddUnverified.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();
    return respuesta;
}

// Añadir una nueva accion sin verificar
export const consensusAddCheatUnverified = async (account, action, details) => {

    const datos = {
        wallet: account,
        action: action,
        details: details
    }

    const cargaUtil = JSON.stringify(datos);
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/consensus_AddCheatUnverified.php`, {
        method: "POST",
        body: cargaUtil,
    });

    const respuesta = await peticion.json();
    return respuesta;
}

// Verificar una acción
export const consensusSetVerified = async (account, id, txId = "Cancelled") => {

    const consensusId = parseInt(id);

    const datos = {
        wallet: account,
        id: consensusId,
        txId: txId
    }

    const cargaUtil = JSON.stringify(datos);

    await fetch(`${ConstantesAPI().RUTA_API}/consensus_Verify.php`, {
        method: "POST",
        body: cargaUtil,
    });

}

// Comprobar todas las entradas que no están verificadas
const consensusCheckUnverified = async (wallet) => {
    const peticion = await fetch(`${ConstantesAPI().RUTA_API}/consensus_CheckUnverified.php?wallet=${wallet}`);
    const respuesta = await peticion.json();
    return respuesta;
}

// ----------------------------
// 1ª VALIDACIÓN: CAPSULAS
// ----------------------------

const generateSyncMintCapsules = async (account, desyncCaps, consensusId) => {
    const randomTxDesync = makeDesyncTX(42);
    const capsString = desyncCaps.toString();
    try {
        await SendSaveOpenCapsulesToServerAPICapsules({account, tx: randomTxDesync, count: capsString});
        await CreateMintToServerAPICapsules({account, tx: randomTxDesync, idCaps: desyncCaps});
        await consensusSetVerified(account, consensusId, randomTxDesync);
    } catch(error) {
        //console.log("ERROR AL SINCRONIZAR");
    }
}

const checkSyncBurnedCaps = async (account, burnedCaps) => {
    var auxDesyncCaps = [];
    const burnedCapsDB = await getBurnedCapsFromDB(account);

    for (let index = 0; index < burnedCaps.length; index++) {
        const element = burnedCaps[index];
        
        
        const encontrado = burnedCapsDB.find(e => e === element);
        if(encontrado === undefined) {
            auxDesyncCaps.push(element);
        }
    }

    //console.log("auxDesyncCaps -> ", auxDesyncCaps);

    return auxDesyncCaps;
}

const consensusCheckCapsules = async (infoAccount, transaccion, MysteryCapsule) => {
    //console.log("Entro en consensusCheckCapsules");
    const burnedBlockchainCaps = infoAccount.genesisCapsDetails.burnedCaps;

    let burnedBdCaps = [];
    try {
        burnedBdCaps = JSON.parse(transaccion.details);
    } catch(error) {
        burnedBdCaps = burnedBlockchainCaps;
    }

    const checkBlockchainFunction = (currentValue) => burnedBlockchainCaps.includes(currentValue);
    const itsOnBlockchain = burnedBdCaps.every(checkBlockchainFunction);

    if(itsOnBlockchain) {
        //console.log("PRIMERA VERIFICACION -> Están en blockchain pero no está verificada en Consensus");

        const doubleCheck = await checkSyncBurnedCaps(infoAccount.account, burnedBlockchainCaps);
        //console.log("SEGUNDA VERIFICACION -> ", doubleCheck)

        if(doubleCheck.length === 0) {
            //console.log("El minteo ya estaba creado. Falta validar en Consensus", transaccion.id, burnedBlockchainCaps[0]);
            const txRecolectadas = [];
            for (let index = 0; index < burnedBlockchainCaps.length; index++) {
                const element = burnedBlockchainCaps[index];
                const tx = await getTxFromMint(element, infoAccount.account);
                txRecolectadas.push(tx);
            }
            if(txRecolectadas.length === burnedBlockchainCaps.length) {
                await consensusSetVerified(infoAccount.account, transaccion.id, JSON.stringify("All in sync"));
            } else {
                await consensusSetVerified(infoAccount.account, transaccion.id, JSON.stringify(txRecolectadas));
            }
            
        } else {
            //console.log("Hay capsulas sin sincronizar para la consensusId -> ", transaccion.id);
            await generateSyncMintCapsules(infoAccount.account, doubleCheck, transaccion.id);
        }
        
    } else {
        //console.log("Están en Consensus pero no en blockchain. CANCELADA.")
        await consensusSetVerified(infoAccount.account, transaccion.id);
    }

}

// -------------------------------
// 2ª VALIDACIÓN: NFTs MINTEADOS
// -------------------------------

const consensusCheckNftsMinted = async (account, DECollection, consensusId) => {
    //console.log("Entro en consensusCheckNftsMinted");

    const transTx = await getScTxMinteos(account);
    const allTxs = [];

    for (let index = 0; index < transTx.length; index++) {
        const txId = transTx[index];
        allTxs.push(txId);
    }

    let checkArray = [];

    for (let i = 0; i < allTxs.length; i++) {
        const element = allTxs[i];

        const checkTx = await DECollection.isTxOnSystem.call(element);
        
        if(checkTx) {
            await saveTxMints(account, element, "CONSENSUS CARDS");
            const splited = element.split("-");
            await saveConsensusMint(account, splited[0], splited[1]);
            checkArray.push(splited[0]);
        }
    }

    let check = checkArray.length === 0 ? "Cancelled" : "Synchronized transactions: " + JSON.stringify(checkArray);
    await consensusSetVerified(account, consensusId, check);

}

const checkRewardMatch = async (cleanTokenTypes, account) => {
    const allRewards = await getAllRewards();
            
    var encontrado = false;
    var contador = 0;
    //console.log("consensusCheckRewards - Searching on Reward");

    while(!encontrado && contador < allRewards.length) {
        const reward = allRewards[contador];

        if(reward.length === cleanTokenTypes.length) {
            const checkSameReward = (element) => reward.includes(element);
            const isThisReward = cleanTokenTypes.every(checkSameReward);
            if(isThisReward) encontrado = true;
        }
        
        contador++;
    }

    const datos = {
        found: encontrado,
        rewardIndex: contador
    }

    return datos;
}

const checkMintRewardOnBD = async (account, rewardIndex) => {
    var respuesta = null;
    const allMints = await getSpecialMinteos(account, rewardIndex);

    if(allMints.length > 0) {
        //console.log("consensusCheckRewards - Checking mints...", allMints);
        respuesta = allMints[0].txId;
    }

    return respuesta;
}

const consensusCheckRewards = async (transaccion, DECollection, account) => {
    //console.log("Entro en consensusCheckRewards");

    var canParseDetails = false;
    var usedCardsOnConsensus = null;

    try {
        usedCardsOnConsensus = JSON.parse(transaccion.details);
        canParseDetails = true;
    } catch(error) {
        canParseDetails = false;
    }
    
    if(canParseDetails && usedCardsOnConsensus !== null) {

        const tokenInfo = [];
        for (let index = 0; index < usedCardsOnConsensus.length; index++) {
            const element = usedCardsOnConsensus[index];
            const respuesta = await DECollection.getTokenInfo.call(element);
            tokenInfo.push(respuesta);
        }

        const checkIfUsed = (currentValue) => currentValue.used;
        const isAllUsed = tokenInfo.every(checkIfUsed);

        if(isAllUsed) {
            //console.log("consensusCheckRewards - All cards its used");
            
            const cleanTokenTypes = tokenInfo.flatMap(token => token.typeNft.words);
            const rewardCheck = await checkRewardMatch(cleanTokenTypes, account);

            if(rewardCheck.found === true) {
                //console.log("consensusCheckRewards - Reward found", rewardCheck.rewardIndex);
                const mintTx = await checkMintRewardOnBD(account, rewardCheck.rewardIndex);
                if(mintTx !== null) {
                    //console.log("consensusCheckRewards - Mint found - Validating on Consensus...");
                    await consensusSetVerified(account, transaccion.id, mintTx);
                } else {
                    //console.log("consensusCheckRewards - Mint NOT found - Generating...");
                    const randomTx = makeDesyncTX(42);
                    await createMintReward(account, randomTx, rewardCheck.rewardIndex);
                    await consensusSetVerified(account, transaccion.id, randomTx);
                }
                updateUsedWalletsData(account, usedCardsOnConsensus);
            } else {
                //console.log("consensusCheckRewards - Reward not exist.");
                await consensusSetVerified(account, transaccion.id, "Reward not exist");
            }

        } else {
            //console.log("consensusCheckRewards - Not on blockchain");
            await consensusSetVerified(account, transaccion.id);
        }
    } else {
        await consensusSetVerified(account, transaccion.id, "Reward malformed");
    }
        
}

var runningConsensus = false;

// Ejecutar el sistema de consenso
export const consensusRun = async () => {
    
    if(!runningConsensus) {
        //console.log("consensusRun -> Initiated");
        runningConsensus = true;

        try {
            const infoAccount = await waitInfoAccount();
            const web3Api = await waitWeb3Api();
            const unverified = await consensusCheckUnverified(infoAccount.account);
            
            if(unverified.length > 0) {
                for (let index = 0; index < unverified.length; index++) {
                    const transaccion = unverified[index];
                    const action = transaccion.action;
                    
                    if(action === "Open Capsules") {

                        await consensusCheckCapsules(infoAccount, transaccion, web3Api.contracts.MysteryCapsule);

                    } else if(action === "NFTs Minted") {

                        await consensusCheckNftsMinted(infoAccount.account, web3Api.contracts.DECollection, transaccion.id);

                    } else if(action === "Reward") {

                        await consensusCheckRewards(transaccion, web3Api.contracts.DECollection, infoAccount.account);
                        
                    } else {
                        //console.log("Malformed consensusId ->", transaccion.id, action);
                    }
                }
            }
        } catch(error) {
            //console.log("consensusRun -> FAILED, RETRYING -> ", error);
            await wait(5000);
            consensusRun();
        }
        runningConsensus = false;
        //console.log("consensusRun -> Finished");
    }
    
}

export const consensusRunNow = async (nowInfoAccount, contracts) => {
    if(!runningConsensus) {
        //console.log("consensusRunNow -> Initiated");
        runningConsensus = true;
        const infoAccount = nowInfoAccount;
        const unverified = await consensusCheckUnverified(infoAccount.account);
        const { DECollection, MysteryCapsule } = contracts;
        
        if(unverified.length > 0) {
            for (let index = 0; index < unverified.length; index++) {
                const transaccion = unverified[index];
                const action = transaccion.action;
                
                if(action === "Open Capsules") {

                    await consensusCheckCapsules(infoAccount, transaccion, MysteryCapsule);

                } else if(action === "NFTs Minted") {

                    await consensusCheckNftsMinted(infoAccount.account, DECollection, transaccion.id);

                } else if(action === "Reward") {

                    await consensusCheckRewards(transaccion, DECollection, infoAccount.account);
                    
                } else {
                    //console.log("Malformed consensusId ->", transaccion.id, action);
                }
            }
        }
        runningConsensus = false;
        //console.log("consensusRunNow -> Finished");
    }
}

// ----------------------------
// AUXILIARES

function makeDesyncTX(length) {
    var result           = 'DESYNC_';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}