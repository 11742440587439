import { useState, Fragment } from "react";
import { Container } from "react-bootstrap";

// MetaMask -> Autodetectar wallet
import Web3Modal from "web3modal";
import { sequence } from '0xsequence';
import WalletConnectProvider from "@walletconnect/web3-provider";

import DropdownWallet from "../../Elements/Buttons/HeaderWallet/DropdownWallet";

//import classes from "./Login.module.css";

const Login = props => {

    const [ isConnecting, setIsConneting ] = useState(false);
    const { account, balance, balanceUSDC, isConnected, collectionDetails } = props.infoAccount;
    
    // ------------------
    
    const providerOptions = {
        bitkeep: {
            package: true
        },
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: {
                    137: "https://polygon-rpc.com",
                    80001: "https://rpc-mumbai.maticvigil.com"
                },
                infuraId: "62559bba8bef40748d7823c29b2ea045",
                network: "matic",
                supportedNetworks: [137, 80001],
                chainId: 137
            }
        },
        sequence: {
            package: sequence, // required
            options: {
                appName: "Dark Earth", // optional
                defaultNetwork: "polygon" // optional
            }
        },
        injected: {
            display: {
                description: "Connect with your wallet on browser"
            },
        }
    };

    const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: false, // optional
        providerOptions, // required,
        theme: {
            background: "rgb(20, 20, 20)",
            main: "rgb(255, 255, 255)",
            secondary: "rgb(136, 136, 136)",
            border: "rgba(195, 195, 195, 0.14)",
            hover: "rgb(40, 40, 40)"
          }
    });
    
      
    // ------------------

    const useLogin = async () => {
        const loadProvider = async () => {

            web3Modal.clearCachedProvider();

            try {
                const provider = await web3Modal.connect();
                if(provider.wc) {
                    provider.disconnect();
                }
                await onLoginHandler(provider);
            } catch(error) {
                console.log("LOGIN ERROR -> ", error);
                //window.location.reload();
            }

            
        }
        
        await loadProvider();
    }

    const onLoginHandler = async (auxProv) => {
        
        setIsConneting(true);
        if(auxProv.isMetamask){
            await auxProv.request({
                method: "eth_requestAccounts"
            }).catch((error) => {
                console.log("ERROR MetaMask: ", error);
                //window.location.reload();
            });
        }
        setIsConneting(false);
        props.onLogin(auxProv);
    };

    const onLogoutHandler = async () => {
        web3Modal.clearCachedProvider();
        props.onLogout();
    };

    return (
        <Fragment>
            { !isConnected ?
                
                <button type="button" className="btn btn-outline-light" onClick = { useLogin } disabled={isConnecting}>
                    <b className="mx-2">{ !isConnecting ? "CONNECT WALLET" : "CONNECTING..."}</b>
                </button>

                :
                <Container fluid>
                    <DropdownWallet
                        getBlockchainData = { props.getBlockchainData }
                        onLogout = { onLogoutHandler }
                        balance = { balance }
                        balanceUSDC = { balanceUSDC }
                        currentAccount = { account }
                        chainId = { props.chainId }
                        tokenIds = { collectionDetails.cardsIds }
                        useLoad = { props.useLoad }
                    />
                </Container>
            }
        </Fragment>
    );
}

export default Login;