import React, { Fragment } from "react";
import NoConectado from "../../components/UI/Main/NoConectado/NoConectado";
import Market from "../../components/UI/pages/Market/Market";
import NoDisponible from "../NoDisponible/NoDisponible";

const Marketplace = (props) => {

    const { isConnected } = props.infoAccount;
    const activado = props.activo;

    return (
        <Fragment>
            <main>

                { !isConnected ?
                    <NoConectado/>

                    :
                    activado ?
                        <Market/>
                    :
                        <NoDisponible/>
                }

            </main>
        </Fragment>
  );

}

export default Marketplace;